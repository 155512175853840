import React from "react";

function CustomLinkRenderer(params) {
  const isLink = params.value.startsWith("https");
  if (isLink) {
    const websiteName = new URL(params.value).hostname;
    return (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        {websiteName}
      </a>
    );
  }

  return <>{params.value}</>;
}

export default CustomLinkRenderer;
