import React from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Chip,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDateNumeric } from "../../Utilities/DateUtilities";

function TopicCard(p) {
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme

  const handleSettingsClick = () => {
    navigate(`/dashboard/topic-settings/${p.id}`, { replace: true });
  };

  const handleRemoveClick = () => {
    p.removeTopic(p.data);
  };

  return (
    <Card
      elevation={0}
      style={{
        border: "1px solid grey",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div">
          {p.topic}
        </Typography>

        <Typography
          component="p"
          sx={{
            marginBottom: "12px",
            marginTop: "12px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            className="material-symbols-outlined"
            sx={{
              fontSize: "20px",
              marginRight: "6px",
              verticalAlign: "middle",
            }}
          >
            history
          </span>
          <strong>Active since:</strong> {formatDateNumeric(p.createdAt)}
        </Typography>

        <Box
          sx={{
            marginBottom: "8px",
            marginTop: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            className="material-symbols-outlined"
            sx={{
              fontSize: "20px",
              marginRight: "6px",
              verticalAlign: "middle",
            }}
          >
            schedule_send
          </span>
          <strong>Status:</strong>
          <Typography
            component="span"
            sx={{ ml: 1, mr: 3, verticalAlign: "middle" }}
          >
            {p.status ? (
              <Chip
                label="Active"
                sx={{
                  bgcolor: "#dcfce6",
                  color: "#166434",
                  border: "0.5px solid green",
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                sx={{
                  bgcolor: "#e0e0e0",
                  color: "#9e9e9e",
                  border: "0.5px solid #9e9e9e",
                }}
              />
            )}
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Button
          size="small"
          onClick={handleRemoveClick}
          sx={{
            alignSelf: "flex-start",

            color: theme.palette.error.main,
          }}
        >
          Remove
        </Button>

        <Button
          size="small"
          onClick={handleSettingsClick}
          sx={{
            alignSelf: "flex-end",

            color: theme.palette.text.secondary, // Use theme for text color
            "&:hover": {
              backgroundColor: theme.palette.action.hover, // Use theme for hover background color
            },
          }}
        >
          Chat
        </Button>
      </Box>
    </Card>
  );
}

export default TopicCard;
