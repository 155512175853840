import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

// AG GRID
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

// DIALOG
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DeleteRenderer from "../Components/DeleteRenderer";
import useUtilsManageSlices from "../Hooks/useUtilsMangeSlices";

import { Grid } from "@mui/material";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const filter = createFilterOptions();

const ManageSourcesContainer = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [open, setOpen] = React.useState(false);
  const [newQuery, setNewQuery] = useState("");
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [disableDelete, setDisabelDelete] = useState(false);

  const { openDelete, setOpenDelete, handleQueryAddition } =
    useUtilsManageSlices();

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    { field: "searchQuery", filter: true },

    {
      field: "createdBy",
      valueGetter: (params) => {
        if (params.data.createdBy) {
          return `${params.data.createdBy.firstName} ${params.data.createdBy.lastName}`;
        } else {
          return "No User Data";
        }
      },
    },
    { field: "isActive", filter: true },
    { field: "Delete", cellRenderer: DeleteRenderer },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event, event.column.colId);
    setSelectedQuery(event.data);
    async function toggleIsActive() {
      if (event.column.colId === "isActive") {
        let gridData = await axios.post(`${prodApi}/sources/toggleActive`, {
          queryId: event.data._id,
          isActive: !event.data.isActive,
        });
        setRowData(gridData.data);
      }
    }
    toggleIsActive();
  }, []);

  // Example load data from server
  // "http://localhost:8000/user/login",
  useEffect(() => {
    async function getData() {
      let gridData = await axios.get(`${prodApi}/sources/getAllSearchQueries`);
      console.log(gridData.data, userobj);
      setRowData(gridData.data);
    }
    getData();
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);

  return (
    <>
      {rowData.length > 0 ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Web-Search Query Management</h3>
            </Grid>

            <Grid item xs={12}>
              <div>
                {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
                <div
                  className="ag-theme-alpine"
                  style={{
                    // width: 500,
                    height: 500,
                  }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                    onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <AddIcon />
              </Fab>
            </Grid>
            {/* ADD MODAL */}
            <Grid item xs={12}>
              <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Add New Search Query</DialogTitle>

                <DialogContent>
                  <p>
                    Explore other existing search queries that may fit your
                    interests.
                  </p>
                  <Autocomplete
                    id="auto-complete"
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={rowData.map((i) => {
                      return { label: i.searchQuery, id: i._id };
                    })}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setNewQuery({
                          label: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setNewQuery({
                          label: newValue.inputValue,
                        });
                      } else {
                        setNewQuery(newValue);
                      }
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.label;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          label: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.label}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Search-query" />
                    )}
                    on
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={async () => {
                      console.log("Clicked save", newQuery, userobj);
                      await handleQueryAddition(
                        newQuery,
                        userobj,
                        rowData,
                        setRowData,
                        setNewQuery,
                        handleClose
                      );
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            {/* DELETE MODAL */}
            <Grid item xs={12}>
              {selectedQuery ? (
                <Dialog
                  open={openDelete}
                  onClose={setOpenDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You are about to delete the search query{" "}
                      <b>{`${selectedQuery.searchQuery}`}</b>.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button onClick={setOpenDelete} style={{ color: "red" }}>
                      cancel
                    </Button>
                    <Button
                      disabled={disableDelete}
                      onClick={async () => {
                        setDisabelDelete(true);
                        let deleteResponse = await axios.post(
                          `${prodApi}/sourcesV2/delete-topic`,
                          {
                            queryId: selectedQuery._id,
                          }
                        );
                        console.log("Delete: ", deleteResponse.data);
                        if (deleteResponse.data.status !== 500) {
                          // Fetch all topics
                          let gridData = await axios.get(
                            `${prodApi}/sources/getAllSearchQueries`
                          );
                          console.log(gridData.data, userobj);
                          setRowData(gridData.data);
                          setOpenDelete();
                          setDisabelDelete(false);
                        } else {
                          alert("Error deleting query");
                          setDisabelDelete(false);
                        }
                      }}
                      autoFocus
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default ManageSourcesContainer;
