import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CardBorderNoHover = ({ children }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "1px solid",
        elevation: 0,
        "&:hover": { boxShadow: "none" },
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardBorderNoHover;
