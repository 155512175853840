import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  openDelete: false,
  openRemove: false,
};
const AccountPreferencesSlice = createSlice({
  name: "accountPreferences",
  initialState: INITIAL_STATE,
  reducers: {
    setOpenDelete: (state, action) => {
      state.openDelete = action.payload;
    },
    setOpenRemove: (state, action) => {
      state.openRemove = action.payload;
    },
  },
});

export const AccountPreferencesSliceActions = AccountPreferencesSlice.actions;
export const AccountPreferencesSliceReducer = AccountPreferencesSlice.reducer;
