import React, { createContext, useContext, useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";

import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  defer,
} from "react-router-dom";

import { AuthLayout } from "./modules/Login/views/AuthLayout";
import LoginContainer from "./modules/Login/views/LoginContainer";
import RegisterContainer from "./modules/Register/views/RegisterContainer";
import DashboardContainerV2 from "./modules/Dashboard/views/DashboardContainerV2";
import AccountPreferences from "./modules/AccountPreferences/views/AccountPreferences";
import DebriefsView from "./modules/Debriefs/views/DebriefsView";
import SourcesContainer from "./modules/Sources/views/SourcesContainer";
import ManageSourcesContainer from "./adminModules/ManageSources/views/ManageSourcesContainer";
import MyDebriefs from "./modules/Dashboard/views/MyDebriefs";
import TopicManager from "./modules/TopicManager/views/TopicManagerContainer";
import MyTopicsContainer from "./modules/MyTopics/views/MyTopicsContainer";
import DebriefBuilder from "./modules/DebriefBuilder/views/DebriefBuilderContainer";
import MyWorkspaces from "./modules/MyWorkspaces/views/MyWorkspaces";
import WorkSpaceManagerContainer from "./modules/Workspace/views/WorkSpaceManagerContainer";
import SemanticSearch from "./modules/SemanticSearch/views/SemanticSearch";
import PublicDebrief from "./modules/PublicPages/views/PublicDebrief";

import HomeContainer from "./modules/Home/views/HomeContainer";
import MyIntegrationsContainer from "./modules/MyIntegrations/views/MyIntegrationsContainer";

// THEMES
import lightTheme from "./Theme/light";
import darkTheme from "./Theme/dark";

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 1000)
  );

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/shared/:id" element={<PublicDebrief />} />
      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route path="/" element={<LoginContainer />} />
        <Route path="/register" element={<RegisterContainer />} />
        <Route path="/dashboard" element={<DashboardContainerV2 />}>
          <Route path="home" element={<HomeContainer />} />
          <Route path="my-debriefs" element={<MyDebriefs />} />
          <Route path="debriefs" element={<DebriefsView />} />
          {/* Topics */}
          <Route path="my-topics" element={<MyTopicsContainer />} />
          <Route path="topic-settings/:id" element={<TopicManager />} />
          {/* Workspaces */}
          <Route path="my-schedules" element={<MyWorkspaces />} />
          <Route path="workspace/:id" element={<WorkSpaceManagerContainer />} />
          <Route path="account" element={<AccountPreferences />} />
          <Route path="sources" element={<SourcesContainer />} />
          <Route path="manageSources" element={<ManageSourcesContainer />} />
          <Route path="debrief-builder" element={<DebriefBuilder />} />
          <Route path="semantic-search" element={<SemanticSearch />} />
          {/* Integrations */}
          <Route path="my-integrations" element={<MyIntegrationsContainer />} />
        </Route>
      </Route>
    </>
  )
);
export const ThemeModeContext = createContext("light");

function App() {
  const [mode, setMode] = useState("light");
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = mode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeModeContext.Provider value={{ mode, toggleMode }}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
}

export default App;
