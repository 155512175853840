import React, { useState, useRef, useMemo } from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlet } from "react-router-dom";

import { useTheme } from "@mui/material/styles"; // Import useTheme

// AG GRID

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import RemoveRenderer from "../../../adminModules/ManageSources/Components/RemoveRenderer";
import CircleStatusRenderer from "../../../common/components/AGGridRenderers/CircleStatusRenderer";

import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";

// REDUCER
import useUtilsAccountPreferences from "../Hooks/useUtilsAccountPreferences";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

// const filter = createFilterOptions();

export default function AccountPreferences() {
  // TODO: // fix the colors in this page and allow the user to edit account details, include the option to upgrade to a premium account here
  const theme = useTheme(); // Use the useTheme hook to access the theme

  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [openLoading, setOpenLoading] = useState(false);
  const { openRemove, handleQueryAddition, setOpenRemove } =
    useUtilsAccountPreferences();

  const [mainUser, setMainUser] = React.useState({});

  const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [userTopics, setUserTopics] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");

  // Query stuff
  const [newQuery, setNewQuery] = useState("");
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [disableDelete, setDisabelDelete] = useState(false);
  const [columnDefs, setColumnDefs] = useState([
    { field: "searchQuery", filter: true },
    {
      headerName: "On",
      field: "isActive",
      cellRenderer: CircleStatusRenderer,
    },
    {
      headerName: "Currently Reading",
      field: "userActive",
      filter: true,
    },
    { field: "Remove", cellRenderer: RemoveRenderer },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  // Example of consuming Grid Event
  const cellClickedListener = async (event) => {
    console.log("cellClicked", event, event.column.colId);
    setSelectedQuery(event.data);
    async function toggleIsActive() {
      if (event.column.colId === "userActive") {
        console.log("Row data: ", rowData, mainUser);
        const activeCount = !mainUser.userActiveTopics
          ? 0
          : mainUser.userActiveTopics.length;
        console.log("Active count: ", activeCount);
        setOpenLoading(true);
        // Is the topic an active topic for the user?
        if (event.data.userActive) {
          // Yes -> turn it off
          let toggleResponse = await axios.post(
            `${prodApi}/sourcesV2/activetate-topic-for-user`,
            {
              topicId: event.data._id,
              userId: userobj._id,
            }
          );
          console.log("Toggle off: ", toggleResponse);
          // Fetch all topics
          let userResponse = await axios.post(`${prodApi}/user/fetch`, {
            id: userobj._id,
          });
          setMainUser(userResponse.data);
          // Fetch all topics
          let userTopicsData = await axios.get(
            `${prodApi}/sourcesV2/user-topics/${userobj._id}`
          );
          setFormatUserTopics(
            userResponse.data,
            userTopicsData.data.userTopics
          );
        } else {
          // No -> turn it on
          if (activeCount < 3) {
            let toggleResponse = await axios.post(
              `${prodApi}/sourcesV2/activetate-topic-for-user`,
              {
                topicId: event.data._id,
                userId: userobj._id,
              }
            );
            console.log("Toggle on: ", toggleResponse);
            let userResponse = await axios.post(`${prodApi}/user/fetch`, {
              id: userobj._id,
            });
            setMainUser(userResponse.data);
            // Fetch all topics
            let userTopicsData = await axios.get(
              `${prodApi}/sourcesV2/user-topics/${userobj._id}`
            );
            setFormatUserTopics(
              userResponse.data,
              userTopicsData.data.userTopics
            );
          } else {
            alert(
              "You reached 3 active topics, remove 1 before activating another"
            );
          }
        }
        setOpenLoading(false);
      }
    }
    toggleIsActive();
  };

  // Each Column Definition results in one Column.
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const setFormatUserTopics = (user, topics) => {
    // Create a copy of the userActiveTopics array for faster look-up
    const userActiveTopicsSet = new Set(user.userActiveTopics);
    console.log("User active set: ", userActiveTopicsSet);
    // Iterate through the topics and set userActive based on user's active topics
    const formattedTopics = topics.map((topic) => ({
      ...topic,
      userActive: userActiveTopicsSet.has(String(topic._id)),
    }));
    console.log("Formated topics: ", formattedTopics);
    setUserTopics(formattedTopics);
    return;
  };

  // use effect
  React.useEffect(() => {
    async function fetchUser() {
      setOpenLoading(true);
      console.log("User:", userobj._id);
      //  Fetch user object
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });
      console.log("User response: ", userResponse.data);
      if (userResponse.data) {
        setMainUser(userResponse.data);
        // Fetch all search topics for options
        let gridData = await axios.get(
          `${prodApi}/sources/getAllSearchQueries`,
          {
            userId: userobj._id,
          }
        );
        setRowData(gridData.data);
        // Fetch all topics a user joined
        let userTopicsData = await axios.get(
          `${prodApi}/sourcesV2/user-topics/${userobj._id}`
        );

        setFormatUserTopics(userResponse.data, userTopicsData.data.userTopics);
        setOpenLoading(false);
      }
    }
    fetchUser();
  }, []);

  return (
    <>
      {!openLoading ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: theme.palette.background.paper,
                  width: "70vh",
                  padding: 15,
                  margin: 10,
                  border: "1.5px solid #f9f9f3",
                  borderRadius: "15px",
                }}
              >
                {/* <Backdrop
                  sx={{
                    // color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop> */}
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <b>Account Details</b>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      disabled
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      variant="standard"
                      // color="primary"
                      value={mainUser.firstName || ""}
                      onChange={(event) => {
                        let temp = { ...mainUser };
                        temp.firstName = event.target.value;
                        setMainUser(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      autoComplete="last-name"
                      name="lastName"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      autoFocus
                      variant="standard"
                      // color="secondary"
                      value={mainUser.lastName || ""}
                      onChange={(event) => {
                        let temp = { ...mainUser };
                        temp.lastName = event.target.value;
                        setMainUser(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      autoComplete="primary-email"
                      name="email"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      autoFocus
                      variant="standard"
                      // color="secondary"
                      value={mainUser.email || ""}
                      onChange={(event) => {
                        let temp = { ...mainUser };
                        temp.email = event.target.value;
                        setMainUser(temp);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <div
                style={{
                  paddingBottom: 10,
                  fontSize: "24px",
                  color: "#C6017E",
                }}
              >
                <b>My Topics</b>
              </div>

              <div>
                <p>
                  <span
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    Active Reading Topics:
                  </span>
                  <span style={{ fontStyle: "italic", fontWeight: "lighter" }}>
                    (Max 3 active topics)
                  </span>
                </p>
              </div>
              <Stack direction="row" spacing={2}>
                {mainUser?.userActiveTopics?.length > 0
                  ? mainUser.userActiveTopics
                      .filter((topicId) =>
                        userTopics.some((item) => item._id === topicId)
                      )
                      .map((topicId, index) => {
                        const topic = userTopics.find(
                          (item) => item._id === topicId
                        );
                        return (
                          <Chip
                            key={index}
                            label={topic.searchQuery}
                            style={{
                              fontFamily: "Arial, sans-serif",
                              fontSize: "14px",
                              padding: "8px 12px",
                              backgroundColor: "#f9f9f3",
                              border: "1.5px solid #f9f9f3",
                              borderRadius: "15px",
                              cursor: "pointer",
                              color: "#1c2715",
                              fontWeight: "600",
                            }}
                          />
                        );
                      })
                  : null}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <div>
                <div
                  className="ag-theme-alpine"
                  style={{
                    height: 500,
                  }}
                >
                  {userTopics.length > 0 ? (
                    <AgGridReact
                      ref={gridRef}
                      rowData={userTopics}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      animateRows={true}
                      rowSelection="multiple"
                      onCellClicked={cellClickedListener}
                    />
                  ) : null}
                </div>
              </div>
            </Grid> */}
            {/* MODAL */}
            {/* <Grid item xs={12}>
              <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle
                  style={{
                    paddingBottom: 10,
                    fontSize: "24px",
                    color: "#C6017E",
                  }}
                >
                  Add or Find Topic
                </DialogTitle>

                <DialogContent>
                  <p>
                    Explore other existing search topics and queries that may
                    fit your interests.
                  </p>

                  <Autocomplete
                    id="auto-complete"
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={rowData
                      .filter(
                        (item) =>
                          !userTopics.some((topic) => topic._id === item._id)
                      )
                      .map((i) => {
                        return { label: i.searchQuery, id: i._id };
                      })}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setNewQuery({
                          label: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setNewQuery({
                          label: newValue.inputValue,
                        });
                      } else {
                        setNewQuery(newValue);
                      }
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.label;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      console.log("PARAMS: ", params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          label: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <li key={option.label} {...props}>
                        {option.label}
                      </li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type the topics you'd like us to search and summarize..."
                      />
                    )}
                    on
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    style={{ color: "#000000" }}
                    onClick={async () => {
                      console.log("Clicked save", newQuery, userobj);
                      await handleQueryAddition(
                        newQuery,
                        userobj,
                        userTopics,
                        setFormatUserTopics,
                        setNewQuery,
                        setMainUser,
                        handleClose
                      );
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid> */}

            {/* REMOVE MODAL */}
            {/* <Grid item xs={12}>
              {selectedQuery ? (
                <Dialog
                  open={openRemove}
                  onClose={() => {
                    console.log("Close");
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You are about to remove the search query
                      <b>{` ${selectedQuery.searchQuery}`}</b>.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      onClick={() => {
                        setOpenRemove();
                      }}
                      style={{ color: "black" }}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="text"
                      style={{ color: "red" }}
                      disabled={disableDelete}
                      onClick={async () => {
                        setDisabelDelete(true);
                        let deleteResponse = await axios.post(
                          `${prodApi}/sourcesV2/remove-user-from-topic`,
                          {
                            queryId: selectedQuery._id,
                            userId: userobj._id,
                          }
                        );
                        if (deleteResponse.data.status !== 500) {
                          // Fetch all topics a user joined
                          let userTopicsData = await axios.get(
                            `${prodApi}/sourcesV2/user-topics/${userobj._id}`
                          );

                          setFormatUserTopics(
                            mainUser,
                            userTopicsData.data.userTopics
                          );

                          setOpenRemove();
                          setDisabelDelete(false);
                        } else {
                          alert("Error deleting query");
                          setDisabelDelete(false);
                        }
                      }}
                      autoFocus
                    >
                      Proceed
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
            </Grid> */}
            {/* <Grid item xs={12}>
              <Fab
                
                style={{
                  position: "fixed",
                  right: "20px",
                  bottom: "20px",
                  backgroundColor: "#C6017E",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <AddIcon />
              </Fab>
            </Grid> */}
          </Grid>
        </>
      ) : (
        <Grid item xs>
          <BackdropLoader isLoading={openLoading} />{" "}
        </Grid>
      )}

      <Grid item xs={12}>
        {status === "succes" ? (
          <>
            {" "}
            <Alert severity="success">Succesfuly Update User </Alert>
          </>
        ) : (
          <> </>
        )}
        {status === "error" ? (
          <>
            {" "}
            <Alert severity="error">Error while updating </Alert>
          </>
        ) : (
          <> </>
        )}
      </Grid>
      <Outlet />
    </>
  );
}
