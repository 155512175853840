import * as React from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

export default function SourcesContainer() {
  const [openLoading, setOpenLoading] = React.useState(false);
  const [webArticles, setWebArticles] = React.useState([]);
  const [expandedItems, setExpandedItems] = React.useState({});

  const handleClose = () => {
    setOpenLoading(false);
  };

  React.useEffect(() => {
    async function fetchUser() {
      setOpenLoading(true);

      let clusters = await axios.get(
        "https://rsnc-23.onrender.com/sources/listAll"
      );

      setWebArticles(clusters.data.splice(0, 50));

      setOpenLoading(false);
    }
    fetchUser();
  }, []);

  const handleClick = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Grid container space={2}>
      {/* Loading back drop */}
      <Grid item xs={12}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            padding: "45px 0", // Add padding to the top and bottom for spacing
            color: "#000000",
            textAlign: "center", // Center the text horizontally
            fontWeight: "bold", // Makes the text bold
            fontSize: "24px",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            fontWeight={900}
            fontSize={40}
          >
            Your Headlines
          </Typography>
        </div>
      </Grid>
      {webArticles.length > 0
        ? webArticles.map((item, index) => {
            // const dateOptions = {
            //   year: "numeric",
            //   month: "long",
            //   day: "numeric",
            //   hour: "numeric",
            //   minute: "numeric",
            //   timeZoneName: "short",
            // };

            // const date = new Date(item.createdAt);
            const formattedDate = moment(item.date).format(
              "MMMM Do, YYYY [at] hA [EST]"
            );
            return (
              <Grid key={index} item xs={5}>
                <div
                  style={{
                    padding: 30,
                    margin: 40,
                    backgroundColor: "#FFFFFF",
                    overflow: "hidden",
                    maxHeight: expandedItems[index] ? "100%" : "350px",
                    overflowY: item.expanded ? "scroll" : "hidden",
                    transition: "max-height 0.4s ease-out",
                    borderRadius: 20,
                    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.05)",
                    alignItems: "center",
                    border: "1.5px solid #E0E0E0",
                  }}
                  onClick={() => handleClick(index)}
                >
                  <h2 style={{ color: "#d31275", fontWeight: "bold" }}>
                    {/* {item.title} */}
                    {item.subject}
                  </h2>{" "}
                  {/* <p style={{ color: "#007283", fontWeight: "bold" }}>
                    About {item.category}
                  </p>{" "} */}
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ margin: 0, fontStyle: "italic" }}>
                      Updated on {formattedDate}
                    </p>
                  </div> */}
                  {/* <h4>Source </h4>
                  <Stack direction="row" spacing={1}>
                    <Tooltip
                      title={
                        item.link.includes(".com")
                          ? "This content is from a published web article."
                          : "This content is extracted from an email newsletter."
                      }
                      placement="right-start"
                      arrow
                    >
                      <Chip
                        onClick={() => {
                          window.open(item.link);
                        }}
                        label={item.link
                          .replace(/^https?:\/\/(www\.)?/, "")
                          .replace(/(\.com).*$/, ".com")
                          .toUpperCase()}
                        variant="outlined"
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: "#f2f2f2", // Set the avatar's background color
                              color: "#1c2715", // Set the avatar's text color
                              fontSize: "12px", // Adjust font size of the letter inside the avatar
                            }}
                          >
                            {item.link.includes(".com") ? "N" : "NL"}
                          </Avatar>
                        }
                        key={`${item.link}`}
                        style={{
                          backgroundColor: "#FFFFFF", // Background color
                          color: "#000003", // Text color
                          fontWeight: "800", // Makes the text bold
                          maxWidth: "100%", // Ensures that the Chip doesn't overflow the container
                          border: "0.75px solid #E0E0E0",
                          fontSize: "9px", // Decreased font size
                          padding: "4px 4px", // Reduced padding
                          borderRadius: "10px", // Adjusted border-radius
                        }}
                      />
                    </Tooltip>
                  </Stack> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "50%",
                    }}
                  >
                    <UnfoldMoreIcon
                      style={{
                        fontSize: "30px",
                        color: "#A6A6A6",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                  <h5 style={{ marginTop: "30px" }}>Article Content</h5>
                  {/* <p>{item.content}</p> */}
                  <p dangerouslySetInnerHTML={{ __html: item.htmlContent }} />
                </div>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
}
