import React from "react";

const CircleStatusRenderer = (props) => {
  const { value } = props;

  const circleColor = value ? "green" : "red";

  return (
    <span>
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: circleColor,
          borderRadius: "50%",
          margin: 10,
        }}
      ></div>
    </span>
  );
};

export default CircleStatusRenderer;
