import * as React from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

// Components
import WebClusterModalv2 from "../../../common/components/Modals/webClusterModalv2";
import ClusterSummaryView from "../../../common/components/Topic Views/ClusterSummaryView";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

export default function MyDebriefs() {
  const [openLoading, setOpenLoading] = React.useState(false);
  const [archiveContent, setArchiveContent] = React.useState([]);
  const [archiveDates, setArchiveDates] = React.useState([]);
  // Modal
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleClose = () => {
    setOpenLoading(false);
  };

  // use effect
  React.useEffect(() => {
    async function fetchUser() {
      setOpenLoading(true);
      const saved = localStorage.getItem("user");
      const userobj = JSON.parse(saved);
      let clusters = await axios.get(
        `${prodApi}/content/fetch-user-clusters/${userobj._id}`
      );

      let dates = Object.keys(clusters.data);
      dates.sort((a, b) => new Date(b) - new Date(a));
      setArchiveDates(dates);
      setArchiveContent(clusters.data);
      setOpenLoading(false);
    }
    fetchUser();
  }, []);

  return (
    <Grid container space={2}>
      {/* Modal */}
      <Grid item xs>
        {selectedItem ? (
          <WebClusterModalv2
            open={openModal}
            content={selectedItem}
            handleClose={() => {
              setOpenModal(false);
            }}
          />
        ) : null}
      </Grid>

      {/* Loading back drop */}
      <Grid item xs={12}>
        <BackdropLoader isLoading={openLoading} />
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            color: "#000000",
            textAlign: "center", // Center the text horizontally
            fontWeight: "bold", // Makes the text bold
            fontSize: "24px",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            fontWeight={900}
            fontSize={40}
          >
            My Debriefs
          </Typography>
        </div>
      </Grid>

      {archiveDates.length > 0
        ? archiveDates.map((date) => {
            return (
              <>
                {/* DATE DIVIDER */}
                <Grid item xs={12}>
                  <div
                    style={{
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Divider style={{ flex: 1, backgroundColor: "#F9F9F3" }} />{" "}
                    {/* Lighter gray color */}
                    <Chip
                      label={date}
                      style={{
                        margin: "10px 20px ",
                        fontWeight: "bold",
                      }}
                    />
                    <Divider style={{ flex: 1, backgroundColor: "#F9F9F3" }} />{" "}
                    {/* Lighter gray color */}
                  </div>
                </Grid>

                <>
                  {/* CARD COLLECTION */}
                  <Grid container spacing={2}>
                    {archiveContent[date].map((item) => (
                      <Grid item xs={12}>
                        <div
                          style={{
                            padding: 10,
                            backgroundColor: "#FFFFFF",
                            borderRadius: 20,
                            alignItems: "center",
                            border: "1.5px solid #E0E0E0",
                            transition: "0.3s", // Transition effect for smooth hover
                            cursor: "zoom-in", // Change cursor to zoom-in
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 8px 40px rgba(0, 0, 0, 0.1)"; // Increase shadow blur on hover
                            e.currentTarget.style.border =
                              "1.5px solid rgba(255, 0, 255, 0.2)"; // Change border color to light magenta on hover
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 4px 10px rgba(0, 0, 0, 0.1)"; // Reset shadow to default subtle state
                            e.currentTarget.style.border =
                              "1.5px solid #E0E0E0"; // Reset border color
                          }}
                        >
                          <ClusterSummaryView
                            item={item}
                            setSelectedItem={setSelectedItem}
                            setOpenModal={setOpenModal}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              </>
            );
          })
        : null}
      <Fab
        size="small" // This will make the button smaller
        color="secondary"
        aria-label="scroll-top"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          backdropFilter: "blur(10px)", // Glass effect
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background
          color: "#007283", // Text color set to black
        }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <ArrowUpwardIcon />
      </Fab>
    </Grid>
  );
}
