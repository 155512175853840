import * as React from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
import SectionPreview from "../../../common/components/SectionPreview/SectionPreview";
import { Dialog } from "@mui/material";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import dayjs from "dayjs";
import useCommonHook from "../../../common/hooks/useCommonHook";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { FormControlLabel, Checkbox, Tooltip } from "@mui/material";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

function HomeTopicsView({ topicsFeed, handleClickSource }) {
  const { setSnackBar } = useCommonHook();
  const [expandedTopics, setExpandedTopics] = React.useState({});
  const [openLoading, setOpenLoading] = React.useState(false);
  const [selectedNewsLetter, setSelectedNewsLetter] = React.useState({});
  const [showNewsLetter, setShowNewsLetter] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleTopicExpansion = (topic) => {
  setExpandedTopics(prev => ({
    ...prev,
    [topic]: !prev[topic]
  }));
};
  const handleEmailClick = async (item) => {
    try {
      if (item.type === "email") {
        setOpenLoading(true);
        let newsLetterContent = await axios.post(
          `${prodApi}/email/fetch-email`,
          {
            subject: item.subject,
            senderEmail: item.senderEmail,
          }
        );
        if (newsLetterContent.status === 200) {
          setShowNewsLetter(true);
          setSelectedNewsLetter(newsLetterContent.data.htmlContent);
        } else {
          setSnackBar(true, "Oops! Something went wrong while retrieving the newsletter.");
        }
      }
    } catch (e) {
      setSnackBar(true, "Oops! Something went wrong while retrieving the newsletter.");
    } finally {
      setOpenLoading(false);
    }
  };const showMoreItems = (topic) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topic]: (prev[topic] || 3) + 3
    }));
  };

  const showAllItems = (topic) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topic]: topicsFeed[topic].length
    }));
  };

  const showLessItems = (topic) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topic]: 3
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <BackdropLoader isLoading={openLoading} />
      <Box sx={{ mb: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          Your Topics
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.secondary }}>
          Recommended based on your interests
        </Typography>
        <Grid item xs={12}>
          <p>
            To begin creating a Debrief select some sources by tapping the <PlaylistAddIcon /> icon. You may choose up to 6 sources.
          </p>
        </Grid>
      </Box>

      {Object.keys(topicsFeed).length > 0 ? (
        Object.entries(topicsFeed).map(([topic, items]) => (
          items.length > 0 && (
            <Box key={topic} sx={{ mb: 6 }}>
              <Typography variant="h3" component="h2" sx={{ mb: 3, fontWeight: 'bold', color: theme.palette.text.primary }}>
                {topic}
              </Typography>
              <Grid container spacing={3}>
                {items.slice(0, expandedTopics[topic] || 3).map((item, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '12px',
                      overflow: 'hidden',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
                      }
                    }}>
                      <SectionPreview
                        source={item}
                        onClickEmail={() => handleEmailClick(item)}
                      />
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        p: 2,
                        borderTop: `1px solid ${theme.palette.divider}`
                      }}>
                        <Chip 
                          label={item.type === "email" ? "Newsletter" : "Article"}
                          size="small"
                          sx={{ 
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                        <Tooltip title="Add to your debrief">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<PlaylistAddIcon />}
                                checkedIcon={<PlaylistAddCheckIcon />}
                                onClick={() => handleClickSource(item)}
                                sx={{
                                  color: theme.palette.primary.main,
                                  '&.Mui-checked': {
                                    color: theme.palette.success.main,
                                  },
                                }}
                              />
                            }
                            label=""
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {items.length > 3 && (expandedTopics[topic] || 3) < items.length && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button 
                    onClick={() => showMoreItems(topic)}
                    variant="outlined"
                    sx={{ mt: 2, mr: 1 }}
                  >
                    Show More
                  </Button>
                  <Button 
                    onClick={() => showAllItems(topic)}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  >
                    Show All
                  </Button>
                </Box>
              )}
              {(expandedTopics[topic] || 3) > 3 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button 
                    onClick={() => showLessItems(topic)}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  >
                    Show Less
                  </Button>
                </Box>
              )}
            </Box>
          )
        ))
      ) : (
        <Typography variant="body1" sx={{ p: 3, backgroundColor: theme.palette.background.paper, borderRadius: '8px' }}>
          It looks like you don't have any topics associated with your account at the moment. Please go to the topics tab and add some topics to populate this page.
        </Typography>
      )}

      <Dialog
        open={showNewsLetter}
        onClose={() => setShowNewsLetter(false)}
        fullWidth
        maxWidth="md"
      >
        <Box sx={{ p: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: selectedNewsLetter }} />
        </Box>
      </Dialog>
    </Container>
  );
}

export default HomeTopicsView;
