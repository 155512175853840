import React, { useEffect, useState } from "react";

function linkify(inputText) {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return inputText.replace(
    urlRegex,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #00BFFF; cursor: pointer;">${url}</a>`
  );
}

const TypingText = ({ text = "", speed = 10 }) => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    if (visibleCount < text.length) {
      const isSpecialCharacter = [
        " ",
        ".",
        ",",
        "!",
        "?",
        ";",
        ":",
        "-",
        "(",
        ")",
        "[",
        "]",
        "{",
        "}",
        "<",
        ">",
        "+",
        "=",
        "*",
        "&",
        "^",
        "%",
        "$",
        "#",
        "@",
        "~",
      ].includes(text[visibleCount]);
      const timer = setTimeout(
        () => {
          setVisibleCount((prev) => (isSpecialCharacter ? prev + 2 : prev + 1));
        },
        isSpecialCharacter ? 15 : speed
      );
      return () => clearTimeout(timer);
    }
  }, [visibleCount, text, speed]);

  // Process the currently visible text to replace URLs with clickable links
  const displayedText = linkify(text.slice(0, visibleCount));

  return <p dangerouslySetInnerHTML={{ __html: displayedText }} />;
};

export default TypingText;
