import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Chip,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function WorkspaceCard(p) {
  const navigate = useNavigate();
  const theme = useTheme(); // Import and use the useTheme hook

  return (
    <>
      <Card elevation={0}       style={{
        border: "1px solid grey",
        borderRadius: "10px",
      }}>
        <CardContent>
          <Typography variant="h4" component="div">
            {p.data.workspaceName}
          </Typography>

          <p style={{ marginBottom: "8px", marginTop: "8px" }}>
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "20px",
                verticalAlign: "middle",
                marginRight: "6px",
                fontWeight: "900",
              }}
            >
              title
            </span>
            <b>Description: </b> {p.data.focusArea}
          </p>

          <p style={{ marginBottom: "8px", marginTop: "8px" }}>
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "20px",
                verticalAlign: "middle",
                marginRight: "6px",
              }}
            >
              autorenew
            </span>
            <b>Frequency:</b>{" "}
            {p.data.frequency.type.charAt(0).toUpperCase() +
              p.data.frequency.type.slice(1)}
          </p>

          <div
            style={{
              marginBottom: "8px",
              marginTop: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "20px",
                marginRight: "6px",
                verticalAlign: "middle",
              }}
            >
              schedule_send
            </span>
            <b>Status:</b>
            <Typography
              component="span"
              sx={{ ml: 1, mr: 3, verticalAlign: "middle" }}
            >
              {p.status ? (
                <Chip
                  label="Active"
                  sx={{
                    bgcolor: "#dcfce6",
                    color: "#166434",
                    border: "0.5px solid green",
                  }}
                />
              ) : (
                <Chip
                  label="Inactive"
                  sx={{
                    bgcolor: "#e0e0e0",
                    color: "#9e9e9e",
                    border: "0.5px solid #9e9e9e",
                  }}
                />
              )}
            </Typography>
          </div>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="small"
            sx={{
              color: theme.palette.error.main, // Use theme for button text color
              "&:hover": {
                backgroundColor: theme.palette.action.hover, // Use theme for hover background color
              },
            }}
            onClick={() => {
              p.deleteWorkspace();
            }}
          >
            Delete
          </Button>
          <Button
            size="small"
            sx={{
              alignSelf: "flex-end",
              color: theme.palette.text.secondary, // Use theme for text color
              "&:hover": {
                backgroundColor: theme.palette.action.hover, // Use theme for hover background color
              },
            }}
            onClick={() => {
              navigate(`/dashboard/workspace/${p.id}`, { replace: true });
            }}
          >
            Edit
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export default WorkspaceCard;
