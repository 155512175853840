import React, { useState, useEffect } from "react";
import "./SectionPreviewSmall.css"; // Make sure this is the correct path to your CSS file
import axios from "axios";
import { getDomainName } from "../../Utilities/SourceUtilities";

import { Card, Chip, Divider, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Button } from "@mui/material";

const pythonApi = "http://localhost:8080"; // Replace with your API endpoint as needed
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};
function SectionPreviewSmall(p) {
  const [previewData, setPreviewData] = useState(null);


  useEffect(() => {}, [p]);

  return (
    <Card
      elevation={0}
      className="custom-card-small"
      onClick={() => {
        p.handleClickSource(p.source);
      }}
       sx={{
    border: '1.5px solid #f6f6ef', // Add a border
  }}
    >
      {p?.source ? (
        <>
          <div style={{ display: "flex" }}>
            {p?.source?.imageUrl && p?.source.imageUrl !== "no value" && (
              <img
                src={p.source.imageUrl}
                alt="Preview"
                className="preview-image-small"
              />
            )}

            {p?.source?.image && p?.source.image !== "no value" && (
              <EmailIcon className="preview-image" />
            )}
            <h2 className="preview-title-small">
              {p.source?.title && p.source?.title !== "no value"
                ? p.source.title
                : p.source.subject}
            </h2>
          </div>

          <Divider
            className="divider-horizontal"
            flexItem
            style={{ padding: 2, margin: 5 }}
          />
          <div
            className="flex-row"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: 5,
              }}
            >
              {p.source?.favicon && p.source.favicon !== "no value" && (
                <div>
                  <Chip
                    avatar={
                      <img
                        src={p.source.favicon}
                        alt="Favicon"
                        className="favicon"
                        style={{ borderRadius: 5, padding: 1 }}
                      />
                    }
                    label={getDomainName(p.source.link)}
                  />
                </div>
              )}
              {p.source?.senderEmail && p.source.senderEmail !== "no value" && (
                <div>
                  <Chip
                    avatar={
                      <img
                        src={p.source.image}
                        alt="Favicon"
                        style={{ borderRadius: 5, padding: 1 }}
                      />
                    }
                    label={
                      p.source.senderEmail.length > 20
                        ? p.source.senderEmail.substring(0, 20 - 3) + "..."
                        : p.source.senderEmail
                    }
                    className="source-chip"
                  />
                </div>
              )}
            </div>
            <Divider
              className="divider-vertical"
              orientation="vertical"
              flexItem
            />
            <div
              className="flex-item"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ margin: 0 }}>
                {p.source?.link && p.source.link !== "no value"
                  ? "Article"
                  : "Newsletter"}
              </p>
            </div>
          </div>
          <Divider
            className="divider-horizontal"
            flexItem
            style={{ padding: 2, margin: 5 }}
          />
        </>
      ) : (
        <p>Loading link preview...</p>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
 <Button
  variant="text"
  sx={{
    color: 'grey', //
  }}
>
  Remove from debrief
</Button>
      </div>
    </Card>
  );
}

export default SectionPreviewSmall;
