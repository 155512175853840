import axios from "axios";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

export const doesUserExists = async (email) => {
  try {
    // Post request to your endpoint with the email
    const response = await axios.post(`${prodApi}/user/does-user-exist`, {
      email: email,
      test: "test",
    });
    console.log("DOES USER EXISTS:", response);
    // Check the status code to determine if the user exists
    if (response.status === 200) {
      // Status code 200 means user exists
      return response.data;
    } else if (response.status === 404) {
      // Status code 404 means user does not exist
      return {};
    }
  } catch (error) {
    // Handle errors (e.g., network issues, endpoint not found, etc.)
    console.error("Error checking user existence:", error);

    // If the error is due to the user not existing (404)
    if (error.response && error.response.status === 404) {
      return false;
    }

    // Optionally, throw an error or return a specific value indicating the failure
    throw error; // or return false or a specific error code as needed
  }
};
