import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../common/Utilities/DateUtilities";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
import ComingSoonComponent from "../../../common/components/Buttons/ComingSoonButton";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Helmet } from "react-helmet";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import GoBackButton from "../../../common/components/Buttons/GoBackButton";

// UTILITIES
import { deepObjectDiff } from "../Hooks/utilities";

// AUTOCOMPLETE muiv5
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// RADIO GROUP
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// VIEWS
import DebriefBuilder from "../../DebriefBuilder/views/DebriefBuilderContainer";
import DebriefStructureBuilder from "./DebriefStructureBuilder";
import CronSelector from "./CronSelector";
import CardBorderNoHover from "../../../common/components/Cards/CardBorderNoHover";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function WorkSpaceManagerContainer() {
  const navigate = useNavigate();

  const { id } = useParams();

  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);

  const [tab, setTab] = React.useState(0);
  const [workspace, setWorkSpace] = React.useState({});
  const [baseWorkSpace, setBaseWorkSpace] = useState({});

  const [loading, setLoading] = useState(true);

  const [allAvailableWebSearchTopics, setAllAvailableWebSearchTopics] =
    useState([]);

  const [allowUpdate, setAllowUpdate] = useState(false);

  // SNACK BAR VARIABLES
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("Success");
  const [message, setMessage] = useState("");

  const [edit, setEdit] = useState(false);

  const setAlert = (open, message, type) => {
    setOpen(open);
    setType(type);
    setMessage(message);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleToggleActive = (event, newValue) => {
    console.log("Toggle active:", newValue);
    let workSpaceCopy = { ...workspace };
    workSpaceCopy.isActive = newValue;
    setWorkSpace(workSpaceCopy);
    if (baseWorkSpace.isActive !== workSpaceCopy.isActive) {
      setAllowUpdate(true);
    }
  };

  const handleAutocompleteChange = (event, newSelectedOptions) => {
    console.log("selected options: ", newSelectedOptions);
    // setSelectedOptions(newSelectedOptions);
    let workspaceCopy = { ...workspace };
    const limitedSelection = newSelectedOptions.slice(-1); // This gets the last item of the array

    workspaceCopy.topics = limitedSelection;
    setWorkSpace(workspaceCopy);
    setAllowUpdate(true);
  };

  React.useEffect(() => {
    async function getTopic() {
      //  Fetch user object
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });
      console.log("User response: ", userResponse.data);
      if (userResponse.data) {
        // Fetch all search topics for options
        let workspaceData = await axios.get(
          `${prodApi}/workspace/fetch-workspace/${id}`,
          {
            userId: userobj._id,
          }
        );

        let userTopicsData = await axios.get(
          `${prodApi}/sourcesV2/user-topics/${userobj._id}`
        );
        console.log("USER TOPICS DATA: ", userTopicsData.data);
        // Set the view of users current followed topics
        setAllAvailableWebSearchTopics(userTopicsData.data.userTopics);

        // setSelectedOptions(workspaceData.data.workSpace.topics);
        setWorkSpace(workspaceData.data.workSpace);
        setBaseWorkSpace(workspaceData.data.workSpace);
      }
      setLoading(false);
    }
    getTopic();
  }, []);

  return (
    <div>
      <PageHead
        title="My Workspaces"
        description="Design and schedule personalized debriefs across a variety of topics. Craft a professional news intelligence feed tailored to your industry and interests."
      />

      <Grid
        item
        xs={12}
        sx={{
          pb: 2, // padding bottom with the theme's spacing value of 2
        }}
      >
        <GoBackButton navigateTo="/dashboard/my-schedules" />
      </Grid>

      <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
        {!workspace?.workspaceName ? (
          <></>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs>
              <CardBorderNoHover>
                <Typography variant="h5" component="div">
                  {workspace.workspaceName}
                </Typography>

                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Active since: <b>{formatDate(workspace.createdAt)}</b>
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Status: <b>{workspace.isActive ? "Active" : "Frozen"}</b>
                </Typography>
              </CardBorderNoHover>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tab}
                  onChange={handleTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab
                    label={
                      <span>
                        Design
                        <ComingSoonComponent />
                      </span>
                    }
                    disabled
                    {...a11yProps(1)}
                  />{" "}
                </Tabs>
              </Box>
              <CustomTabPanel value={tab} index={0}>
                <div
                  style={{
                    // backgroundColor: "",
                    padding: 10,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Tooltip
                        title="Toggle this switch to start editing your debriefs. This will allow you to modify and update content as needed."
                        arrow
                        placement="bottom"
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="span">
                            <span
                              className="material-symbols-outlined"
                              style={{
                                fontSize: "20px",
                                verticalAlign: "middle",
                                marginRight: "6px",
                              }}
                            >
                              tune
                            </span>

                            <b>
                              {edit
                                ? "Editing Mode Enabled"
                                : "Enable Editing Mode"}
                            </b>
                          </Typography>

                          <Switch
                            checked={edit}
                            color="primary"
                            onChange={() => {
                              setEdit(!edit);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Stack>
                      </Tooltip>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: 2, // padding top with the theme's spacing value of 2 (usually 16px)
                        pb: 2, // padding bottom with the theme's spacing value of 2
                      }}
                    >
                      <Autocomplete
                        disabled={!edit}
                        multiple
                        id="tags-outlined"
                        options={allAvailableWebSearchTopics}
                        getOptionLabel={(option) => option.searchQuery}
                        value={workspace.topics}
                        onChange={handleAutocompleteChange}
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="My Topics"
                            InputLabelProps={{
                              style: { fontWeight: "bold", color: "black" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: 2, // padding top with the theme's spacing value of 2 (usually 16px)
                        pb: 2, // padding bottom with the theme's spacing value of 2
                      }}
                    >
                      <CronSelector
                        disabled={!edit}
                        frequency={workspace.frequency.type}
                        hour={workspace.frequency.hour}
                        minute={workspace.frequency.minute}
                        days={
                          workspace.frequency.day_of_week.split(",").length < 0
                            ? []
                            : workspace.frequency.day_of_week.split(",")
                        }
                        months={
                          workspace.frequency.day.split(",").length < 0
                            ? []
                            : workspace.frequency.day.split(",")
                        }
                        onSave={(x) => {
                          console.log("Cron expresion:\n", x);
                          // let frequencyObject = {
                          //   type: x.frequency,
                          //   hour: x.hour,
                          //   minute: x.minute,
                          //   day: x.day_of_month,
                          //   day_of_week: x.day_of_week,
                          // };
                          let workSpaceCopy = { ...workspace };
                          workSpaceCopy.frequency = x;
                          setWorkSpace(workSpaceCopy);
                          setAllowUpdate(true);
                        }}
                      />
                      <Typography variant="caption" color="textSecondary">
                        Frequency affects the data available and used for
                        creating debriefs.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: 2, // padding top with the theme's spacing value of 2 (usually 16px)
                        pb: 2, // padding bottom with the theme's spacing value of 2
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="span" gutterBottom>
                          <span
                            className="material-symbols-outlined"
                            style={{
                              fontSize: "20px",
                              verticalAlign: "middle",
                              marginRight: "6px",
                            }}
                          >
                            toggle_off
                          </span>

                          <b>Status</b>
                        </Typography>

                        <Typography>Inactive</Typography>
                        <Switch
                          disabled={!edit}
                          checked={workspace.isActive}
                          onChange={handleToggleActive}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <Typography>Active</Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: 2, // padding top with the theme's spacing value of 2 (usually 16px)
                        pb: 2, // padding bottom with the theme's spacing value of 2
                      }}
                    >
                      <Typography variant="span" gutterBottom>
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "20px",
                            verticalAlign: "middle",
                            marginRight: "6px",
                          }}
                        >
                          edit_document
                        </span>

                        <b>
                          Type <ComingSoonComponent />
                        </b>
                      </Typography>

                      <Stack direction="row" spacing={1} alignItems="center">
                        <FormControl disabled={!edit}>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            {workspace.debriefType === "General Overview"
                              ? "The 'General Overview' Debrief leverages advanced AI to synthesize and distill key insights and highlights from a variety of sources, providing you with a dynamic and comprehensive summary of events for the selected timeframe."
                              : "The 'Deep Dive' Debrief adapts to your unique requirements by utilizing a customizable Debrief framework. It allows you to select specific questions to monitor over time, providing tailored insights and enabling on-demand modifications to your debrief content, for an in-depth understanding of your chosen topics."}
                          </FormLabel>

                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={workspace.debriefType}
                            onChange={(x) => {
                              console.log("report type:", x.target.value);
                              let copy = { ...workspace };
                              copy.debriefType = x.target.value;
                              setWorkSpace(copy);
                              setAllowUpdate(true);
                            }}
                          >
                            <FormControlLabel
                              value="General Overview"
                              control={<Radio />}
                              label="General Overview"
                            />
                            {/* <FormControlLabel
                              value="Deep Dive"
                              control={<Radio />}
                              label="Deep Dive"
                            /> */}
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="outlined"
                        disabled={!edit}
                        onClick={async () => {
                          // Save workspace
                          const response = await axios.post(
                            `${pythonApi}/scheduler/update_job`,
                            { workspace: workspace }
                          );
                          console.log("response: ", response);
                          if (response.status === 200) {
                            setAlert(
                              true,
                              "Details are applied and your debrief is scheduled successfully!",
                              "success"
                            );
                            setEdit(false);
                          } else {
                            setAlert(
                              true,
                              "Oops something went wrong!",
                              "error"
                            );
                          }
                        }}
                      >
                        Update Details
                      </Button>
                      <Button
                        disabled={edit}
                        variant="outlined"
                        onClick={async () => {
                          // Save workspace

                          if (workspace.topics.length > 0) {
                            setLoading(true);
                            const response = await axios.post(
                              `${pythonApi}/scheduler/manual-trigger-generate-debrief`,
                              { workspace: workspace }
                            );
                            console.log("response: ", response);
                            if (response.status === 200) {
                              setAlert(
                                true,
                                "Your Debrief is generated successfully! Head to the My Debriefs Tab.",
                                "success"
                              );
                              setEdit(false);
                              setLoading(false);
                            } else {
                              setAlert(
                                true,
                                "Oops something went wrong!",
                                "error"
                              );
                            }
                          } else {
                            alert(
                              "You can not create a Debrief without selecting a topic. Please select a topic first."
                            );
                          }
                        }}
                      >
                        Create Now
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <DebriefStructureBuilder
                  setAlert={setAlert}
                  sections={
                    workspace?.reportStructure?.sections
                      ? workspace?.reportStructure?.sections
                      : []
                  }
                  workSpaceId={workspace?._id ? workspace?._id : ""}
                  reportStructureId={
                    workspace?.reportStructure?._id
                      ? workspace?.reportStructure?._id
                      : ""
                  }
                />
              </CustomTabPanel>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <BackdropLoader isLoading={loading} />
        </Grid>
      </>
    </div>
  );
}

export default WorkSpaceManagerContainer;
