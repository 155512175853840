import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  Chip,
  Stack,
  Avatar,
  Button,
  Alert,
  Snackbar,
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  useTheme, // Included useTheme here
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Firebase
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";

// Local Components
import SentimentCard from "../../../common/components/Sentiment/SentimentCard";
import SectionPreview from "../../../common/components/SectionPreview/SectionPreview";
import PieChartComponent from "../../../common/components/PieChart/PieChartComponent";
import DateFormatString from "../../../common/components/Date/DateFormatString";
import KeyInsightsView from "../../../common/components/Topic Views/KeyInsightsView";
import BulletSummaryView from "../../../common/components/Summary Views/BulletSummaryView";
import LineChartComponent from "../../../common/components/LineChart/LineChartComponent";
import GradientButton from "../../../common/components/Buttons/GradientButton";
import SourcesCarousel from "../../../common/components/Topic Views/SourcesCarousel";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";

// Utilities
import {
  reorganizeClusters,
  getEntities,
  getTopFiveSources,
  getDomainName,
  getSourceUris,
} from "../../../common/Utilities/SourceUtilities";
import { formatDateNumeric } from "../../../common/Utilities/DateUtilities";

// Assets
import logo from "../../../common/assets/Debriefs.svg";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const palette2 = [
  "#FF385C", // Vibrant Red
  "#FF6B6C", // Soft Coral
  "#FFD8A6", // Peach
  "#FFB385", // Warm Orange
  "#FF8A5B", // Bright Orange
  "#7A4FF3", // Deep Purple
  "#513C98", // Dark Violet
  "#3466F2", // Bright Blue
  "#2DAF83", // Teal Green
  "#00FF9D", // Neon Green
  "#0CF9FF", // Neon Blue
  "#FBFF0C", // Neon Yellow
  "#FF60ED", // Neon Pink
  "#FF009F", // Hot Pink
  "#9DFF00", // Lime Green
];



function getSentimentText(data) {
  let totalNeutral = 0;
  let totalNegative = 0;
  let totalPositive = 0;
  let sentimentCount = 0;

  if (data?.clusters) {
    data?.clusters.forEach((cluster) => {
      cluster.sources.forEach((article) => {
        if (article.sentiment) {
          totalNeutral += article.sentiment.neu;
          totalNegative += article.sentiment.neg;
          totalPositive += article.sentiment.pos;
          sentimentCount++;
        }
      });
    });
    if (totalNegative > totalPositive) {
      return (
        <Typography>
          <b>Sentiment: </b>
          {"The discussion on this topic has been overall "}
          <b style={{ color: "red" }}>Negative</b>
        </Typography>
      );
    } else {
      return (
        <Typography>
          <b>Sentiment: </b>
          {"The discussion on this topic has been overall "}
          <b style={{ color: "green" }}>Positive</b>
        </Typography>
      );
    }
  }
  return 0;
}

export default function PublicDebrief() {
  const { id } = useParams();
    const navigate = useNavigate();

  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false); // State for Snackbar visibility

  const [sourceUris, setSourceUris] = React.useState([]);


  const iconStyle = { fontSize: "small", marginRight: "4px" }; // Added marginRight for some spacing
  const theme = useTheme(); // Access the theme
  {
    /* Common Styles */
  }
  const chipStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.action.chip,
    color: theme.palette.text.primary, // Dark grey font
    borderRadius: "20px", // Rounded corners
    padding: "4px 8px", // Padding inside the chip
    marginRight: "8px", // Space between chip and dynamic variable
  };

  const iconStyleCategory = {
    fontSize: "20px",
    verticalAlign: "middle",
    marginRight: "6px",
  };

  const labelStyle = { fontSize: "15px", color: "#1c2715" }; // Dark grey font for label

  const dynamicTextStyle = { fontSize: "15px", flexGrow: 1, fontWeight: "500" };

  // Snackbar close handler
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Clipboard copy function with Snackbar notification
  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setOpenSnackbar(true); // Show Snackbar on successful copy
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  // Modal
  const isNotMobile = useMediaQuery("(min-width:600px)");

  const [item, setItem] = React.useState({});

  const [sources, setSources] = React.useState([]);
  const [clusterSources, setClusterSources] = React.useState({});
  // const [wordsToHighlight, setWords] = React.useState([]);
  const [entities, setEntities] = React.useState([]);
  const handleClose = () => {
    setOpenLoading(false);
  };
 const handleGoBack = () => {
    navigate("/dashboard/debriefs");
  };

  // use effect
  React.useEffect(() => {
    async function fetchUser() {
      setOpenLoading(true);

      //   TODO: Finish this page
      let cluster = await axios.post(
        `${prodApi}/content/fetchClusterSummaryById`,
        {
          id: id,
        }
      );

      setItem(cluster.data);
      let clusterGroupedSources = reorganizeClusters(cluster.data.clusters);
      setClusterSources(clusterGroupedSources);
      setSources(getTopFiveSources(clusterGroupedSources));
      setSourceUris(getSourceUris(clusterGroupedSources));
      let tempEntities = getEntities(cluster.data.clusters, palette2);
      let temp = tempEntities.map((i) => {
        return { word: i.label, color: i.color };
      });

      setEntities(tempEntities);
      // setWords(temp);
      setOpenLoading(false);
    }
    fetchUser();
  }, []);

  // Safely parse JSON and extract the title if possible
  const generalSummary = safeParseJSON(item.generalSummary);
  const title = generalSummary.title || ''; // Provide a default title if none is found
      // A utility function to safely parse JSON and return a default value if parsing fails
function safeParseJSON(jsonString, defaultValue = {}) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultValue;
  }
};



  return (
    <div>
      <PageHead
        title={`${item.title} | ${title} • ${item.topic}`}
        description="Access comprehensive debriefs on current global topics. Dive into detailed analyses, key insights, and the latest updates across various domains."
      />

      <>
        <CssBaseline />

 
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#f2f2f2",
        }}
        component="nav"
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            
            <a
              href="https://app.debriefs.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="Logo" style={{ height: "80px" }} />
            </a>
          </div>
          </Toolbar>
        </AppBar>
        {openLoading ? (
          <>
            <Backdrop
              sx={{
                color: "#F9F9F3",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openLoading}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : (
          <Box
            component="main"
            sx={{
              p: 1,
              paddingTop: 10,
              backgroundColor: "#f2f2f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 20,

                width: isNotMobile ? "70%" : "98%",
              }}
            >
              {/* <CardContent> */}
              <Grid
                item
                container
                xs={12}
                spacing={isNotMobile ? 2 : 0}
                style={{
                  backgroundColor: "white",
                  padding: isNotMobile ? 20 : 5,
                  borderRadius: 16,
                  margin: 5,
                }}
              >
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: "20px" }}
                  >
                    <Typography
                      variant="h1"
                      component="div"
                      style={{ fontWeight: 900 }}
                    >
                    {item.title} | {title} {/* Use the safely parsed title here */}

                    </Typography>

                    {!userobj?.firstName ? (
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => {
                          // Construct the URL
                          const baseUrl = window.location.origin; // This gets the base URL of your app
                          const sharedUrl = `${baseUrl}/`;

                          // Open the new tab with the constructed URL
                          window.open(
                            sharedUrl,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "18px", // Makes the icon smaller
                            marginRight: "4px", // Adds some distance between the icon and text
                            verticalAlign: "middle", // Vertically centers the icon with the text
                            // color: "#000000",
                          }}
                        >
                          dashboard_customize
                        </span>
                        Customize
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>

                <Grid item caontainer xs={12} sm={6}>
                  {/* Topic */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={chipStyle}>
                      <span
                        className="material-symbols-outlined"
                        style={iconStyleCategory}
                      >
                        event_note
                      </span>
                      <span style={labelStyle}>
                        <b>Topic</b>
                      </span>
                    </div>
                    <Typography component="h6" style={dynamicTextStyle}>
                      {item.topic}
                    </Typography>
                  </Grid>

                  {/* Frequency */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={chipStyle}>
                      <span
                        className="material-symbols-outlined"
                        style={iconStyleCategory}
                      >
                        autorenew
                      </span>
                      <span style={labelStyle}>
                        <b>Frequency</b>
                      </span>
                    </div>
                    <Typography component="h6" style={dynamicTextStyle}>
                      {item.frequency?.charAt(0).toUpperCase() +
                        item.frequency?.slice(1) || "N/A"}
                    </Typography>
                  </Grid>

                  {/* Coverage */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={chipStyle}>
                      <span
                        className="material-symbols-outlined"
                        style={iconStyleCategory}
                      >
                        event_available
                      </span>
                      <span style={labelStyle}>
                        <b>Coverage</b>
                      </span>
                    </div>
                    <Typography component="h6" style={dynamicTextStyle}>
                      {item.startDate ? (
                        <>
                          {formatDateNumeric(item.startDate)} to{" "}
                          {formatDateNumeric(item.createdAt)}
                        </>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Grid>

                  {/* Debrief Type 
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={chipStyle}>
                      <span
                        className="material-symbols-outlined"
                        style={iconStyleCategory}
                      >
                        description
                      </span>
                      <span style={labelStyle}>
                        <b>Type</b>
                      </span>
                    </div>
                    <Typography component="h6" style={dynamicTextStyle}>
                      {"type" in item ? "Deep Dive" : "General Overview"}
                    </Typography>
                  </Grid>*/}

                  {/* Published On */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={chipStyle}>
                      <span
                        className="material-symbols-outlined"
                        style={iconStyleCategory}
                      >
                        schedule
                      </span>
                      <span style={labelStyle}>
                        <b>Published</b>
                      </span>
                    </div>
                    <Typography component="h6" style={dynamicTextStyle}>
                      <DateFormatString
                        time={item.createdAt}
                        dateFormat={"MMMM DD, YYYY"}
                        timeFormat={"hh:mm A"}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item caontainer xs={12} sm={6}>
                  <SourcesCarousel
                    slides={sourceUris}
                    options={{ loop: true }}
                  />
                  <Grid item xs={12}>
                    <Typography>
                      <b>Sources</b>
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {sources.length > 0 ? (
                        <>
                          {sources.slice(0, 4).map((s, index) => (
                            <Avatar
                              key={index}
                              alt={`Avatar ${index}`}
                              src={s}
                              sx={{ width: 30, height: 30 }}
                            />
                          ))}
                          {sources.length > 4 ? (
                            <>
                              <Chip label={`+ ${sources.length - 4}`} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 10,
                  }}
                >
                  <Typography variant="h3" component="div">
                    Important Mentions
                  </Typography>
                  {entities && <PieChartComponent entities={entities} />}
                </Grid>
                {"type" in item ? (
                  <></>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: 10,
                    }}
                  >
                    <Typography variant="h3" component="div">
                      Highlights
                    </Typography>
                    <BulletSummaryView text={item.generalSummary} />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 10,
                  }}
                >
                  <KeyInsightsView item={item} sources={clusterSources} />
                </Grid>

                {/* <Grid item xs={12}>
                <SentimentCard item={item} />
              </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleCopyLink} // Updated to use handleCopyLink function
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "18px",
                        marginRight: "4px",
                        verticalAlign: "middle",
                        color: theme.palette.accent.main,
                      }}
                    >
                      share
                    </span>
                    Share
                  </Button>

                  {!userobj?.firstName ? (
                    <>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => {
                          // Construct the URL
                          const baseUrl = window.location.origin; // This gets the base URL of your app
                          const sharedUrl = `${baseUrl}/`;

                          // Open the new tab with the constructed URL
                          window.open(
                            sharedUrl,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "18px", // Makes the icon smaller
                            marginRight: "4px", // Adds some distance between the icon and text
                            verticalAlign: "middle", // Vertically centers the icon with the text
                            color: "#000000",
                          }}
                        >
                          chat
                        </span>
                        Chat
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}

                  <Box display="flex" justifyContent="flex-end">
                    {!userobj?.firstName && (
                      <GradientButton text={"Join Debriefs"} />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Disclaimer: Our AI model is designed to assist you in
                  digesting news more efficiently across various domains,
                  including finance, science, and technology. While we strive
                  for accuracy, there may be instances where the model can make
                  mistakes, particularly given the complexity and evolving
                  nature of these fields. Financial information provided is for
                  informational purposes only and is not intended as financial
                  advice. Debriefs does not produce financial advice and does
                  not endorse or guarantee the accuracy or reliability of
                  information provided by the AI in these specific areas.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => handleCloseSnackbar("")}
          severity="success"
          sx={{
            width: "100%",
            border: "1px solid lightgrey", // Add dark grey border to Alert
          }}
        >
          Link copied to clipboard
        </Alert>
      </Snackbar>
    </div>
  );
}
