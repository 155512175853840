// useUtilsAccountPreferences.js

import { useSelector, useDispatch } from "react-redux";
import { AccountPreferencesSliceActions } from "../reducers/AccountPreferencesReducer";
import axios from "axios";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const useUtilsAccountPreferences = () => {
  const openDelete = useSelector(
    (state) => state.accountPreferences.openDelete
  );
  const openRemove = useSelector(
    (state) => state.accountPreferences.openRemove
  );
  const dispatch = useDispatch();

  const setOpenDelete = () => {
    dispatch(AccountPreferencesSliceActions.setOpenDelete(!openDelete));
  };

  const setOpenRemove = () => {
    dispatch(AccountPreferencesSliceActions.setOpenRemove(!openRemove));
  };

  const handleQueryAddition = async (
    newQuery,
    userobj,
    rowData,
    setFormatUserTopics,
    setNewQuery,
    setMainUser,
    handleClose
  ) => {
    console.log("HANDLE QUERY ADDITION: ACCOUNT PREF");
    try {
      let response;
      if ("id" in newQuery) {
        console.log("Joining new query: ", newQuery);
        // add user to topic
        // if joining topic make sure the user has less than 3 active search queries
        response = await axios.post(`${prodApi}/sourcesV2/join-topic`, {
          queryId: newQuery.id,
          userId: userobj._id,
        });
        response = response.data.query;
      } else {
        // create new topic
        response = await axios.post(`${prodApi}/sourcesV2/create-topic`, {
          query: newQuery.label,
          userId: userobj._id,
        });
        response = response.data;
      }
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });
      setMainUser(userResponse.data);

      console.log("New query save response: ", response);
      let rowDataCopy = [...rowData];
      rowDataCopy.push(response);

      setFormatUserTopics(userResponse.data, rowDataCopy);
      console.log("ROW DATA COPY: ", rowDataCopy);
      setNewQuery("");
      handleClose();
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };

  return {
    openDelete,
    openRemove,
    setOpenRemove,
    setOpenDelete,
    handleQueryAddition,
    // Add other functions or data you want to expose
  };
};

export default useUtilsAccountPreferences;
