export function reorganizeClusters(clusters) {
  const reorganizedClusters = [];

  clusters.forEach((cluster) => {
    const groups = {};
    cluster.sources.forEach((source) => {
      const key = source.link || source.subject; // Use link for web articles and subject for emails
      if (!groups[key]) {
        groups[key] = {
          type: source.type,
          link: source.link,
          text: [],
          image: source.image,
          imageUrl: source.imageUrl,
          favicon: source.favicon,
          title: source.title,
          subject: source.subject,
          senderEmail: source.senderEmail,
          date: source.createdAt,
        };
      }
      groups[key].text.push(source.text);
    });

    const groupedSources = Object.values(groups);
    reorganizedClusters.push({
      summary: cluster.summary,
      groupedSources: groupedSources,
    });
  });

  return reorganizedClusters;
}

export function groupByDate(data) {
  const grouped = {};
  if (data && data.length > 0) {
    data.forEach((item) => {
      // Convert date to 'DD-MM-YYYY' format
      const date = new Date(item.date);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;

      // Group by this date
      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(item);
    });

    // Convert grouped object to array of objects
    return Object.keys(grouped).map((date) => ({
      date: date,
      sources: grouped[date],
    }));
  } else {
    return [];
  }
}

export function getEntitiesForHomePage(data) {
  try {
    let entities = {};
    let colorIndex = 0;

    Object.values(data).forEach((sourcesList) => {
      sourcesList.forEach((source) => {
        source.entities.forEach((e) => {
          if (e.text in entities) {
            entities[e.text].value += e.count;
          } else {
            entities[e.text] = {
              label: e.text,
              value: e.count,
              // color: colors[colorIndex % colors.length],
            };
            // colorIndex++;
          }
        });
      });
    });

    // Convert the dictionary to an array of objects and sort by count in descending order
    let sortedEntities = Object.values(entities).sort(
      (a, b) => b.value - a.value
    );

    // Get the top 9 entities
    let topEntities = sortedEntities.slice(0, 10);

    return topEntities; // Return the top entities
  } catch (e) {
    console.error(e);
    return [];
  }
}
export function getEntities(data, colors) {
  try {
    let entities = {};
    let colorIndex = 0;

    data.forEach((cluster) => {
      cluster.sources.forEach((source) => {
        source.entities.forEach((e) => {
          if (e.text in entities) {
            entities[e.text].value += e.count;
          } else {
            entities[e.text] = {
              label: e.text,
              value: e.count,
              color: colors[colorIndex % colors.length],
            };
            colorIndex++;
          }
        });
      });
    });

    // Convert the dictionary to an array of objects and sort by count in descending order
    let sortedEntities = Object.values(entities).sort(
      (a, b) => b.value - a.value
    );

    // Get the top 9 entities
    let topEntities = sortedEntities.slice(0, 6);

    return topEntities; // Return the top entities
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function getDomainName(url) {
  try {
    // Create a URL object from the URL string
    const urlObj = new URL(url);
    // Extract hostname from URL object
    const hostname = urlObj.hostname;

    // In 'www.example.com', 'example' would be the domain name we want to return.
    // This regex matches parts of the domain, excluding subdomains and the top-level domain.
    const match = hostname.match(/(?:www\.)?(.*?)\./);
    // If a match is found, return the domain name part, otherwise return the full hostname
    return match ? match[1] : hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

export function getTopFiveSources(clusters) {
  let sourceData = [];
  // console.log("Data in:", clusters);
  clusters.forEach((cluster) => {
    cluster.groupedSources.forEach((source) => {
      if (source.favicon) {
        sourceData.push(source.favicon);
      }
      if (source.senderEmail) {
        sourceData.push(source.senderEmail);
      }
    });
  });
  // console.log("Source Data: ", sourceData);

  return Array.from(new Set(sourceData));
}

export function getSourceUris(clusters) {
  let sourceData = [];
  // console.log("Data in:", clusters);
  clusters.forEach((cluster) => {
    cluster.groupedSources.forEach((source) => {
      if (source.imageUrl) {
        sourceData.push(source.imageUrl);
      }
      if (source.senderEmail) {
        sourceData.push(source.senderEmail);
      }
    });
  });
  // console.log("Source Data: ", sourceData);

  return Array.from(new Set(sourceData));
}
