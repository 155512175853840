import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#000000", // black
    },
    secondary: {
      main: "#ffc300", // yellow
    },
    tertiary: {
      main: "#FFFFFF", // Teal for tertiary actions and accents
    },
        fourth: {
      main: "#f0f0f0", // grey, inverted from white
        },
    error: {
      main: "#F44336", // Red for error states and warnings
    },

    background: {
      default: "#f5f5f5",
      paper: "#ffffff", //
    },
    text: {
      primary: "#333333", // Darker shade for primary text for better contrast
      secondary: "#555555", // Slightly lighter for secondary text
    },
    accent: {
      main: "#416788", // Amber accent color
    },
    success: {
      main: "#8BC34A", // Light green for success states
    },
    warning: {
      main: "#009688", // Dark teal for warning states
    },
    sideMenu: {
      main: "#FFFFFF",
    },
    action: {
      hover: "#e0e0e0",
      chip: "#f9f9f3", // f9f9f3 | fcf0cc
      like: "d31275",
      dislike: "0d3b66",
      commment: "ee964b",
    },
  },
  typography: {
    fontFamily: [
      '"Circular"', // Primary font
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),

    h1: {
      fontSize: "2.125rem", // 34px
      fontWeight: 700,
      lineHeight: 1.235,
    },
    h2: {
      fontSize: "1.75rem", // 28px
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem", // 24px
      fontWeight: 700,
      // lineHeight: 1.167,
    },
    h4: {
      fontSize: "1.25rem", // 20px
      fontWeight: 700,
      // lineHeight: 1.235,
    },
    h5: {
      fontSize: "1rem", // 16px
      fontWeight: 700,
      lineHeight: 1.334,
    },
    h6: {
      fontSize: "0.875rem", // 14px
      fontWeight: 700,
      lineHeight: 1.6,
    },
    subtitle1: {
      fontSize: "1rem", // 16px
      fontWeight: 400,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 500,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: "1rem", // 16px
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 400,
      lineHeight: 1.43,
    },

    caption: {
      fontSize: "0.75rem", // 12px
      fontWeight: 400,
      lineHeight: 1.66,
    },
    overline: {
      fontSize: "0.75rem", // 12px
      fontWeight: 400,
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "8px 16px",
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          margin: "10px", // Inside padding for content spacing
          backgroundColor: "#FFFFFF", // A clean white background
        },
        // If the card has a media section, you might want to style it as well
        media: {
          borderRadius: "8px", // Rounded corners for any media elements
          overflow: "hidden", // Ensures the content fits neatly within the rounded corners
        },
        // Styling for the content area inside the card
        content: {
          padding: "16px", // Spacing inside the content area
        },
        // Styling for actions at the bottom of the card
        actions: {
          padding: "16px", // Spacing inside the actions area
        },
      },
    },
    MuiDrawer: {},
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 8,
});

export default lightTheme;
