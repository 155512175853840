import * as React from "react";
// import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Avatar } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SparklesIcon from "@mui/icons-material/AutoAwesome"; // Replace with a suitable icon
import { LoginOutlined, LogoutOutlined } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";

import { Navigate, Outlet } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../common/hooks/useAuth";

import CustomAppBar from "../../../common/components/AppBar/CustomAppBar";
import { Button, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
// ICONS
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import ConstructionIcon from "@mui/icons-material/Construction";
import HiveIcon from "@mui/icons-material/Hive";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import ApiIcon from "@mui/icons-material/ApiRounded";

import LineStyleIcon from "@mui/icons-material/LineStyle";
import { ListSubheader } from "@mui/material";

import BookmarksIcon from "@mui/icons-material/Bookmarks";

import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import { ThemeModeContext } from "../../../App";
import darkThemeLogo from "../../../common/assets/DebriefsDarkMode.svg";
import lightThemeLogo from "../../../common/assets/Debriefs.svg";
import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import useCommonHook from "../../../common/hooks/useCommonHook";
import OnBoardingModal from "../../OnBoarding/OnBoardingModal";
import ComingSoonComponent from "../../../common/components/Buttons/ComingSoonButton";
import OnBoardingModalv2 from "../../OnBoarding/OnBoardingModalv2";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";

const drawerWidth = 200;

export default function DashboardContainerV2(props) {
  let navigate = useNavigate();
  const { user, logout } = useAuth();
  let userOnboarded = user?.onBoarded ?? true;
  const mode = React.useContext(ThemeModeContext);
  const { snackbarMessage, snackbarOpen, setSnackBar } = useCommonHook();
  const [view, setView] = React.useState("DebriefsView");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openOnboardModal, setOpenOnboardModal] = React.useState(userOnboarded);
  const location = useLocation();

  const { window } = props;
  const theme = useTheme();
  const handleTeamWorkspace = () => {
    window.open("https://debriefs.ai/contact-us", "_blank");
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAccountMenuItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenuItemClose = () => {
    setAnchorEl(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar(false, "");
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (!user) {
    return <Navigate to="/" replace />;
  }

  const drawer = (
    <>
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <a
          href="https://app.debriefs.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={mode.mode === "dark" ? darkThemeLogo : lightThemeLogo}
            alt="Logo"
            style={{
              height: "60px",
              width: "auto",
              marginBottom: "5px",
            }}
          />
        </a>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <List sx={{ width: drawerWidth }}>
          {[
            { text: "Home", url: "home", external: false, icon: <HomeIcon /> },
            {
              text: "Debriefs",
              url: "debriefs",
              external: false,
              icon: <AutoStoriesIcon />,
            },
            {
              text: "Topics",
              url: "my-topics",
              external: false,
              icon: <BookmarksIcon />,
            },
            
// {
//               text: "Search",
//               url: "https://search.debriefs.ai/",
//               external: true,
//               icon: <ManageSearchIcon />,
// },
          ].map((item, index) => (
            <ListItem
              key={item.text}
              sx={{
                width: drawerWidth,
              }}
            >
              <ListItemButton
                selected={view === item.text}
                onClick={() => {
                  setView(item.text);
                  if (item.external) {
                    const link = document.createElement("a");
                    link.href = item.url;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    document.body.appendChild(link); // Append to the document
                    link.click(); // Simulate click
                    document.body.removeChild(link);
                  } else {
                    navigate(`/dashboard/${item.url}`);
                  }
                }}
                sx={{
                  padding: "10px 20px", // Add padding
                  backgroundColor:
                    view === item.text ? "#f7f7f7" : "transparent", // Change background color when selected
                  borderRadius: view === item.text ? "10px" : "0px", // Add rounded corners when selected
                  "&:hover": {
                    backgroundColor: "#f7f7f7", // Add hover effect
                  },
                  display: "flex", // Add this line
                  justifyContent: "center", // Add this line
                }}
              >
                <ListItemIcon
                  sx={{
                    color: view === item.text ? "#545c67" : "inherit",
                    minWidth: "45px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>{" "}
                {/* Change color when selected and adjust minWidth */}
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}

          {user.type === "Admin" ? (
            <>
              <Divider />
              <ListSubheader>Admin</ListSubheader>
              {[
                {
                  text: "Account",
                  url: "account",
                  icon: <PersonOutlineIcon />,
                },
                {
                  text: "Schedules",
                  url: "my-schedules",
                  external: false,
                  icon: <HiveIcon />,
                },
                {
                  text: <span>Integrations</span>,
                  url: "my-integrations",
                  external: false,
                  icon: <ApiIcon />,
                },
                {
                  text: "Headlines",
                  url: "sources",
                  icon: <FormatListBulletedIcon />,
                },
                {
                  text: "Source Management",
                  url: "manageSources",
                  icon: <LineStyleIcon />,
                },
                {
                  text: "Debrief Builder",
                  url: "debrief-builder",
                  icon: <ConstructionIcon />,
                },
              ].map((item, index) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    selected={view === item.text}
                    onClick={() => {
                      setView(item.text);
                      navigate(`/dashboard/${item.url}`);
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </>
          ) : null}
        </List>
      </Box>
      <Divider></Divider>
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <a
          href="https://www.producthunt.com/posts/debriefs?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-debriefs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=458598&theme=light"
            alt="Debriefs - Synthesize&#0032;media&#0032;for&#0032;news&#0032;consumption&#0032;and&#0032;industry&#0032;knowledge | Product Hunt"
            style={{ width: "180px", height: "54px" }}
          />
        </a>
      </Box>

      {/** 
<Button
  onClick={handleTeamWorkspace}
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
    margin: 2,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#f7f7f7',
      transform: 'translateY(-2px)',
    },
    mx: 'auto',
    width: 160, // Set width to 160px
    maxWidth: '400px',
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 2,
    }}
  >
    <SparklesIcon sx={{ fontSize: 24, marginRight: 1, color: '#4285F4' }} />
    <Typography variant="body2" fontWeight="bold" sx={{ color: '#333333' }}>
      Create a Workspace For Business
    </Typography>
  </Box>
  <Box
    sx={{
      display: 'inline-block',
      backgroundColor: 'rgba(66, 133, 244, 0.1)',
      borderRadius: '20px',
      padding: '8px 16px',
      marginBottom: 2,
    }}
  >
    <Typography variant="body1" sx={{ color: '#4285F4', fontWeight: 'bold' }}>
      New
    </Typography>
  </Box>
</Button>*/}

      <Button
        onClick={handleAccountMenuItem} // Add this line
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center the content
          padding: "10px 10px",
          backgroundColor: "primary",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          maxWidth: "180px",
          width: "100%",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "secondary",
          },
          mx: "auto", // Add horizontal margins
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", ml: 2, mr: 2 }}>
          <Avatar
            sx={{
              bgcolor: "#b39ddb",
              width: 30,
              height: 30,
              fontSize: 14,
              marginRight: 1,
            }}
          >
            {user.firstName.charAt(0).toUpperCase()}
          </Avatar>{" "}
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "120px",
              }}
            >
              {user.firstName}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#757575",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "120px",
              }}
            >
              Free Tier
            </Typography>
          </Box>
        </Box>
        <ArrowDropDownIcon sx={{ color: "#757575" }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleAccountMenuItemClose}
        anchorOrigin={{
          vertical: "top", // Change this to 'top'
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom", // Change this to 'bottom'
          horizontal: "center",
        }}
      >
        <MenuItem
          component="a"
          href="https://debriefs.ai/contact-us"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleAccountMenuItemClose}
        >
          <ListItemIcon>
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: "rgba(66, 133, 244, 0.1)",
                borderRadius: "4px",
                padding: "2px 6px",
                marginLeft: "auto",
                marginRight: "6px",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#4285F4",
                  fontWeight: "bold",
                }}
              >
                Pro
              </Typography>
            </Box>
          </ListItemIcon>
          <b>Upgrade your account</b>
        </MenuItem>
        <MenuItem
          onClick={() => {
            console.log("Log out clicked");
            logout();
            handleAccountMenuItemClose();
          }}
        >
          <ListItemIcon>
            <LogoutOutlined fontSize="small" sx={{ color: "#000" }} />{" "}
            {/* Modify this line */}
          </ListItemIcon>
         <b> Logout</b>
        </MenuItem>
        {/* Add more MenuItem components here for other options */}
      </Menu>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <Snackbar
        open={snackbarOpen}
        onClose={handleClose}
        autoHideDuration={3000}
        message={snackbarMessage}
        key={snackbarMessage}
      />

      <OnBoardingModalv2
        open={!openOnboardModal}
        setOpenOnboardModal={setOpenOnboardModal}
      />

      <CustomAppBar logout={logout} handleDrawerToggle={handleDrawerToggle} />

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.sideMenu.main,
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.sideMenu.main,
            borderRight: "none",
          },
          width: drawerWidth,
        }}
        open
      >
        {drawer}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Toolbar />
        {/* CONTENT WILL BE DISPLAYED HERE */}
        <Outlet />
      </Box>
    </Box>
  );
}
