import React from 'react';
import styled from 'styled-components';

const StyledOverview = styled.a`
  background-color: #f0f0f0;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  color: #333;
  padding: 2px 4px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: -0.8em;
  margin-left: 0.4em;
`;

const BetaButton = () => {
  return (
    <StyledOverview href="https://r3xoyxf2izi.typeform.com/to/TNt8ZVOe?typeform-source=admin.typeform.com" target="_blank" rel="noopener noreferrer">
      Beta
    </StyledOverview>
  );
};

export default BetaButton;


