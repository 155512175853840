import React, { useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import NoteIcon from "@mui/icons-material/Note";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { useTheme } from "@mui/material/styles"; // Import useTheme
import axios from "axios";
import useCommonHook from "../../hooks/useCommonHook";
import { Typography, Tooltip } from "@mui/material";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function FeedbackSection({ insightId }) {
  const theme = useTheme();
  const { setSnackBar } = useCommonHook();
  const [open, setOpen] = useState(false);
  // const [openAlert, setOpenAlert] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("");
  const [disable, setDisable] = useState(false);

  const handleThumbUp = () => {
    // Send a thumb up feedback to the backend to store in MongoDB
  };

  const handleThumbDown = () => {
    // Open the dialog for detailed feedback
    setOpen(true);
  };

  const handleNoteSubmit = async () => {
    setDisable(true);
    let feedback = await axios.post(
      `${prodApi}/content/add-comment-to-debrief`,
      {
        id: insightId,
        comment: feedbackNote,
      }
    );

    console.log("Feedback posted: ", feedback);
    setDisable(false);
    setSnackBar(true, "Thank you for submitting your feedback!");
    setOpen(false);
  };

  return (
    <>
      {/* <IconButton aria-label="thumb up" onClick={handleThumbUp}>
        <FavoriteBorderIcon
          style={{
            fontSize: 20,
            color: theme.palette.action.like,
          }}
        />
      </IconButton>
      <IconButton aria-label="thumb down" onClick={handleThumbDown}>
        <ThumbDownAltOutlinedIcon
          style={{ fontSize: 20, color: theme.palette.action.dislike }}
        />
      </IconButton> */}

<Tooltip title="Give feedback" top arrow>
  <IconButton aria-label="add note" onClick={() => setOpen(true)}>
    <EditNoteOutlinedIcon
      style={{ fontSize: 20, color: theme.palette.action.commment }}
    />
  </IconButton>
</Tooltip>

      {/* Feedback dialog */}
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Share Fedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Feedback"
            type="text"
            fullWidth
            variant="outlined"
            value={feedbackNote}
            onChange={(e) => {
              setFeedbackNote(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleNoteSubmit} disabled={disable}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default FeedbackSection;
