import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  openDelete: false,
  openRemove: false,
};
const ManageSourcesSlice = createSlice({
  name: "manageSources",
  initialState: INITIAL_STATE,
  reducers: {
    setOpenDelete: (state, action) => {
      state.openDelete = action.payload;
    },
    setOpenRemove: (state, action) => {
      state.openRemove = action.payload;
    },
  },
});

export const ManageSourcesSliceActions = ManageSourcesSlice.actions;
export const ManageSourcesSliceReducer = ManageSourcesSlice.reducer;
