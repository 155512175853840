import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RingLoader from "react-spinners/RingLoader";
const BackdropLoader = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: "#F9F9F3",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: { sm: `calc(100% - ${200}px)` },
        ml: { sm: `${200}px` },
      }}
      open={isLoading}
    >
      {/* <CircularProgress color="inherit" /> */}
      <RingLoader
        loading={isLoading}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
        color={"#ffffff"}
      />
    </Backdrop>
  );
};

export default BackdropLoader;
