import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";

export default function PieChartComponent(p) {
  return (
    <Stack width="100%" height="100%">
      {/* Container for horizontal scrolling */}
      <Box
        sx={{
          overflowX: "auto", // Enable horizontal scrolling
          width: "100%", // Take full width of the parent
          height: "auto", // Height is auto to adjust to the content
          whiteSpace: "nowrap", // Prevent wrapping of items inside
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PieChart
          series={[
            {
              data: p.entities,
              paddingAngle: 3,
              innerRadius: 15,
              cornerRadius: 5,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            },
          ]}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "bottom", horizontal: "left" },

              padding: 0,
              itemMarkWidth: 5,
              itemMarkHeight: 5,
              markGap: 5,
              itemGap: 10,
            },
          }}
          // margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
          height={200}
          width={650}
        />
      </Box>
    </Stack>
  );
}
