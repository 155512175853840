import * as React from "react";
import {
  Button,
  Divider,
  Tooltip,
  AvatarGroup,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ExpandMoreIcon,
  Grid,
  Chip,
  Stack,
  Avatar,
  IconButton,
  useTheme,
  Drawer, // Import useTheme hook
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef } from 'react';
import { useState } from "react";
import ShareButton from "../Buttons/ShareButton";
import moment from "moment-timezone";
import ReactDOM from "react-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Components
import SummaryViewv1 from "../Summary Views/SummaryViewv1";
import DateFormatString from "../Date/DateFormatString";
import FeedbackSection from "../UserFeedback/FeedbackSection";
import BulletSummaryView from "../Summary Views/BulletSummaryView";
import BlindspotView from"../Summary Views/BlindspotView";
import ImportantQuestions from "./ImportantQuestions";
import SourcesCarousel from "./SourcesCarousel";
import ChatWithDebrief from "../../components/Chat/ChatDebrief/ChatWithDebrief";
import SourcesDisplayInContext from "./SourcesInContextView";
import { formatDateNumeric } from "../../../common/Utilities/DateUtilities";

import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
// Firebase
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import ShareIcon from "@mui/icons-material/Share";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Chat from "@mui/icons-material/Chat";
import Backdrop from "@mui/material/Backdrop";
import RevealMoreIcon from '@mui/icons-material/Visibility'; // Import the icon you want to use

import {
  reorganizeClusters,
  getTopFiveSources,
  getSourceUris,
} from "../../Utilities/SourceUtilities";

export default function ClusterSummaryView(p) {
  const { onQuestionClick } = p;

  const localDate = moment.utc(p.item.createdAt).local();
  const [sources, setSources] = React.useState([]);
  const [sourceUris, setSourceUris] = React.useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showQuestionDetails, setShowQuestionDetails] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const theme = useTheme(); // Access the theme
  // Formatting the date and time
  const formattedDate = localDate.format("MMMM DD, YYYY");
  const formattedTime = localDate.format("hh:mm A");
  const iconStyle = { fontSize: "small", marginRight: "4px" }; // Added marginRight for some spacing

  
  const chipStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.action.chip,
    color: theme.palette.text.primary, // Dark grey font
    borderRadius: "20px", // Rounded corners
    padding: "4px 8px", // Padding inside the chip
    marginRight: "8px", // Space between chip and dynamic variable
  };
  const gridItemStyleChip = {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
  };
  const iconStyleCategory = {
    fontSize: "20px",
    verticalAlign: "middle",
    marginRight: "6px",
  };

  const labelStyle = { fontSize: "15px" }; // Dark grey font for label

  const dynamicTextStyle = { fontSize: "15px", flexGrow: 1, fontWeight: "500" };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setShowQuestionDetails(true);
  };
  const [expandedHeight, setExpandedHeight] = useState('400px');
  const [showButton, setShowButton] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentHeight = contentRef.current.scrollHeight;
    if (parseInt(expandedHeight) >= contentHeight) {
      setShowButton(false);
    }
  }, [expandedHeight]);

  const handleGoDeeper = () => {
    setExpandedHeight((prevHeight) => {
      const newHeight = parseInt(prevHeight) + 400;
      return `${newHeight}px`;
    });
  };

  // Function to handle thumbs down feedback
  const handleThumbsDown = async (feedbackId) => {
    try {
      const response = await axios.post("/api/feedback/thumbs-down", {
        feedbackId: feedbackId,
      });
      console.log("Thumbs down submitted successfully", response.data);
      // Additional logic after successful submission
    } catch (error) {
      console.error("Error submitting thumbs down", error);
    }
  };

  // Function to handle note submission
  const handleNoteSubmit = async (feedbackId, note) => {
    try {
      const response = await axios.post("/api/feedback/note", {
        feedbackId: feedbackId,
        note: note,
      });
      console.log("Note submitted successfully", response.data);
      // Additional logic after successful submission
    } catch (error) {
      console.error("Error submitting note", error);
    }
  };

  // A utility function to safely parse JSON and return a default value if parsing fails
  function safeParseJSON(jsonString, defaultValue = {}) {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return defaultValue;
    }
  }

  // In the parent component or the script where you want to use the section object
  function handleSectionClick(section) {
    // Use the section object here
    console.log(section);
  }
  // Safely parse JSON and extract the title if possible
  const generalSummary = safeParseJSON(p.item.generalSummary);
  const title = generalSummary.title || ""; // Provide a default title if none is found

 const type =
  "type" in p.item && p.item.type === "sections"
    ? "Deep Dive"
    : "type" in p.item && p.item.type === "blindspot"
    ? "Blindspot Analysis"
    : "General Overview";
  React.useEffect(() => {
    let clusterGroupedSources = reorganizeClusters(p.item.clusters);
    // console.log("Cluster sources: ", p.item.clusters);
    // console.log("Sources: ", getTopFiveSources(clusterGroupedSources));
    setSources(getTopFiveSources(clusterGroupedSources));
    setSourceUris(getSourceUris(clusterGroupedSources));
  }, []);

const analysisType =
  "category" in p.item && p.item.category === "Blindspot Analysis"
    ? "Blindspot Analysis"
    : "General Overview";

React.useEffect(() => {
  let clusterGroupedSources = reorganizeClusters(p.item.clusters);
  // console.log("Cluster sources: ", p.item.clusters);
  // console.log("Sources: ", getTopFiveSources(clusterGroupedSources));
  setSources(getTopFiveSources(clusterGroupedSources));
  setSourceUris(getSourceUris(clusterGroupedSources));
}, []);


  

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // style={{ marginBottom: "20px" }}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          




          <Typography variant="h2" component="div" style={{ fontWeight: 900 }}>
            {p.item.title} | {title} {/* Use the safely parsed title here */}
          </Typography>
        </Box>
      </Grid>

      <Grid item container xs={12} sm={6}>
        {/* Topic */}
        <Grid item xs={12} style={gridItemStyleChip}>
          <div style={chipStyle}>
            <span
              className="material-symbols-outlined"
              style={iconStyleCategory}
            >
              event_note
            </span>
            <span style={labelStyle}>
              <b>Analysis</b>
            </span>
          </div>
          <Typography component="h6" style={dynamicTextStyle}>
            {p.item.topic}
          </Typography>
        </Grid>

        {/* Frequency */}
        <Grid item xs={12} style={gridItemStyleChip}>
          <div style={chipStyle}>
            <span
              className="material-symbols-outlined"
              style={iconStyleCategory}
            >
              autorenew
            </span>
            <span style={labelStyle}>
              <b>Frequency</b>
            </span>
          </div>
          <Typography component="h6" style={dynamicTextStyle}>
            {p.item.frequency.charAt(0).toUpperCase() +
              p.item.frequency.slice(1)}
          </Typography>
        </Grid>

        {/* Coverage */}
        <Grid item xs={12} style={gridItemStyleChip}>
          <div style={chipStyle}>
            <span
              className="material-symbols-outlined"
              style={iconStyleCategory}
            >
              event_available
            </span>
            <span style={labelStyle}>
              <b>Coverage</b>
            </span>
          </div>
          <Typography component="h6" style={dynamicTextStyle}>
            {p.item.startDate
              ? `${formatDateNumeric(p.item.startDate)} to ${formatDateNumeric(
                  p.item.createdAt
                )}`
              : "N/A"}
          </Typography>
        </Grid>

        {/* Debrief Type
        <Grid item xs={12} style={gridItemStyleChip}>
          <div style={chipStyle}>
            <span
              className="material-symbols-outlined"
              style={iconStyleCategory}
            >
              description
            </span>
            <span style={labelStyle}>
              <b>Type</b>
            </span>
          </div>
          <Typography component="h6" style={dynamicTextStyle}>
            {type}
          </Typography>
        </Grid> */}

        {/* Published On */}
        <Grid item xs={12} style={gridItemStyleChip}>
          <div style={chipStyle}>
            <span
              className="material-symbols-outlined"
              style={iconStyleCategory}
            >
              schedule
            </span>
            <span style={labelStyle}>
              <b>Published</b>
            </span>
          </div>
          <Typography component="h6" style={dynamicTextStyle}>
            {formattedDate} at {formattedTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid item xs={12}>
          <SourcesCarousel slides={sourceUris} options={{ loop: true }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <b>Sources</b>
          </Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            {sources.length > 0 ? (
              <AvatarGroup
                max={8}
                sx={{
                  ".MuiAvatarGroup-avatar": {
                    width: 30,
                    height: 30, // Apply to all avatars
                  },
                  // Specific customization for overflow indicator if needed
                }}
              >
                {sources.map((source, index) => (
                  <Tooltip key={index} title={source.name}>
                    <Avatar
                      alt={source}
                      src={source}
                      sx={{
                        width: 30,
                        height: 30, // Individual avatar styles
                        // Any specific styles for each avatar
                      }}
                    />
                  </Tooltip>
                ))}
              </AvatarGroup>
            ) : null}
          </Stack>
        </Grid>
      </Grid>

      {analysisType === "General Overview" ? (
  <Grid item xs={12}>
    <Box
      sx={{
        maxHeight: expandedHeight,
        overflow: 'hidden',
        position: 'relative',
        transition: 'max-height 0.3s ease',
        '&::after': {
          content: showButton ? '""' : 'none',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100px',
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
          pointerEvents: 'none',
        },
      }}
    >
      <div ref={contentRef}>
      <Typography variant="h3" component="div">
      Highlights
    </Typography>
        <BulletSummaryView text={p.item.generalSummary} />  {/** I have to change this back to BulletSummaryView as this is for testing purposes */}
      </div>
    </Box>
    {showButton && (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleGoDeeper}>Reveal more</Button>
      </Box>
    )}
  </Grid>
) : analysisType === "Blindspot Analysis" ? (
  <Grid item xs={12}>
    <Typography variant="h3" component="div">
      Insights
    </Typography>
    <Box
      sx={{
        maxHeight: expandedHeight,
        overflow: 'hidden',
        position: 'relative',
        transition: 'max-height 0.3s ease',
        '&::after': {
          content: showButton ? '""' : 'none',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100px',
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
          pointerEvents: 'none',
        },
      }}
    >
      <div ref={contentRef}>
        <BlindspotView text={p.item.generalSummary} />
      </div>
    </Box>
    {showButton && (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleGoDeeper}>Reveal more</Button>
      </Box>
    )}
  </Grid>
) : (
  <></>
)}

      <Grid item xs={12}>
        <ImportantQuestions
          item={p.item}
          sources={p.item.clusters}
          baseUrl={window.location.origin}
          onQuestionClick={handleQuestionClick} // This should now handle the full question object
          onSectionClick={handleSectionClick}
          selectedQuestion={selectedQuestion}
          setShowQuestionDetails={setShowQuestionDetails}
        />
      </Grid>

      <Grid item xs={12}>
        <FeedbackSection insightId={p.item._id} />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& button": { m: 1 },
          }}
        >
          {/** 
          <Button
            size="small"
            onClick={() => {
              console.log("Summary id: ", p.item._id);
              // Log an event with Firebase Analytics
              logEvent(analytics, "read_more", {
                name: "read_more_button",
                label: "Read More",
                target_url: `${window.location.origin}/shared/${p.item._id}`,
              });

              const baseUrl = window.location.origin; // This gets the base URL of your app
              const sharedUrl = `${baseUrl}/shared/${p.item._id}`;

              window.open(sharedUrl, "_blank", "noopener,noreferrer");
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "18px", // Makes the icon smaller
                marginRight: "4px", // Adds some distance between the icon and text
                verticalAlign: "middle", // Vertically centers the icon with the text
                color: theme.palette.accent.main,
              }}
            >
              table_chart_view
            </span>
            Read More
          </Button>*/}

          <Button
            size="small"
            onClick={() => {
              const baseUrl = window.location.origin; // This gets the base URL of your app
              const sharedUrl = `${baseUrl}/shared/${p.item._id}`;
              // Log an event with Firebase Analytics
              // Log an event with Firebase Analytics
              logEvent(analytics, "share_debrief", {
                name: "share_debrief",
                label: "Share Button for Debrief",
                shared_url: sharedUrl,
              });

              navigator.clipboard
                .writeText(sharedUrl)
                .then(() => {
                  alert("Link copied to clipboard");
                })
                .catch((err) => {
                  console.error("Failed to copy link: ", err);
                });
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "18px",
                marginRight: "4px",
                verticalAlign: "middle",
                color: theme.palette.accent.main,
              }}
            >
              share
            </span>
            Share
          </Button>

{/** To be continued, new share button
            <ShareButton
  getUrl={() => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/shared/${selectedQuestion._id}`;
  }}
  
  onShare={(url) => {
    logEvent(analytics, "share_question", {
      name: "share_question",
      label: "Share Button for Question",
      shared_url: url,
    });
  }}
/>*/}











          <Button
      size="small"
      onClick={() => {
        setOpenModal(true);
      }}
    >
      <span
        className="material-symbols-outlined"
        style={{
          fontSize: "18px",
          marginRight: "4px",
          verticalAlign: "middle",
          color: theme.palette.accent.main,
        }}
      >
        chat
      </span>
      Chat with Debrief
    </Button>
        </Box>
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          maxWidth={false}
          fullWidth
          PaperProps={{
            style: {
              width: "80%",
              height: "80%",
              maxWidth: "none",
              maxHeight: "none",
              borderRadius: 12,
            },
          }}
        >
          <DialogTitle>Chat with Debrief</DialogTitle>
          <DialogContent
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 12,
            }}
          >
           <ChatWithDebrief sources={p.item.clusters} />
          </DialogContent>
        </Dialog>
      </Grid>

      <Drawer
        anchor="right"
        open={showQuestionDetails}
        onClose={() => setShowQuestionDetails(false)}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: 400, md: 600 }, // Updated width based on screen size
            padding: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <IconButton
            onClick={() => setShowQuestionDetails(false)}
            sx={{ position: "absolute", right: 16, top: 16 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <InsightsIcon
              sx={{ fontSize: 32, mr: 2, color: theme.palette.primary.main }}
            />
            <Typography
              variant="h2"
              sx={{
                fontSize: 32,
                fontWeight: "bold",
                fontFamily: "SF Pro Display, Airbnb Cereal, sans-serif",
                color: theme.palette.text.primary,
              }}
            >
            Go Deeper

            </Typography>
          </Box>
          {selectedQuestion && (
            <>
              {console.log(selectedQuestion)}
              <Box
                sx={{
                  mb: 3,
                  padding: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    mb: 1,
                    fontFamily: "SF Pro Text, Airbnb Cereal, sans-serif",
                    color: theme.palette.text.primary,
                  }}
                >
                  {selectedQuestion.title}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: 16,
                    lineHeight: 1.6,
                    fontFamily: "SF Pro Text, Airbnb Cereal, sans-serif",
                    color: theme.palette.text.secondary,
                  }}
                  dangerouslySetInnerHTML={{ __html: selectedQuestion.summary }}
                />
              </Box>
              {/* Add SourcesDisplayInContext here */}
    <Box sx={{ mb: 3 }}>
      <SourcesDisplayInContext sources={selectedQuestion.sources} />
    </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ShareIcon />}
                  sx={{ mr: 2 }}
                  onClick={() => {
                    const baseUrl = window.location.origin;
                    const sharedUrl = `${baseUrl}/shared/${p.item._id}`;
                    logEvent(analytics, "share_question", {
                      name: "share_question",
                      label: "Share Button for Question",
                      shared_url: sharedUrl,
                    });

                    navigator.clipboard
                      .writeText(sharedUrl)
                      .then(() => {
                        alert("Link copied to clipboard");
                      })
                      .catch((err) => {
                        console.error("Failed to copy link: ", err);
                      });
                  }}
                >
                  Share
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </Grid>
  );
}
