import { Grid } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../common/Utilities/DateUtilities";

// VIEWS

import ControlledChatComponent from "../../../common/components/Chat/ControlledChat/ControlledChatComponent";
import TopicSourceManager from "./TopicSourceManager";
import GoBackButton from "../../../common/components/Buttons/GoBackButton";
import CardBorderNoHover from "../../../common/components/Cards/CardBorderNoHover";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import ComingSoonComponent from "../../../common/components/Buttons/ComingSoonButton";
import BetaButton from "../../../common/components/Buttons/BetaButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TopicManager() {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [selectedTopic, setSelectedTopic] = React.useState();
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);

  const [loading, setLoading] = useState(true);

  const [mainUser, setMainUser] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    async function getTopic() {
      //  Fetch user object
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });
      console.log("User response: ", userResponse.data);
      if (userResponse.data) {
        setMainUser(userResponse.data);
        // Fetch all search topics for options
        let topicData = await axios.get(
          `${prodApi}/sourcesV2/fetch-topic/${id}`,
          {
            userId: userobj._id,
          }
        );
        console.log("Topic: ", topicData);
        setSelectedTopic(topicData.data.topic);
      }
      setLoading(false);
    }
    getTopic();
  }, []);

  return (
    <div>
      <PageHead
        title="Topic Manager"
        description="Design and schedule personalized debriefs across a variety of topics. Craft a professional news intelligence feed tailored to your industry and interests."
      />
      <>
        {loading ? (
          <></>
        ) : (
          <Grid
            container
            spacing={2}
            // style={{ width: "100%", backgroundColor: "red" }}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <GoBackButton navigateTo="/dashboard/my-topics" />
              </div>
            </Grid>
            <Grid item xs>
              <CardBorderNoHover>
                <Typography variant="h5" component="div">
                  {selectedTopic.searchQuery}
                </Typography>
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                ></Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Active since: {<b>{formatDate(selectedTopic.createdAt)}</b>}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Status:{" "}
                  {<b>{selectedTopic.isActive ? "Active" : "Frozen"}</b>}
                </Typography>
              </CardBorderNoHover>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                        label={
                          <span>
                            Chat
                            <BetaButton />
                          </span>
                        }
                        
                        {...a11yProps(1)}
                      />                
                  <Tab label="Sources" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel
                value={value}
                index={0}
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "70vh",
                  }}
                >
                  <ControlledChatComponent
                    title={`Ask anything about ${selectedTopic.searchQuery}`}
                    searchQuery={selectedTopic.searchQuery}
                  />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TopicSourceManager query={selectedTopic.searchQuery} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Report Builder
              </CustomTabPanel>
            </Grid>
            <Grid item xs={12}>
              <Backdrop
                sx={{
                  color: "#F9F9F3",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
                onClick={() => {}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        )}
      </>
    </div>
  );
}

export default TopicManager;
