import * as React from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Alert from "@mui/material/Alert";

import im from "../../../common/assets/sunset.png";
import logo from "../../../common/assets/Debriefs.svg";

import { useAuth } from "../../../common/hooks/useAuth";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { MobileRegisterSection } from "./MobileRegisterSection";
import GradientButton from "../../../common/components/Buttons/GradientButton";

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit">Debriefs.ai</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function RegisterContainer() {
  const { register } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [allowSignUp, setAllowSignUp] = React.useState(false);

  const [validInvitationCode, setValidInvitationCode] = React.useState(true);

  const [registerSuccess, setRegisterSuccess] = React.useState({
    state: false,
    message: "",
  });

  const handleLoading = () => {
    // setDisable(!disableLogin);
    setLoading(!loading);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Register clicked", event);
    // disabled until we want to allow people to register
    // let success = await register(event, handleLoading);
    const data = new FormData(event.currentTarget);
    if (data.get("invitationCode").length > 0) {
      setValidInvitationCode(false);
    }
  };

  const handleClose = () => {
    setLoading(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      {/* Web View */}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <div>
              <Box
                sx={{
                  paddingLeft: 5,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                <img
                  src={logo}
                  alt="Debriefs AI"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Box>
              <img
                src={im}
                alt="sideView"
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  borderTopRightRadius: "50px",
                }}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            {allowSignUp ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                    // height: "85vh",
                  }}
                >
                  <div style={{ padding: 5 }}>
                    <Typography component="h1" variant="h5" fontWeight={900}>
                      Get started
                    </Typography>
                  </div>

                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="family-name"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          variant="standard"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {registerSuccess.state ? (
                          <>
                            {registerSuccess.message === "error" ? (
                              <>
                                <Alert severity="error">
                                  Error registering user
                                </Alert>
                              </>
                            ) : (
                              <>
                                <Alert severity="success">
                                  User Created Succesfuly
                                </Alert>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        borderRadius: "15px",
                        background: "linear-gradient(90deg, #ff385c, #c6017e)", // Gradient background
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, #ff5075, #d6018e)", // Brighter gradient for hover effect
                        },
                      }}
                    >
                      Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <GradientButton text={"Need an account?"} />
                      </Grid>
                      <Grid item>
                        <Link
                          href="/"
                          variant="body2"
                          sx={{
                            color: "#c6017e",
                            fontWeight: "bold",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#c6017e", // Same color as text
                            },
                          }}
                        >
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                    // height: "85vh",
                  }}
                >
                  <div style={{ padding: 5 }}>
                    <Typography component="h1" variant="h5" fontWeight={900}>
                      Hey! Do you have an invitation code?
                    </Typography>
                  </div>

                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="invitationCode"
                          label="Invitation Code"
                          variant="standard"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {validInvitationCode ? (
                          <></>
                        ) : (
                          <>
                            <Alert severity="error">Invalid code</Alert>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        borderRadius: "15px",
                        background: "linear-gradient(90deg, #ff385c, #c6017e)", // Gradient background
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, #ff5075, #d6018e)", // Brighter gradient for hover effect
                        },
                      }}
                    >
                      Check Access
                    </Button>
                    <Grid container justifyContent="flex-end" spacing={5}>
                      <Grid item>
                        <GradientButton text={"Need an account?"} />
                      </Grid>
                      <Grid item>
                        <Link
                          href="/"
                          variant="body2"
                          sx={{
                            color: "#c6017e",
                            fontWeight: "bold",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#c6017e", // Same color as text
                            },
                          }}
                        >
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <Box
          component="footer"
          sx={{
            py: 2,
            mt: "auto",
            backgroundColor: "transparent",
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
        >
          <Copyright />
        </Box>
      </Box>
      {/* MOBILE VIEW  */}
      <MobileRegisterSection
        registerSuccess={registerSuccess}
        setValidInvitationCode={setValidInvitationCode}
        handleSubmit={handleSubmit}
        validInvitationCode={validInvitationCode}
      />
      <Copyright sx={{ mt: 1 }} />
    </>
  );
}
