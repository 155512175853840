import React, { useState } from "react";
import { Chip, Box } from "@mui/material";

const SearchOptions = ({ onSearch, optionEmojis }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
      onSearch(selectedOptions.filter((item) => item !== option).join(" "));
    } else {
      setSelectedOptions([...selectedOptions, option]);
      onSearch([...selectedOptions, option].join(", "));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        mb: 2,
        justifyContent: "center",
      }}
    >
      {optionEmojis.map(({ option, emoji }) => (
        <Chip
          key={option}
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box component="span" sx={{ mr: 1, fontSize: "1.2rem" }}>
                {emoji}
              </Box>
              {option}
            </Box>
          }
          onClick={() => handleOptionClick(option)}
          color={selectedOptions.includes(option) ? "primary" : "default"}
          clickable
          sx={{
            border: "1px solid",
            borderColor: selectedOptions.includes(option)
              ? "primary.main"
              : "grey.200",
          }}
        />
      ))}
    </Box>
  );
};

export default SearchOptions;
