// rootReducer.js

import { combineReducers } from "redux";
import { ManageSourcesSliceReducer } from "../../adminModules/ManageSources/reducer/ManageSourcesReducer";
import { AccountPreferencesSliceReducer } from "../../modules/AccountPreferences/reducers/AccountPreferencesReducer";
import { commonSliceReducer } from "../../common/reducers/CommonReducer";

const rootReducer = combineReducers({
  manageSources: ManageSourcesSliceReducer,
  accountPreferences: AccountPreferencesSliceReducer,
  commonSlice: commonSliceReducer,
});

export default rootReducer;
