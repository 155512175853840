import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import axios from "axios";
import TopicCard from "../../../common/components/Cards/TopicCard";

import PageHead from "../../../common/components/Helmet/DebriefsHelmet";

// import RingLoader from "react-spinners/RingLoader";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
// FAB
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

// AUTOCOMPLETE muiv5
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// DIALOG
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

// TAB PANEL
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function MyTopicsContainer() {
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);

  const [openLoading, setOpenLoading] = useState(false);
  const [mainUser, setMainUser] = React.useState({});

  const [allAvailableWebSearchTopics, setAllAvailableWebSearchTopics] =
    useState([]);
  const [userTopics, setUserTopics] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");

  // SEARCH TOPIC JOIN/CREATE
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newQuery, setNewQuery] = useState("");

  // TAB PANEL
  const [actionType, setActionType] = useState(0);

  // REMOVE MODAL
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [disableDelete, setDisabelDelete] = useState(false);

  function compare(a, b) {
    if (a.searchQuery < b.searchQuery) {
      return -1;
    }
    if (a.searchQuery > b.searchQuery) {
      return 1;
    }
    return 0;
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setActionType(newValue);
  };

  const handleAutocompleteChange = (event, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
  };

  const handleDelete = async () => {
    try {
      setDisabelDelete(true);

      let deleteResponse = await axios.post(
        `${prodApi}/sourcesV2/remove-user-from-topic`,
        {
          queryId: selectedQuery._id,
          userId: userobj._id,
        }
      );
      if (deleteResponse.data.status !== 500) {
        // Fetch all topics a user joined
        let userTopicsData = await axios.get(
          `${prodApi}/sourcesV2/user-topics/${userobj._id}`
        );
        setUserTopics(userTopicsData.data.userTopics);

        setOpenRemove(false);
        setDisabelDelete(false);
      } else {
        alert("Error deleting query");
        setDisabelDelete(false);
      }
    } catch (e) {
      alert("Error deleting query");
      setDisabelDelete(false);
    }
  };
  const handleJoinTopics = async () => {
    console.log("Handle join topics");
    try {
      // add user to topic
      let joined = [];
      if (selectedOptions.length > 0) {
        for (let optionToJoin of selectedOptions) {
          console.log("Option to join", optionToJoin);
          let response = await axios.post(`${prodApi}/sourcesV2/join-topic`, {
            queryId: optionToJoin._id,
            userId: userobj._id,
          });
          console.log("response data: ", response.data);
          joined.push(response.data.query);
        }
      }
      console.log("Joined: ", joined);
      let copy = [...userTopics];
      copy = copy.concat(joined);
      console.log("Copy: ", copy);
      // UPDATE USER TOPICS
      setUserTopics(copy);
      // UPDATE AVAILABLE OPTIONS
      setAllAvailableWebSearchTopics(
        allAvailableWebSearchTopics
          .filter((item) => !userTopics.some((topic) => topic._id === item._id))
          .sort(compare)
      );
      setSelectedOptions([]);
      setNewQuery("");
      handleClose();
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };

  const handleCreateNewTopic = async () => {
    try {
      console.log("Topic to create: ", newQuery);
      // create new topic
      let response = await axios.post(`${prodApi}/sourcesV2/create-topic`, {
        query: newQuery,
        userId: userobj._id,
      });
      response = response.data;

      console.log("New query save response: ", response);
      let userTopicsCopy = [...userTopics];
      userTopicsCopy.push(response);
      setUserTopics(userTopicsCopy);
      setNewQuery("");
      handleClose();
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };
  // USE EFFECT
  React.useEffect(() => {
    async function fetchData() {
      setOpenLoading(true);
      //  Fetch user object
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });
      if (userResponse.data) {
        setMainUser(userResponse.data);
        // Fetch all search topics for options
        let allWebSearchTopics = await axios.get(
          `${prodApi}/sourcesV2/all-active-websearch-topics`
        );
        console.log("ALL WEB SEARCH TOPICS: ", allWebSearchTopics.data);
        // Fetch all topics a user follows
        let userTopicsData = await axios.get(
          `${prodApi}/sourcesV2/user-topics/${userobj._id}`
        );
        console.log("USER TOPICS DATA: ", userTopicsData.data);
        // Set the view of users current followed topics
        setUserTopics(userTopicsData.data.userTopics);
        // Filter current user topics from available options
        setAllAvailableWebSearchTopics(
          allWebSearchTopics.data.allWebSearchTopics
            .filter(
              (item) =>
                !userTopicsData.data.userTopics.some(
                  (topic) => topic._id === item._id
                )
            )
            .sort(compare)
        );
        setOpenLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <PageHead
        title="My Topics"
        description="Customize your news experience by selecting topics you're interested in. Stay informed with articles, updates, and debriefs tailored to your preferences."
      />

      <>
        {openLoading ? (
          <>
            <BackdropLoader isLoading={openLoading} />
          </>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              // style={{ width: "100vw" }}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={900}
                  fontSize={40}
                >
                  My Topics
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Typography component="subtitle">
                  Create, edit, or remove the topics you'd want to track over
                  time.{" "}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "15px",
                    verticalAlign: "middle",
                    marginRight: "6px",
                    color: "#545c67",
                  }}
                >
                  info
                </span>
                <Typography
                  component="h6"
                  style={{ color: "#545c67", fontSize: "14px" }}
                >
                  If your topic is newly added, please allow some time for data
                  to accumulate and check back later.{" "}
                </Typography>
              </Grid>

              {userTopics.length > 0 ? (
                userTopics.map((i, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <TopicCard
                      data={i}
                      topic={i.searchQuery}
                      id={i._id}
                      numberOfFiles={"n/a"}
                      createdAt={i.createdAt}
                      status={i.isActive}
                      removeTopic={(x) => {
                        setSelectedQuery(x);
                        setOpenRemove(true);
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "80vh",
                  }}
                >
                  <p>
                    You have not added any topics. Please add a topic with the +
                    button below to get started.
                  </p>
                </div>
              )}

              <Grid item xs={12}>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle
                    style={{
                      paddingBottom: 10,
                      fontSize: "24px",
                      fontWeight: "900",
                      color: "#000000",
                    }}
                  >
                    Select Topic
                  </DialogTitle>

                  <DialogContent>
                    <p>
                      Select an existing topic or request a new one. We'll begin
                      tracking data across the web for you.
                    </p>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        tm: "5",
                        tb: "5",
                      }}
                    >
                      <span
                        className="material-symbols-outlined"
                        style={{
                          fontSize: "20px",
                          verticalAlign: "middle",
                          marginRight: "6px",
                          color: "#C6017E",
                        }}
                      >
                        info
                      </span>
                      <Typography
                        component="h6"
                        style={{ color: "#C6017E", fontSize: "15px" }}
                      >
                        Please allow up to 24 hours for us to process and review
                        your topic suggestions due to the high volume of
                        requests we receive.{" "}
                      </Typography>
                    </Grid>

                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={actionType}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Search Topics" {...a11yProps(0)} />

                          <Tab label="Create New Topic" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </Box>
                    <CustomTabPanel value={actionType} index={0}>
                      <div style={{ backgroundColor: "", padding: 10 }}>
                        <p>
                          Explore existing topics that may fit your interests.
                        </p>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={allAvailableWebSearchTopics}
                          getOptionLabel={(option) => option.searchQuery}
                          value={selectedOptions}
                          onChange={handleAutocompleteChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} label="Choose a topic" />
                          )}
                        />
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={actionType} index={1}>
                      <p>
                        Cant find what you are looking for? Create a new one.
                      </p>
                      <TextField
                        name="newTopic"
                        required
                        fullWidth
                        id="topic"
                        label="Create New Topic"
                        autoFocus
                        variant="outlined"
                        color="secondary"
                        value={newQuery}
                        onChange={(event) => {
                          // let temp = { ...mainUser };
                          // temp.firstName = event.target.value;
                          // setMainUser(temp);
                          setNewQuery(event.target.value);
                        }}
                      />
                    </CustomTabPanel>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      style={{ color: "#000000" }}
                      onClick={async () => {
                        console.log("Clicked save");
                        if (actionType === 0) {
                          await handleJoinTopics();
                        }
                        if (actionType === 1) {
                          await handleCreateNewTopic();
                        }
                      }}
                    >
                      {actionType === 0 ? "Create" : "Request"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <Fab
                  color="primary"
                  aria-label="add"
                  style={{
                    position: "fixed",
                    bottom: "16px", // Adjust as needed
                    right: "16px", // Adjust as needed
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <AddIcon />
                </Fab>
              </Grid>
              <Grid item xs={12}>
                {openRemove ? (
                  <Dialog
                    open={openRemove}
                    onClose={() => {
                      console.log("Close");
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        You are about to remove the search query
                        <b>{` ${selectedQuery.searchQuery}`}</b>.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setOpenRemove(false);
                        }}
                        style={{ color: "black" }}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="text"
                        style={{ color: "red" }}
                        disabled={disableDelete}
                        onClick={async () => {
                          await handleDelete();
                        }}
                        autoFocus
                      >
                        Proceed
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}
              </Grid>
            </Grid>
          </>
        )}
      </>
    </div>
  );
}

export default MyTopicsContainer;
