import Button from "@mui/material/Button";

const ActionButton = (p) => {
  return (
    <Button
      disabled={p.disabled}
      type="submit"
      fullWidth={p.fullWidth}
      variant="contained"
      sx={{
        borderRadius: "15px",
        background: p.disabled
          ? "#E0E0E0" // Grayed out background when disabled
          : "linear-gradient(90deg, #ff385c, #c6017e)", // Gradient background
        color: p.disabled ? "#9e9e9e" : "#ffffff", // Text color when disabled
        "&:hover": {
          background: p.disabled
            ? "#E0E0E0" // Keep the same background when disabled
            : "linear-gradient(90deg, #ff5075, #d6018e)", // Brighter gradient for hover effect
          boxShadow: p.disabled
            ? "none"
            : "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)", // Box shadow for hover effect if not disabled
        },
        // You might want to add additional disabled styles here
        "&.Mui-disabled": {
          border: "1px solid #E0E0E0", // Optional: Border for disabled state
          color: "#9e9e9e", // Re-apply text color for specificity
          background: "#E0E0E0", // Re-apply background for specificity
        },
      }}
      onClick={() => {
        if (p.onClick && !p.disabled) {
          p.onClick();
        }
      }}
    >
      {p.label}
    </Button>
  );
};

export default ActionButton;
