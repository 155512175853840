export const formatDate = (input) => {
  const date = new Date(input);
  // Format with Intl.DateTimeFormat
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

export const formatDateNumeric = (input) => {
  const date = new Date(input);
  // Format with Intl.DateTimeFormat
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};
