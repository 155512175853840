import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import RingLoader from "react-spinners/RingLoader";
import { TopicSelectionAddition } from "../../common/components/DialogViews/TopicSelectionAddition";
import useCommonHook from "../../common/hooks/useCommonHook";
import { useAuth } from "../../common/hooks/useAuth";
import { CustomAccordion } from "../../common/components/Accordion/CustomAccordion";
import { styled } from "@mui/system";


const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function OnBoardingModalv2(p) {
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const { setUser } = useAuth();
  const { setSnackBar } = useCommonHook();
  const [previewData, setPreviewData] = useState(null);
  const [step, setStep] = useState(0);
  // -------
  const [actionType, setActionType] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newQuery, setNewQuery] = useState("");
  // -------
  const [name, setName] = useState("");
  const [areaOfFocus, setAreaOfFocus] = useState("");
  const [selectedWorkspaceTopic, setSelectedWorkspaceTopic] = useState({});
    // Added state variables for work, job title, and what led the user here
  const [work, setWork] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [whatLedUserHere, setWhatLedUserHere] = useState("");
  // -------
  const [onBoarderdStarted, setOnBoardedStarted] = useState(false);
  const [stateMessage, setStateMessage] = useState(
    "Adding topics to your account"
  );
  const [role, setRole] = useState("");
  const [codingExperience, setCodingExperience] = useState("");


  const handleJoinTopics = async () => {
    console.log("Handle join topics");
    try {
      // add user to topic
      let joined = [];
      if (selectedOptions.length > 0) {
        for (let optionToJoin of selectedOptions) {
          console.log("Option to join", optionToJoin);
          let response = await axios.post(`${prodApi}/sourcesV2/join-topic`, {
            queryId: optionToJoin._id,
            userId: userobj._id,
          });
          console.log("response data: ", response.data);
          joined.push(response.data.query);
        }
      }
      console.log("Joined: ", joined);
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };
  const handleCreateNewTopic = async () => {
    try {
      console.log("Topic to create: ", newQuery);
      // create new topic
      let response = await axios.post(`${prodApi}/sourcesV2/create-topic`, {
        query: newQuery,
        userId: userobj._id,
      });
      response = response.data;

      console.log("New query save response: ", response);
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };

  const markUserOnboarded = async () => {
    try {
      console.log("Mark User Onboarded: ", newQuery);
      let response = await axios.post(`${prodApi}/user/onboard-user`, {
        userId: userobj._id,
      });
      console.log("Mark user response: ", response);
      if (response.status === 200) {
        let userCopy = { ...userobj };
        userCopy.onBoarded = true;
        console.log("User updated copy: ", userCopy);
        localStorage.setItem("user", JSON.stringify(userCopy));
        setUser(userCopy);
      }
    } catch (e) {
      console.error("Error marking user as onboarded:", e);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onBoardUser = async () => {
    setOnBoardedStarted(true);

    // Create/Add topics
    if (selectedOptions.length > 0) {
      setStateMessage("Adding topics to your account");
      await handleJoinTopics();
      await delay(2000); // Wait for 1 second
    }
    if (newQuery !== "") {
      setStateMessage("Creating new topics");
      await handleCreateNewTopic();
      await delay(2000); // Wait for 1 second
    }

    // Update user on-boarded status to true
    await markUserOnboarded();
    // Update UI state
    setSnackBar(true, "Welcome to Debriefs!");
    setOnBoardedStarted(false);
    p.setOpenOnboardModal(true);
    window.location.reload(true);
  };

  
  return (
    <Dialog open={p.open} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          paddingBottom: 10,
          fontSize: "24px",
          color: "#000000",
          fontWeight: "900",
          textAlign: "center",
        }}
      >
        Welcome To Debriefs!
      </DialogTitle>
      <DialogContent>
        {/* Added a progress bar */}
        <LinearProgress variant="determinate" value={(step / 2) * 100} />

        {/* Added a new step for work, job title, and what led the user here */}
        {step === 0 && (
          <div style={{ padding: "24px" }}>
            <Typography variant="h6" gutterBottom>
              Tell us a little about yourself
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Work"
                  fullWidth
                  value={work}
                  onChange={(e) => setWork(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Job Title"
                  fullWidth
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="What led you here?"
                  fullWidth
                  multiline
                  rows={4}
                  value={whatLedUserHere}
                  onChange={(e) => setWhatLedUserHere(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {step === 1 && (
          <div style={{ padding: "24px" }}>
            <Typography variant="h6" gutterBottom>
              Step 1: Select Topics
            </Typography>
            <CustomAccordion
              section={"Stuck?"}
              children={
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Think about the following when choosing your topics
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Added cards for topic suggestions */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Your Investments
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Stay updated on market trends, stocks, or investment
                            opportunities that matter to you.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Your Work
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Keep a pulse on industry news, breakthroughs, and
                            developments relevant to your work.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Your Personal Curiosity
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Whether it's politics, sports, or research papers,
                            follow what fuels your hobbies and leisure time.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <TopicSelectionAddition
              parenSelectOptions={setSelectedOptions}
              parentSetNewQuery={setNewQuery}
              newQuery={newQuery}
              selectedOptions={selectedOptions}
              actionType={actionType}
              setActionType={setActionType}
            />
          </div>
        )}

        {step === 2 && (
          <div style={{ padding: "24px" }}>
            <Typography variant="h6" gutterBottom>
              Step 2: Create Debrief
            </Typography>
            <Typography variant="body1" paragraph>
              Select up to three items in the next page and create your Debrief!
            </Typography>
            {onBoarderdStarted ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px",
                }}
              >
                <RingLoader
                  loading={true}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <Typography variant="body1" style={{ marginTop: "16px" }}>
                  {stateMessage}
                </Typography>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          disabled={step === 0 || onBoarderdStarted}
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button
          disabled={onBoarderdStarted}
          onClick={async () => {
            console.log("Clicked save");

            if (step === 0) {
              if (work !== "" && jobTitle !== "" && whatLedUserHere !== "") {
                setStep(1);
              } else {
                alert("Please fill in all the fields.");
              }
            }
            if (step === 1) {
              if (selectedOptions.length > 2) {
                setStep(2);
              } else {
                alert("Please select at least 3 topics.");
              }
            }
            if (step === 2) {
              onBoardUser();
            }
          }}
        >
          {step === 2 ? "Let's Read!" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default OnBoardingModalv2;
