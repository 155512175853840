import * as React from "react";
import { WifiCalling3Sharp } from "@mui/icons-material";
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  List,
  ListItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import useCommonHook from "../../../common/hooks/useCommonHook";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import axios from "axios";
import SectionPreviewSmall from "../../../common/components/SectionPreview/SectionPreviewSmall";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import QueryStatsSharpIcon from "@mui/icons-material/QueryStatsSharp";
import TroubleshootSharpIcon from "@mui/icons-material/TroubleshootSharp";
import ChevronRightIcon from "@mui/icons-material/ChevronRightSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InsightsIcon from "@mui/icons-material/Insights";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
// const pythonApi = localPython;

const pythonApi = prodApiPython;

function CreateDebriefDrawer({
  isOpen,
  openMLOptions,
  setOpenMLOptions,
  setIsOpen,
  isDebriefCreating,
  selectedMenuItem,
  setSelectedMenuItem,
  selectedSources,
  setSelectedSources,
  setIsDebriefCreating,
  userobj,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  

  const { setSnackBar } = useCommonHook();
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        console.log("Close Drawer");

        setIsOpen(false);
      }}
    >
      <Box
        sx={{ width: 400, padding: 2, position: "relative" }}
        role="presentation"
      >
        <IconButton
          onClick={() => {
            setIsOpen(false);
          }}
          sx={{
            top: "8px",
            left: "2px",
            mb: 2,
            zIndex: 1,
            color: "black",
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography variant="h2" sx={{ ml: 2, mt: 1 }}>
          Begin Your Debrief
        </Typography>

        <Typography variant="h5" sx={{ ml: 2, mt: 2, mb: 2 }}>
          Choose Debrief Type
        </Typography>

        <Button
          onClick={(e) => {
            console.log("Open ML Options");
            setAnchorEl(e.currentTarget);
            setOpenMLOptions(true);
          }}
          sx={{
            display: "flex",
            alignItems: "left",
            justifyContent: "center",
            padding: "10px 10px",
            backgroundColor: "primary",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            maxWidth: "70%",
            width: "100%",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary",
            },
            mx: "auto",
            mb: 4,
            mt: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", ml: 2, mr: 2 }}>
            <AutoAwesomeSharpIcon
              sx={{ color: "#b39ddb", fontSize: 20, marginRight: 1 }}
            />
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Analysis Type
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#757575",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {selectedMenuItem}
              </Typography>
            </Box>
          </Box>
          <ArrowDropDownIcon sx={{ color: "#757575" }} />
        </Button>

        <Menu
          open={openMLOptions}
          anchorEl={anchorEl}
          onClose={() => {
            setOpenMLOptions(false);
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={() => {
              setSelectedMenuItem("General Overview");
              setOpenMLOptions(false);
            }}
          >
            <ListItemIcon>
              <AutoAwesomeSharpIcon
                fontSize="small"
                sx={{ color: "primary" }}
              />
            </ListItemIcon>
            General Overview
          </MenuItem>
          <MenuItem
            
            onClick={() => {
              setSelectedMenuItem("Blindspot Analysis");
              setOpenMLOptions(false);
            }}
          >
            <ListItemIcon>
              <VisibilityOffSharpIcon
                fontSize="small"
                sx={{ color: "primary" }}
              />
            </ListItemIcon>
            Blindspot Analysis
<Box
  sx={{
    display: "inline-block",
    backgroundColor: "rgba(255, 165, 0, 0.1)", // Light orange background
    borderRadius: "4px",
    padding: "2px 6px",
    marginLeft: "auto",
  }}
>
  <Typography
    variant="caption"
    sx={{
      color: "#FFA500", // Orange text color
      fontWeight: "bold",
    }}
  >
    Trial
  </Typography>
</Box>
          </MenuItem>

          <MenuItem
            disabled
            onClick={() => {
              setSelectedMenuItem("Deep Dive Analysis");
              setOpenMLOptions(false);
            }}
          >
            <ListItemIcon>
              <QueryStatsSharpIcon fontSize="small" sx={{ color: "primary" }} />
            </ListItemIcon>
            Contextual Intelligence
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: "rgba(66, 133, 244, 0.1)",
                borderRadius: "4px",
                padding: "2px 6px",
                marginLeft: "auto",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#4285F4",
                  fontWeight: "bold",
                }}
              >
                Pro
              </Typography>
            </Box>
          </MenuItem>

          <MenuItem
            disabled
            onClick={() => {
              setSelectedMenuItem("Deep Dive Analysis");
              setOpenMLOptions(false);
            }}
          >
            <ListItemIcon>
              <TroubleshootSharpIcon
                fontSize="small"
                sx={{ color: "primary" }}
              />
            </ListItemIcon>
            Anomaly Detection
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: "rgba(66, 133, 244, 0.1)",
                borderRadius: "4px",
                padding: "2px 6px",
                marginLeft: "auto",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#4285F4",
                  fontWeight: "bold",
                }}
              >
                Pro
              </Typography>
            </Box>
          </MenuItem>

          <MenuItem
            disabled
            onClick={() => {
              setSelectedMenuItem("Deep Dive Analysis");
              setOpenMLOptions(false);
            }}
          >
            <ListItemIcon>
              <WifiCalling3Sharp fontSize="small" sx={{ color: "primary" }} />
            </ListItemIcon>
            Earnings Call Brief
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: "rgba(66, 133, 244, 0.1)",
                borderRadius: "4px",
                padding: "2px 6px",
                marginLeft: "auto",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#4285F4",
                  fontWeight: "bold",
                }}
              >
                Pro
              </Typography>
            </Box>
          </MenuItem>
        </Menu>

        {selectedSources.length > 0 && (
          <Typography variant="h5" sx={{ ml: 2, mt: 1 }}>
            Selected Sources
          </Typography>
        )}
        <List>
          {selectedSources.length > 0 ? (
            selectedSources.map((item, index) => (
              <ListItem key={index} sx={{ display: "block" }}>
                <SectionPreviewSmall
                  source={item}
                  handleClickSource={() => {
                    // Remove the clicked source from the selected sources
                    setSelectedSources(
                      selectedSources.filter(
                        (source) => source._id !== item._id
                      )
                    );
                  }}
                />
              </ListItem>
            ))
          ) : (
            <Box
              sx={{
                backgroundColor: "#f9fafb",
                borderRadius: "10px",
                padding: "20px",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  backgroundColor: "rgba(66, 133, 244, 0.1)", // Light blue background
                  borderRadius: "10px", // Rounded corners
                  padding: "5px 10px", // Padding
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4285F4", // Blue text color
                    fontWeight: "bold", // Bold text
                  }}
                >
                  New
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ mt: 2, mb: 4 }}>
                Tips for getting started
              </Typography>

              <Divider></Divider>

              <Typography variant="body2" sx={{ mt: 4, mb: 4 }}>
                <ContentCopyIcon
                  sx={{ mr: 1, fontSize: 18, color: "#01bb7d" }}
                />
                <strong>Choose Similar Content</strong>
                <br />
                Select content that covers related topics. This helps our AI
                generate more cohesive and insightful debriefs.
              </Typography>

              <Typography variant="body2" sx={{ mt: 4, mb: 4 }}>
                <InsightsIcon sx={{ mr: 1, fontSize: 18, color: "#01bb7d" }} />
                <strong>Understand Your Content Better</strong>
                <br />
                Our AI analyzes all the information to provide a clearer and
                more understandable summary.
              </Typography>

              <Typography variant="body2" sx={{ mt: 4, mb: 4 }}>
                <ThumbUpOffAltIcon
                  sx={{ mr: 1, fontSize: 18, color: "#01bb7d" }}
                />
                <strong>Verify Information</strong>
                <br />
                While we strive for accuracy, always double-check the
                information presented in the debriefs.
              </Typography>
              <Divider></Divider>
              <Typography variant="body2" sx={{ mt: 4, mb: 4 }}>
                <b>Get started by adding content from your feed.</b>
              </Typography>
            </Box>
          )}
        </List>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={selectedSources.length === 0}
          sx={{ mt: 2 }}
          onClick={async () => {
            if (selectedSources.length > 0 && selectedSources.length <= 10) {
              // setOpenLoading(true);
              console.log(
                "Selected Sources meet length requirement: ",
                userobj,
                selectedSources
              );
              setIsDebriefCreating(true);
              try {
                const response = await axios.post(
                  `${pythonApi}/debrief-creation/create-with-selected-sources`,
                  {
                    selected_source_ids: selectedSources.map((i) => i._id),
                    topics: selectedSources
                      .filter((i) => i.category !== "no value")
                      .map((i) => i.category)
                      .join(" | "),
                    user_id: userobj._id,
                    user_name: userobj.firstName,
                    user_email: userobj.email,
                    analysis_type: selectedMenuItem, // Add this line to pass the selected menu item

                  }
                );
                console.log("Debrief creation response: ", response);
                setIsDebriefCreating(false);

                // setOpenLoading(false);
                setSnackBar(
                  true,
                  "Your Debrief is ready! Please go to the Debriefs tab."
                );
                const baseUrl = window.location.origin; // This gets the base URL of your app
                const sharedUrl = `${baseUrl}/shared/${response.data.debrief_id}`;

                window.open(sharedUrl, "_blank", "noopener,noreferrer");
                // Navigate to a new URL in the current window
                window.location.href = `${baseUrl}/dashboard/debriefs`;
              } catch (e) {
                setIsDebriefCreating(false);

                // setOpenLoading(false);
                setSnackBar(
                  true,
                  "Sorry something went wrong creating your Debrief. Please try again later."
                );
              }
            } else {
              alert("Please select up to 3 sources to create a Debrief");
            }
          }}
        >
          Create Debrief
        </Button>

        {isDebriefCreating && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backdropFilter: "blur(4px)",
            }}
          >
            <Button
              sx={{ position: "absolute", top: 10, left: 10 }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
            <Typography
              variant="h4"
              sx={{ mb: 4, color: "#333", fontWeight: 600 }}
            >
              Generating Debrief...
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 4, color: "#333", fontWeight: 400 }}
            >
              Hang tight! We're brewing up your debrief.
            </Typography>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                value={80}
                size={64}
                thickness={4}
                sx={{ color: "#545c67" }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={48}
                  thickness={4}
                  sx={{ color: "#000", animationDuration: "550ms" }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
export default CreateDebriefDrawer;
