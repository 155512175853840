import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ActionButton from "../Buttons/ActionButton";
import { useTheme } from "@mui/material/styles"; // Import useTheme

function SectionCard(p) {
  const theme = useTheme(); // Use the useTheme hook to access the theme

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: theme.palette.background.paper, // Use the default background color from the theme
        borderRadius: 10,
      }}
      key={p.index}
    >
      <Typography component="h1" variant="h5">
        Section {p.index + 1}
      </Typography>
      <Box noValidate sx={{ mt: 1 }}>
        <TextField
          disabled={p.disabled}
          multiline
          margin="normal"
          required
          fullWidth
          value={p.data.question ? p.data.question : ""}
          id="email"
          label="Section Question"
          name="Section"
          onChange={(x) =>
            p.handleUpdate(p.data._id, x.target.value, "question")
          }
        />
        <TextField
          disabled={p.disabled}
          margin="normal"
          required
          fullWidth
          value={p.data.keywords ? p.data.keywords : ""}
          name="Topic Search Words"
          label="Key Words"
          type="key-words"
          onChange={(x) =>
            p.handleUpdate(p.data._id, x.target.value, "keywords")
          }
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button disabled={p.disabled} onClick={() => p.delete()}>
            Delete
          </Button>
          {p.index === p.sections.length - 1 ? (
            <ActionButton
              disabled={p.disabled}
              label={"Add Section"}
              onClick={() => p.add()}
            />
          ) : (
            <></>
          )}
        </div>
      </Box>
    </Box>
  );
}
export default SectionCard;
