import Button from "@mui/material/Button";

const SecondaryActionButton = (props) => {
  return (
    <Button
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      variant="outlined" // Use "outlined" variant for a secondary button
      sx={{
        borderRadius: "15px",
        borderColor: "#c6017e", // Border color to complement the primary button
        color: "#c6017e", // Text color to match the border color
        "&:hover": {
          borderColor: "#d6018e", // Brighter border color on hover
          color: "#d6018e", // Brighter text color on hover
        },
      }}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
};

export default SecondaryActionButton;
