import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

// MUI
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";

import dayjs from "dayjs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import axios from "axios";

// COMPONENTS
import ActionButton from "../../../common/components/Buttons/ActionButton";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
import SectionPreview from "../../../common/components/SectionPreview/SectionPreview";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
// DATE RANGE

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// URLS
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

export const SemanticSearch = () => {
  const gridRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    { field: "category" },
    { field: "link" },
    { field: "senderName" },
    {
      field: "title",
    },
    { field: "text" },
    { field: "createdAt" },
    { field: "appearsIn" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  return (
    <div>
      <PageHead
        title={"Semantic Search"}
        description={
          "Leverage our Semantic Search to discover articles and newsletters that go beyond keywords to bring you content rich in context and relevance. Perfect for in-depth research on nuanced topics."
        }
      />

      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Semantic Search News Data</h1>
          </Grid>
          <Grid item xs={12} style={{ mt: "3", mb: "10", display: "flex" }}>
            <Typography
              component="h2"
              sx={{ color: "#545c67" }} // You can also use a specific hex color like '#333' for darker grey
            >
              Search for and find various articles, newsletters, and content
              based on their context. Enter keywords to discover content with
              semantic similarities. For example, to find articles related to
              NVIDIA, you could type in 'NVIDIA, AI Chips, OpenAI'.{" "}
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TextField
              fullWidth
              id="search-basic"
              label="Search"
              variant="outlined"
              placeholder="Type any search terms or keywords"
              value={searchValue}
              onChange={(x) => {
                setSearchValue(x.target.value);
              }}
            />
            <div style={{ margin: 10 }}>
              <ActionButton
                label={"Search"}
                onClick={async () => {
                  setLoading(true);
                  const answer = await axios.post(
                    `${pythonApi}/semantic-search/query`,
                    {
                      search: searchValue,
                      startDate: dayjs(startDate).format(
                        "YYYY-MM-DD HH:mm:ss.SSS"
                      ),
                      endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  console.log("Answer:", answer.data);
                  setRowData(answer.data);
                  setLoading(false);

                  // setTextOutput(answer.data);
                }}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12}> */}
          {rowData.length > 0 ? (
            <>
              {rowData.map((i, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SectionPreview source={i} />

                  {/* <div
                      style={{
                        margin: 5,
                        padding: 5,
                        border: "1px solid black",
                      }}
                    >
                      <p>Title: {i.title}</p>
                      <p>Category: {i.category}</p>
                      <p>Date: {i.createdAt}</p>
                      <p>Link: {i.link}</p>
                      <p>Sender Name: {i.senderName}</p>
                      <p>{i.text}</p>
                    </div> */}
                </Grid>
              ))}
            </>
          ) : (
            <></>
          )}
          {/* </Grid> */}
          <Grid item xs={12}>
            <BackdropLoader isLoading={isLoading} />
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default SemanticSearch;
