import * as React from "react";
import { useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import axios from "axios";
import logo from "../../../common/assets/Debriefs ICON_BLACK.svg";

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {

  Container,

} from "@mui/material";

import {
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Fab,
  Dialog,
  DialogContent,
  Badge,
  DialogTitle,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import {
  getDomainName,
  getEntitiesForHomePage,
} from "../../../common/Utilities/SourceUtilities";

import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import dayjs from "dayjs";
import HomeTopicsView from "./HomeTopicsView";
import HomeNewsLettersView from "./HomeNewsLettersView";

import HomeSearchBar from "./HomeSearchBar";
import useCommonHook from "../../../common/hooks/useCommonHook";
import SourceFilterSelection from "./SourceFilterSelection";

import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";

import SearchOptions from "./SearchOptions";

import ChatWithDebrief from "../../../common/components/Chat/ChatDebrief/ChatWithDebrief";

import "./HomeContainer.css";
import CreateDebriefDrawer from "./CreateDebriefDrawer";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";

import FeedSelector from "./FeedSelector";
import ContentAggregatorView from "./ContentAggregatorView";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
// const pythonApi = localPython;

const pythonApi = prodApiPython;

function HomeContainer() {
  const saved = localStorage.getItem("user");
  const { setSnackBar } = useCommonHook();
  const userobj = JSON.parse(saved);
  const [loadingHomeView, setLoadingHomeView] = React.useState(false);
  const [entities, setEntities] = useState([]);

  //  Selected sources for Debrief creation
  const [selectedSources, setSelectedSources] = React.useState([]);
  const [newsLetterFeed, setNewsLetterFeed] = React.useState({});
  const [topicsFeed, setTopicsFeed] = React.useState({});
  const [topicsFeedFilter, setTopicsFeedFilter] = React.useState({});
  const [filterOptions, setFilterOptions] = React.useState({});
  const [isDebriefCreating, setIsDebriefCreating] = React.useState(false);

  // Inside your component
  const [openModal, setOpenModal] = React.useState(false);
  const [openMLOptions, setOpenMLOptions] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "Choose"
  );

  const [searchTerm, setSearchTerm] = React.useState("");
  const [homeFeedView, setHomeFeedView] = React.useState("news-articles");
  const [animateItemIds, setAnimateItemIds] = useState(new Set());

  const [isOpen, setIsOpen] = useState(false);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const filterOpen = Boolean(filterAnchorEl);
  const filterPopoverId = filterOpen ? "filter-popover" : undefined;
  const [showSourceFilter, setShowSourceFilter] = React.useState(false);

  const handleClickSource = (source) => {
    // Trigger animation
    setAnimateItemIds(new Set([...animateItemIds, source._id]));

    // Schedule the animation to clear after it completes
    setTimeout(() => {
      setAnimateItemIds((current) => {
        const newSet = new Set(current);
        newSet.delete(source._id);
        return newSet;
      });
    }, 500); // Matches the CSS animation duration

    // Update selected sources based on existence check
    setSelectedSources((prevSelectedSources) => {
      const index = prevSelectedSources.findIndex(
        (item) => item._id === source._id
      );

      if (index !== -1) {
        // If source exists, remove it
        const updatedSources = [...prevSelectedSources];
        updatedSources.splice(index, 1);
        return updatedSources;
      } else {
        // If source doesn't exist, add it
        return [...prevSelectedSources, source];
      }
    });
  };

  const handleChange = (event, value) => {
    console.log("Handle change clicked: ", value);
    if (value !== null) {
      setHomeFeedView(value);
      // togglePublicPrivateDebriefView(value);
    }
  };

  const createFilterOptions = (data) => {
    console.log("Create filter options: ", data);
    let sources = {};

    // Iterate through dictionary keys in data (data is a dictionary)
    Object.keys(data).forEach((key) => {
      const array = data[key];

      // Iterate through each item in the array value
      array.forEach((item) => {
        if (item.type === "email") {
          sources[item.senderEmail] = { uri: item.image, type: item.type };
        } else {
          const domain = getDomainName(item.link);
          if (domain) {
            sources[domain] = { uri: item.favicon, type: item.type };
          }
        }
      });
    });

    setFilterOptions(sources);
  };

  React.useEffect(() => {
    setLoadingHomeView(true);

    async function fetchNewsLetterData() {
      try {
        const userResponse = await axios.post(`${prodApi}/user/fetch`, {
          id: userobj._id,
        });
        if (userResponse.data) {
          try {
            const jarvisEmbeddings = await axios.get(
              `${pythonApi}/embeddings/fetch-email-embeddings-jarvis`
            );
            if (jarvisEmbeddings.status === 200 && jarvisEmbeddings.data) {
              setNewsLetterFeed(jarvisEmbeddings.data);
              console.log("Jarvis Embeddings:", jarvisEmbeddings);
            } else {
              throw new Error("Failed to fetch Jarvis Embeddings");
            }
          } catch (error) {
            console.error("Error fetching email data:", error);
            setSnackBar(
              true,
              "Something went wrong while fetching email data."
            );
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setSnackBar(true, "Failed to load user data.");
      }
    }

    async function fetchTopicsData() {
      try {
        const userResponse = await axios.post(`${prodApi}/user/fetch`, {
          id: userobj._id,
        });
        if (userResponse.data) {
          try {
            const userTopicsData = await axios.get(
              `${prodApi}/sourcesV2/user-topics/${userobj._id}`
            );
            if (
              userTopicsData.data &&
              userTopicsData.data.userTopics.length > 0
            ) {
              const startDate = new Date();
              const endDate = new Date();
              try {
                const answers = await axios.post(
                  `${pythonApi}/semantic-search/bulk-query`,
                  {
                    topics: userTopicsData.data.userTopics,
                    distance: 0.83,
                    startDate: dayjs(startDate).format(
                      "YYYY-MM-DD HH:mm:ss.SSS"
                    ),
                    endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
                    user: userobj,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log("ANSWERS: ", answers.data);

                setTopicsFeed(answers.data);
                setEntities(getEntitiesForHomePage(answers.data));
                // setTopicsData(answers.data); // Set the topics data in the state variable

                createFilterOptions(answers.data);
                setTopicsFeedFilter(answers.data);
              } catch (error) {
                console.error("Error during bulk query:", error);
              }
            } else {
              setTopicsFeed({});
              setTopicsFeedFilter({});
            }
          } catch (error) {
            console.error("Error fetching user topics:", error);
          }
          await fetchNewsLetterData();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      setLoadingHomeView(false);
    }

    fetchTopicsData();
  }, []);

  return (
    <div>
      <PageHead
        title="Home"
        description="Access comprehensive debriefs on current global topics. Dive into detailed analyses, key insights, and the latest updates across various domains."
      />

      <Container maxWidth="lg" sx={{ py: 4 }}></Container>
      
      <Grid container sx={{ maxWidth: 'lg', mx: 'auto', py: 4 }} >
        <Grid item xs={12}>
          <BackdropLoader isLoading={loadingHomeView} />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" fontWeight={900} fontSize={35}>
            Hi {userobj?.firstName} 👋
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <HomeSearchBar
            handleClickSource={handleClickSource}
            searchTerm={searchTerm}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 4, bgcolor: 'white', borderRadius: 2 }}></Grid>
         <Grid item={12} sx={{mb: 4} }>
          <Typography variant="h4" component="div">
            Trending
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchOptions
            optionEmojis={[
              { option: "Israel and Palestine War", emoji: "🪖" },
              { option: "2024 US Elections", emoji: "🗳️" },
              { option: "Climate Tech", emoji: "🌍" },
              { option: "Artificial Intelligence", emoji: "🤖" },
              { option: "Venture Capital", emoji: "💰" },
              { option: "Sam Altman", emoji: "👨‍💼" },
              { option: "Elon Musk", emoji: "🚀" },
              { option: "Social Impact", emoji: "🤝" },
              { option: "Carbon Capture", emoji: "🌳" },
              { option: "Energy Storage", emoji: "🔋" },
              { option: "Renewables", emoji: "♻️" },
              { option: "Politics", emoji: "🏛️" },
              { option: "Sustainability", emoji: "🌱" },
              { option: "Career Growth", emoji: "📈" },
              { option: "Startup Fundraising", emoji: "💸" },
              { option: "Real Estate Market", emoji: "🏡" },
            ]}
            onSearch={(searchTerm) => setSearchTerm(searchTerm)}
          />
        </Grid> 
        
        <Grid item={12}sx={{ py: 4 }}>
          <Typography variant="h4" component="div">
            Top Mentions
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ py: 4 }}>
          <SearchOptions
          
            optionEmojis={entities.map((option) => {
            console.log(option);
            return { 
              option:`${option.label}`, 
              count: option.value, // Adjusted from option.count to option.value
              emoji: <TrendingUpIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} />
            };
          })}
          onSearch={(searchTerm) => setSearchTerm(searchTerm)}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} sx={{mb:4}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={handleFilterClick}
              aria-describedby={filterPopoverId}
              variant="outlined"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "transparent"
                    : theme.palette.background.paper,
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.divider
                    : theme.palette.grey[300],
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                borderRadius: "8px",
                padding: "8px 16px",
                marginLeft: "16px",
                display: "flex",
                alignItems: "center",
                position: "relative",
                "&:hover": {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.action.hover
                      : theme.palette.grey[20],
                },
              }}
            >
              <Typography
                sx={{
                  marginRight: "8px",
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                }}
              >
                Choose Feed
              </Typography>
              <span className="material-symbols-outlined">tune</span>
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => setShowSourceFilter(!showSourceFilter)}
              variant="outlined"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "transparent"
                    : theme.palette.background.paper,
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.divider
                    : theme.palette.grey[300],
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                borderRadius: "8px",
                padding: "8px 16px",
                marginLeft: "16px",
                display: "flex",
                alignItems: "center",
                position: "relative",
                "&:hover": {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.action.hover
                      : theme.palette.grey[20],
                },
              }}
            >
              <Typography
                sx={{
                  marginRight: "8px",
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                }}
              >
                Select Source(s)
              </Typography>
              <span className="material-symbols-outlined">checklist_rtl</span>
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        {showSourceFilter && (
          <Grid item xs={12}>
            <SourceFilterSelection
              setTopicsFeedFilter={setTopicsFeedFilter}
              filterOptions={filterOptions}
              topicsFeed={topicsFeed}
            />
          </Grid>
        )}
 
        <Grid item xs={12}>
          {homeFeedView === "news-articles" ? (
            <>
              <HomeTopicsView
                topicsFeed={topicsFeedFilter}
                handleClickSource={handleClickSource}
                contentType="articles"
              />
            </>
          ) : (
            <></>
          )}
          {homeFeedView === "news-letters" ? (
            <>
              <HomeNewsLettersView
                newsLetterFeed={newsLetterFeed}
                handleClickSource={handleClickSource}
                contentType="newsletters"
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          {openModal ? (
            <Dialog
              fullScreen
              open={openModal}
              onClose={() => setOpenModal(false)}
              // maxWidth={false}
              fullWidth
              PaperProps={{
                style: {
                  width: "90vw",
                  height: "90%",
                  maxWidth: "none",
                  maxHeight: "none",
                  borderRadius: 12,
                  backdropFilter: "blur(10px)",
                },
              }}
            >
              <IconButton
                onClick={() => setOpenModal(false)}
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "grey",
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Debrief Chat</DialogTitle>
              <DialogContent>
                <ChatWithDebrief />
              </DialogContent>
            </Dialog>
          ) : null}
        </Grid>
      </Grid>
      <Fab
        size="large" // This will make the button smaller
        color="secondary"
        aria-label="scroll-top"
        style={{
          position: "fixed",
          bottom: "200px",
          right: "20px",
          backdropFilter: "blur(10px)", // Glass effect
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background
          color: "secondary", // Text color set to black
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
          <img 
  src={logo} 
  alt="Logo" 
  style={{ 
    width: '50px', 
    height: '50px', 
    borderRadius: '50%', // Makes the image circular
    padding: '1px' // Optional: Adds padding inside the border
  }} 
/>

      </Fab>
      <Fab
        size="small"
        color="secondary"
        aria-label="scroll-top"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          color: "secondary",
        }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <ArrowUpwardIcon />
      </Fab>
      <Tooltip
        sx={{ fontSize: 12 }}
        title="Add or remove content to create your debriefs"
        arrow
      >
        <Fab
          size="large"
          color="secondary"
          aria-label="open-drawer"
          style={{
            position: "fixed",
            bottom: "100px",
            right: "20px",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            color: "secondary",
          }}
          onClick={() => {
            console.log("Fab Clicked: ", !isOpen);

            setIsOpen(true);
          }}
        >
          <Badge badgeContent={selectedSources.length} color="error">
            {isDebriefCreating ? (
              <CircularProgress size={24} />
            ) : (
              <AutoAwesomeSharpIcon />
            )}
          </Badge>
        </Fab>
      </Tooltip>

      <CreateDebriefDrawer
        isOpen={isOpen}
        userobj={userobj}
        openMLOptions={openMLOptions}
        toggleDrawer={setIsOpen}
        isDebriefCreating={isDebriefCreating}
        setIsOpen={setIsOpen}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        selectedSources={selectedSources}
        setSelectedSources={setSelectedSources}
        setIsDebriefCreating={setIsDebriefCreating}
        setOpenMLOptions={setOpenMLOptions}
      />
      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <DialogTitle>
          Feed Type
          <IconButton
            style={{ position: "absolute", right: "8px", top: "8px" }}
            onClick={handleFilterClose}
          >
            <CloseIcon
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
              }}
            />
          </IconButton>
        </DialogTitle>
        <FeedSelector handleChange={handleChange} homeFeedView={homeFeedView} />
      </Dialog>
    </div>
  );
}

export default HomeContainer;
