// firebaseConfig.js
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCULHaYEoytbOFkiK0a98s82l4EqcBYNKQ",
  authDomain: "debriefs-85a70.firebaseapp.com",
    

  projectId: "debriefs-85a70",
  storageBucket: "debriefs-85a70.appspot.com",
  messagingSenderId: "128508840393",
  appId: "1:128508840393:web:7cce3a6b938c90be0c4018",
  measurementId: "G-9J6YZSJKKF",
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

// Initialize Firebase Analytics
export const analytics = getAnalytics(app);

// Initialize Firebase Auth and set up providers
const auth = getAuth(app);
auth.useDeviceLanguage();

const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  // Optional "tenant" parameter to support both consumer and organizational accounts
  tenant: 'common'
});

// Export the Firebase auth instance and providers
export { auth, googleProvider, microsoftProvider };

export default app;
