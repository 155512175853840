import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./LinkPreview.css"; // Make sure this is the correct path to your CSS file

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
} from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function getDomainName(url) {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    const match = hostname.match(/(?:www\.)?(.*?)\./);
    return match ? match[1] : hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

const pythonApi = "http://localhost:8080"; // Replace with your API endpoint as needed

const highlightText = (text, wordsToHighlight) => {
  if (!wordsToHighlight || wordsToHighlight.length === 0) {
    return text;
  }

  const firstThreeSentences =
    text
      .match(/[^\.!\?]+[\.!\?]+/g)
      ?.slice(0, 3)
      .join(" ") || "";
  const regex = new RegExp(
    `\\b(${wordsToHighlight.map((word) => word.word).join("|")})\\b`,
    "gi"
  );

  return firstThreeSentences.split(regex).map((part, index) => {
    const wordObj = wordsToHighlight.find(
      (word) => word.word.toLowerCase() === part.toLowerCase()
    );
    return wordObj ? (
      <span key={index} style={{ backgroundColor: wordObj.color }}>
        {part}
      </span>
    ) : (
      part
    );
  });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};

const SourceDisplay = (props) => {
  const [previewData, setPreviewData] = useState(null);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [selectedNewsLetter, setSelectedNewsLetter] = React.useState({});
  const [showNewsLetter, setShowNewsLetter] = React.useState(false);
  return (
    <Card elevation={0} className="custom-card">
      {props?.source ? (
        <>
          <div className="image-title-link">
            {props?.source[0]?.imageUrl &&
              props?.source[0]?.imageUrl !== "no value" && (
                <img
                  src={props.source[0].imageUrl}
                  alt="Preview"
                  className="preview-image"
                />
              )}
            {props?.source[0]?.image &&
              props?.source[0].image !== "no value" && <EmailIcon />}
            <h2 className="preview-title">
              {props.source[0]?.type !== "email"
                ? props.source[0].title
                : props.source[0].subject}
            </h2>
          </div>
          <div>
            <p className="card-date w-full text-center text-xs text-gray-500 mt-1 font-sans">
              {formatDate(props.source[0].createdAt)}
            </p>
          </div>

          {props.source?.length > 0 ? (
            <>
              {props.source.map((item, index) => (
                <div key={index}>
                  <p
                    style={{
                      marginTop: 15,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderLeft: "3.5px solid #E0E0E0",
                    }}
                  >
                    {item.text.slice(0, 269)}...
                  </p>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}

          <Divider
            className="divider-horizontal"
            flexItem
            style={{ padding: 2, margin: 5 }}
          />
          <div
            className="flex-row"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: 0,
            }}
          >
            <div
              className="flex-item"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ margin: 0, marginRight: "10px" }}>
                <b>Source</b>
              </p>
              {props.source[0].favicon !== "no value" &&
                props.source[0].favicon !== "" && (
                  <div>
                    <Chip
                      avatar={
                        <img
                          src={props.source[0].favicon}
                          alt="Favicon"
                          className="favicon"
                          style={{ borderRadius: 5, padding: 1 }}
                        />
                      }
                      label={getDomainName(props.source[0].link)}
                      onClick={() =>
                        window.open(props.source[0].link, "_blank")
                      }
                    />
                  </div>
                )}
              {props?.source[0]?.senderEmail &&
                props?.source[0]?.senderEmail !== "no value" && (
                  <div>
                    <Chip
                      avatar={
                        <img
                          src={props.source[0].image}
                          alt="Favicon"
                          style={{ borderRadius: 5, padding: 1 }}
                        />
                      }
                      label={props.source[0].senderEmail}
                      className="source-chip"
                    />
                  </div>
                )}
            </div>
            <Divider
              className="divider-vertical"
              orientation="vertical"
              flexItem
            />
            <div
              className="flex-item"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p style={{ margin: 0 }}>
                {props.source[0].link !== "no value"
                  ? "Article"
                  : "News Letter"}
              </p>
            </div>
          </div>
          <Divider
            className="divider-horizontal"
            flexItem
            style={{ padding: 2, margin: 5 }}
          />
        </>
      ) : (
        <p>Loading link preview...</p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly", // Distributes space evenly around items
          alignItems: "center", // Aligns items vertically in the center
          padding: "8px 0", // Adds vertical padding
        }}
      >
        <Button
          variant="outlined"
          onClick={async () => {
            console.log("Clicked card: ", props);
            if (props.source[0].type !== "email") {
              window.open(props.source[0].link, "_blank");
            } else {
              try {
                if (props.source[0].type === "email") {
                  setOpenLoading(true);
                  let newsLetterContent = await axios.post(
                    `https://rsnc-23.onrender.com/email/fetch-email`,
                    {
                      subject: props.source[0].subject,
                      senderEmail: props.source[0].senderEmail,
                    }
                  );
                  console.log("News letter content: ", newsLetterContent);
                  if (newsLetterContent.status === 200) {
                    setShowNewsLetter(true);
                    setSelectedNewsLetter(newsLetterContent.data.htmlContent);
                    setOpenLoading(false);
                  } else {
                    setOpenLoading(false);
                    alert("Something went wrong while retrieving the email.");
                  }
                }
              } catch (e) {
                setOpenLoading(false);
                alert("Something went wrong while retrieving the email.");
              }
            }
          }}
        >
          View
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  props.handleClickSource(props.source[0]);
                }}
              />
            }
            label="Select"
          />
        </FormGroup>
      </div>
      <Dialog
        open={showNewsLetter}
        onClose={() => {
          setShowNewsLetter(false);
        }}
        fullWidth
      >
        <p
          dangerouslySetInnerHTML={{
            __html: selectedNewsLetter,
          }}
        />
      </Dialog>
    </Card>
  );
};

export default SourceDisplay;
