import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";
import {
  Chip,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseFullscreenSharpIcon from "@mui/icons-material/CloseFullscreenSharp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { EmailRounded } from "@mui/icons-material";

const SourcesCarousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({ playOnInit: true, speed: 0.45 }),
  ]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onButtonAutoplayClick = useCallback(
    (callback) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll;
      if (!autoScroll) return;
      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop;
      resetOrStop();
      callback();
    },
    [emblaApi]
  );

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;
    const playOrStop = autoScroll.isPlaying() ? autoScroll.stop : autoScroll.play;
    playOrStop();
  }, [emblaApi]);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;
    setIsPlaying(autoScroll.isPlaying());
    emblaApi
      .on("autoScroll:play", () => setIsPlaying(true))
      .on("autoScroll:stop", () => setIsPlaying(false))
      .on("reInit", () => setIsPlaying(false));
  }, [emblaApi]);

  const handleSlideClick = (uri, index) => {
    if (uri.includes("http")) {
      setExpandedImage(uri);
      setExpandedImageIndex(index);
    }
  };

  const closeExpandedImage = () => {
    setExpandedImage(null);
    setExpandedImageIndex(null);
  };

  const handlePrevImage = () => {
    const prevIndex = (expandedImageIndex - 1 + slides.length) % slides.length;
    setExpandedImage(slides[prevIndex]);
    setExpandedImageIndex(prevIndex);
  };

  const handleNextImage = () => {
    const nextIndex = (expandedImageIndex + 1) % slides.length;
    setExpandedImage(slides[nextIndex]);
    setExpandedImageIndex(nextIndex);
  };

  const handleThumbnailClick = (index) => {
    setExpandedImage(slides[index]);
    setExpandedImageIndex(index);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevImage();
    } else if (event.key === "ArrowRight") {
      handleNextImage();
    } else if (event.key === "Escape") {
      closeExpandedImage();
    }
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        padding: "16px",
        backgroundColor: "#fafafa",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          width: "100%",
        }}
        ref={emblaRef}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {slides.map((uri, index) => (
            <div
              key={index}
              onClick={() => handleSlideClick(uri, index)}
              style={{
                flex: "0 0 auto",
                width: "120px",
                height: "120px",
                cursor: "pointer",
                transition: "transform 0.3s ease",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                {uri.includes("http") ? (
                  <img
                    src={uri}
                    alt="source url"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                  />
                ) : (
           <Chip
              icon={<EmailRounded style={{ color: "primary" }} />}
              label="Newsletter"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                borderRadius: "20px",
                padding: "8px 16px",
              }}
            />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        open={expandedImage !== null}
        onClose={closeExpandedImage}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#primary",
            borderRadius: "16px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            padding: "24px",
            position: "relative",
            overflow: "hidden",
          },
        }}
        onKeyDown={handleKeyDown}
      >
        <div
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 1,
          }}
        >
          <IconButton onClick={closeExpandedImage}>
            <CloseFullscreenSharpIcon style={{ color: "primary" }} />
          </IconButton>
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "16px",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <IconButton onClick={handlePrevImage} disabled={expandedImageIndex === 0}>
            <ArrowBackIosIcon style={{ color: "primary" }} />
          </IconButton>
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "16px",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleNextImage} disabled={expandedImageIndex === slides.length - 1}>
            <ArrowForwardIosIcon style={{ color: "primary" }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={expandedImage}
            alt="Expanded"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              borderRadius: "12px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          {slides.map((uri, index) => (
            <div
              key={index}
              onClick={() => handleThumbnailClick(index)}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                overflow: "hidden",
                margin: "0 4px",
                cursor: "pointer",
                opacity: index === expandedImageIndex ? 1 : 0.6,
                transition: "opacity 0.3s ease",
              }}
            >
              <img
                src={uri}
                alt={`Thumbnail ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default SourcesCarousel;