import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import darkThemeLogo from "../../assets/DebriefsWhite.png";
import lightThemeLogo from "../../assets/Debriefs.svg";

import LightModeIcon from "@mui/icons-material/LightMode"; // Icon for light mode
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { ThemeModeContext } from "../../../App";
import { useTheme } from "@mui/material/styles";

export default function CustomAppBar(p) {
  const mode = React.useContext(ThemeModeContext);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          console.log("Log out clicked");
          p.logout();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const drawerWidth = 200;

  // console.log("THEME VALUE BACKGROUND: ", theme.palette.background);
  const renderMobileMenu = (
    <Menu
      sx={{ backgroundColor: theme.palette.action.like }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-haspopup="true"
          color="primary"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar
        position="fixed"
        elevation={0}
     sx={{
    backgroundColor: 'transparent', // transparent background
    backdropFilter: 'blur(5px)', // blur effect
    boxShadow: '0px 2px 3px 1px rgba(0, 0, 0, 0.01)', // slight shadow with reduced opacity
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    ml: { sm: `${drawerWidth}px` },
  }}
        component="nav"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="open drawer"
            onClick={() => {
              console.log("menu toggle");
              p.handleDrawerToggle();
            }}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <div>
            <a
              href="https://app.debriefs.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={mode.mode === "dark" ? darkThemeLogo : lightThemeLogo}
                alt="Logo"
                style={{ height: "50px", marginBottom: "10px" }}
              />
            </a>
          </div> */}

          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton
              onClick={() => {
                mode.toggleMode();
              }}
              color="primary"
            >
              {mode.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Box>
          {/*
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="secondary"
            >
              <AccountCircle color="primary" />
            </IconButton>
          </Box>

          
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              sx={{ backgroundColor: theme.palette.action.like }}
            >
              <MoreIcon color="primary" />
            </IconButton>
          </Box>
          */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
