import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  Grid,
} from "@mui/material";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import InputAdornment from "@mui/material/InputAdornment";

import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";

import axios from "axios";
import RingLoader from "react-spinners/RingLoader";
import dayjs from "dayjs";
import SourceDisplay from "../../../common/components/SourceDisplay/SourceDisplay";
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const HomeSearchBar = ({ handleClickSource, searchTerm }) => {
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const [query, setQuery] = useState(searchTerm || "");
  const [filteredResults, setFilteredResults] = useState([]);

  const [loading, setLoading] = useState(false);
  //   const [data, setData] = useState < any > [];
  const [keysInDescedingOrder, setKeys] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = (event) => {
    const input = event.target.value;
    setQuery(input);
  };
  useEffect(() => {
    setQuery(searchTerm || "");
  }, [searchTerm]);

  function groupBy(groupByKey, array) {
    return array.reduce((acc, obj) => {
      // Use the groupByKey as the key in the accumulator object
      let key = obj[groupByKey];
      if (groupByKey === "title" && key === "") {
        key = obj["subject"];
      }
      if (groupByKey === "link" && key === "") {
        key = obj["senderEmail"];
      }
      // If the key doesn't exist in the accumulator, initialize it with an empty array
      if (!acc[key]) {
        acc[key] = [];
      }
      // Push the current object to the array associated with the key
      acc[key].push(obj);
      // Sort the array in descending order based on the valueKey
      acc[key].sort((a, b) => b.distances - a.distances);
      // If the array length is more than 2, keep only the top two elements
      if (acc[key].length > 2) {
        acc[key].splice(2);
      }

      return acc;
    }, {});
  }

  const getRelevanceOrder = (dictionary) => {
    // console.log("Base keys :", baseKeys);
    const sortedKeys = Object.keys(dictionary).sort((a, b) => {
      // Assuming the value to sort by is always the first object in the array
      // and is named 'value1', and you want to sort in ascending order
      // console.log("Val comp:", ...dictionary[a], ...dictionary[b]);
      const valA = Math.max(...dictionary[a].map((obj) => obj.distances));
      const valB = Math.max(...dictionary[b].map((obj) => obj.distances));

      return valB - valA;
    });

    if (sortedKeys.length > 15) {
      sortedKeys.splice(15);
    }
    setKeys(sortedKeys);
    // return sortedKeys;
  };

  useEffect(() => {
    const startDate = dayjs()
      .subtract(3, "day")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    // Set endDate to the end of today
    const endDate = dayjs().endOf("day").format("YYYY-MM-DDTHH:mm:ss");

    // Update state with these values
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 5, mb: 5 }}
      >
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={25}></Typography>
        </Grid>
        <Grid
          item
          xs={12} // Full width on extra small screens
          sm={10} // 10 columns width on small screens
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "4px",
            alignContent: "center",
            position: "relative",
            borderRadius: "24px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            backgroundColor: "tertiary.main",
            transition: "box-shadow 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "0 8px 12px rgba(0, 0, 0, 0.35)",
            },
          }}
        >
          <TextField
            fullWidth
            value={query}
            onChange={handleSearch}
            placeholder="Search Anything - recent climate tech laws, 2024 US Elections, recent changes on AI..."
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                paddingLeft: "24px",
                paddingRight: "88px", // Increase padding to accommodate buttons
                borderColor: "primary.main",
                borderWidth: "1px",

                backgroundColor: "tertiary.main",
                color: "text.secondary",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                  borderWidth: "2.5px",
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "text.secondary",
                opacity: 0.7,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharpIcon />
                </InputAdornment>
              ),

              endAdornment: loading ? (
                <RingLoader size={24} color="blue" />
              ) : (
                <Button
                  onClick={async () => {
                    setLoading(true);
                    setKeys([]);
                    setFilteredResults([]);
                    try {
                      const answer = await axios.post(
                        `${pythonApi}/semantic-search/query`,
                        {
                          user: userobj,
                          search: query,
                          distance: 0.81,
                          startDate: dayjs(startDate).format(
                            "YYYY-MM-DD HH:mm:ss.SSS"
                          ),
                          endDate: dayjs(endDate).format(
                            "YYYY-MM-DD HH:mm:ss.SSS"
                          ),
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );

                      const dataDictionary = groupBy("title", answer.data);
                      console.log("Search Results: ", dataDictionary);
                      setFilteredResults(dataDictionary);
                      getRelevanceOrder(dataDictionary);

                      if (answer.data.length <= 0) {
                        //   setStateMessage("No Results");
                      } else {
                        //   setStateMessage("");
                      }
                      setLoading(false);
                    } catch (e) {
                      setLoading(false);
                      // setStateMessage(
                      //   "Oops! An error occured while processing your request"
                      // );
                    }
                  }}
                  sx={{
                    position: "absolute",
                    right: "70px",
                    color: "text.primary",
                    backdropFilter: "blur(9px)",
                    "&:hover": {
                      backgroundColor: "primary.tertiary",
                    },
                  }}
                >
                  <ArrowOutwardSharpIcon />
                </Button>
              ),
            }}
          />
          <Button
            onClick={async () => {
              setKeys([]);
              setFilteredResults([]);
              setQuery("");
            }}
            sx={{
              position: "absolute",
              right: "6px",
              color: "text.secondary",
              "&:hover": {
                color: "text.primary",
              },
            }}
          >
            <CloseSharpIcon />
          </Button>
        </Grid>
      </Grid>

      {keysInDescedingOrder.length > 0 ? (
        <List
          sx={{
            position: "absolute",
            width: "100%",
            top: "100%",
            marginTop: "7px",
            zIndex: 1,
            // bgcolor: "rgba(255, 255, 255, 0.4)", // Light translucent background
            backdropFilter: "blur(10px)", // Apply a blur effect
            bgcolor: "rgba(0, 0, 0, 0.1)", // Dark gray with 50% opacity
            borderRadius: "24px",
            boxShadow: "0 0 60px 40px rgba(0, 0, 0, 0.1)",
          }}
        >
          {keysInDescedingOrder.map((groupByKey, index) => (
            <>
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& > div": {
                    width: {
                      xs: "100%", // On extra-small devices (mobile)
                      sm: "50%", // On small devices (laptop/desktop)
                    },
                  },
                }}
              >
                <div onClick={async () => {}}>
                  <SourceDisplay
                    source={filteredResults[groupByKey]}
                    handleClickSource={handleClickSource}
                  />
                </div>
              </ListItem>
              <></>
            </>
          ))}
        </List>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default HomeSearchBar;
