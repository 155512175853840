import * as React from "react";

import {
  Grid,
  Typography,
  Chip,
  Divider,
  Card,
  Fab,
  Button,
  Dialog,
  DialogContent,
  useTheme,
  DialogTitle,
  IconButton, // Added useTheme here
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import SmartToyIcon from "@mui/icons-material/SmartToy";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import CloseIcon from "@mui/icons-material/Close";
import PublicDebrief from "../../PublicPages/views/PublicDebrief";
import logo from "../../../common/assets/Debriefs ICON_BLACK.svg";

import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import axios from "axios";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";

// Components
import WebClusterModalv2 from "../../../common/components/Modals/webClusterModalv2";
import DebriefsFilter from "../components/DebriefsFilter";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import ChatWithDebrief from "../../../common/components/Chat/ChatDebrief/ChatWithDebrief";

import ClusterSummaryView from "../../../common/components/Topic Views/ClusterSummaryView";
import { CardContent } from "@mui/material";
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useAuth } from "../../../common/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Routes } from "react-router-dom";
const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

let debriefsData = [];

export default function DebriefsView() {
  const [openLoading, setOpenLoading] = React.useState(false);
  const [debriefsContent, setDebriefs] = React.useState([]);
  const [userTopics, setUserTopics] = React.useState([]);
  const theme = useTheme();
  const [archiveDates, setArchiveDates] = React.useState([]);

  const [openSnackbar, setOpenSnackbar] = React.useState(false); // State for Snackbar visibility
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // Adjust the handleQuestionClick to handle the full question object

  // Snackbar close handler
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Clipboard copy function with Snackbar notification
  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setOpenSnackbar(true); // Show Snackbar on successful copy
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDocumentationClick = () => {
    // Log the event when the link is clicked
    logEvent(analytics, "read_documentation_link", {
      name: "read_documentation",
      label: "How to get started with My Debriefs Guide Link",
    });
  };

  // Modal
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [debriefFilter, setDebriefFilter] = React.useState("my-debriefs");

  const togglePublicPrivateDebriefView = (value) => {
    console.log("Toggle content: ", value);
    if (value === "my-debriefs") {
      let f = filterByUserId();
      let fd = groupDebriefsByDate(f);
      console.log("my debriefs: ", fd);
      setDebriefs(fd);
    } else {
      let fd = groupDebriefsByDate(debriefsData);
      setDebriefs(fd);
    }
    setDebriefFilter(value);
  };

  const filterByUserId = () => {
    const saved = localStorage.getItem("user");
    const userobj = JSON.parse(saved);
    let userId = userobj._id;

    let filteredContent = debriefsData.filter(
      (debrief) => debrief.createdBy === userId
    );

    return filteredContent;
  };

  function filterByTopics(selectedTopics) {
    // Check if "All Topics" is selected or if the selected topics array includes all user topics
    if (
      selectedTopics.includes("All Topics") ||
      selectedTopics.length === userTopics.length
    ) {
      return groupDebriefsByDate(debriefsData); // Return all data grouped by date if "All Topics" is selected
    }

    let filteredDebriefs = debriefsData.filter((debrief) => {
      // Check if the debrief's topic matches any of the selected topics
      return selectedTopics.includes(debrief.topic);
    });

    // Group the filtered debriefs by date
    return groupDebriefsByDate(filteredDebriefs);
  }

  const groupDebriefsByDate = (clusters) => {
    const dictionary = {};
    for (let document of clusters) {
      let date = document.createdAt;
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      const documentDate = new Date(date).toLocaleDateString("en-US", options);
      if (documentDate in dictionary) {
        let insertDocument = [...dictionary[documentDate], document];
        dictionary[documentDate] = insertDocument;
      } else {
        dictionary[documentDate] = [document];
      }
    }
    let dates = Object.keys(dictionary);
    dates.sort((a, b) => new Date(b) - new Date(a));

    setArchiveDates(dates);
    return dictionary;
  };

  // use effect
  React.useEffect(() => {
    const saved = localStorage.getItem("user");
    const userobj = JSON.parse(saved);

    async function fetchUser() {
      setOpenLoading(true);

      let debriefs = await axios.post(
        `${prodApi}/content/fetchAllPublicDebriefs`,
        {
          id: userobj._id,
        }
      );
      let userTopicsData = await axios.get(
        `${prodApi}/sourcesV2/user-topics/${userobj._id}`
      );
      setUserTopics(userTopicsData.data.userTopics);
      debriefsData = debriefs.data;

      let debriefsDateDictionary = groupDebriefsByDate(debriefs.data);
      console.log("Debriefs data: ", debriefsDateDictionary);
      let f = filterByUserId();
      let fd = groupDebriefsByDate(f);
      setDebriefs(fd);
      setOpenLoading(false);
    }
    if (userobj) {
      fetchUser();
    }
  }, [isAuthenticated]);
  const handleGoBack = () => {
    navigate("/dashboard/debriefs"); // Navigate back to the debriefs page
  };

  return (
    <div>
      <PageHead
        title="My Debriefs"
        description="Access comprehensive debriefs on current global topics. Dive into detailed analyses, key insights, and the latest updates across various domains."
      />

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          // style={{ display: "flex" }}
        >
          <Typography
            component="div"
            variant="h1"
            fontWeight={900}
            fontSize={40}
          >
            Debriefs
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          // style={{ mt: "3", mb: "5", display: "flex" }}
        >
          <Typography component="div" variant="body1">
            Here are your personalized debriefs. Analyze different topics, go
            deeper into key insights across many sources, and save time.
          </Typography>
        </Grid>
        <Routes>
          <Route
            path="/public-debrief/:id"
            element={<PublicDebrief onGoBack={handleGoBack} />}
          />
        </Routes>

        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              margin: 5,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "20px",
                verticalAlign: "middle",
                marginRight: "6px",
                color: "#505EFF",
              }}
            >
              school
            </span>
            <Typography
              component="h6"
              style={{ color: "#505EFF", fontSize: "15px", cursor: "pointer" }}
            >
              Learn more about how to create a debrief.
            </Typography>
          </div>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography
                component="h4"
                variant="h3"
                fontWeight={900}
                fontSize={30}
              >
                Tutorial
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              {/* Adjusted for vertical alignment */}
              <Typography
                variant="body2"
                fontWeight={400}
                fontSize={15}
                component="span"
              >
                {" "}
                {/* Changed variant to match MUI and component to "span" */}
                Learn how to use Debriefs to get the most value.&nbsp;
                {/* Non-breaking space added for spacing */}
                <a
                  href="https://www.debriefs.ai/article/get-started-with-debriefs-a-step-by-step-guide"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleDocumentationClick}
                >
                  Read documentation
                </a>
                .
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Link
                to="../my-schedules"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  color: "#d31275",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                    marginRight: "6px",
                  }}
                >
                  edit_square
                </span>
                <Typography component="h6" style={{ fontSize: "15px" }}>
                  Feeling ready? Create your debrief.
                </Typography>
              </Link>
            </Grid>

            <iframe
              width="560"
              height="315"
              src="https://youtu.be/kCym_GhSZBw" // Replace YOUR_VIDEO_ID with the actual ID of the video
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </DialogContent>
        </Dialog>
        {/* FILTER STARTS */}
        {/*          
        <DebriefsFilter
          debriefFilter={debriefFilter}
          setDebriefFilter={setDebriefFilter}
          togglePublicPrivateDebriefView={togglePublicPrivateDebriefView}
          setDebriefs={setDebriefs}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          userTopics={userTopics}
          filterByTopics={filterByTopics} // Make sure to pass this function down if it's defined in DebriefsView
        />*/}
        {/* FILTER ENDS */}
        {archiveDates.length > 0 ? (
          archiveDates.map((date) => {
            return (
              <>
                {/* DATE DIVIDER */}
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 10,
                    }}
                  >
                    <Divider
                      style={{
                        flex: 1,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    />
                    <Chip
                      label={date}
                      style={{
                        marginLeft: 20,
                        marginRight: 20,

                        backgroundColor: theme.palette.background.paper,
                      }}
                    />
                    <Divider
                      style={{
                        flex: 4,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    />
                  </div>
                </Grid>

                <>
                  {/* CARD COLLECTION */}
                  <Grid container spacing={2}>
                    {debriefsContent[date].map((item) => (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Card
                          elevation={0}
                          style={{
                            // padding: 10,
                            borderRadius: 20,
                            alignItems: "center",
                            border: "1.5px solid #E0E0E0",
                            transition: "0.3s", // Transition effect for smooth hover
                            maxWidth: "94%",
                          }}
                        >
                          <CardContent>
                            <ClusterSummaryView
                              item={item}
                              setSelectedItem={setSelectedItem}
                              setOpenModal={setOpenModal}
                              // onQuestionClick={handleQuestionClick}
                              // showQuestionDetails={showQuestionDetails}
                              // setShowQuestionDetails={setShowQuestionDetails}
                              // selectedQuestion={selectedQuestion}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              </>
            );
          })
        ) : (
          <>
            <Grid item xs={12}>
              <Typography>
                <b>
                  You do not have any debriefs yet. To get started, go to the
                  Home tab and create Debriefs.
                </b>
              </Typography>

              <Button
                size="small"
                color="primary"
                onClick={() => {
                  // Construct the URL
                  const baseUrl = window.location.origin; // This gets the base URL of your app
                  const sharedUrl = `${baseUrl}/dashboard/home`;
                  //const sharedUrl = 'https://www.debriefs.ai/contact-us';

                  // Open the new tab with the constructed URL
                  window.open(sharedUrl, "_blank", "noopener,noreferrer");
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                    marginRight: "6px",
                    color: "#d31275",
                  }}
                >
                  add_circle
                </span>
                Create
              </Button>
            </Grid>
          </>
        )}
        {/* Modal */}
        <Grid item xs={12}>
          {openModal ? (
            <Dialog
              fullScreen
              open={openModal}
              onClose={() => setOpenModal(false)}
              // maxWidth={false}
              fullWidth
              PaperProps={{
                style: {
                  width: "90vw",
                  height: "90%",
                  maxWidth: "none",
                  maxHeight: "none",
                  borderRadius: 12,
                  backdropFilter: "blur(10px)",
                },
              }}
            >
              <IconButton
                onClick={() => setOpenModal(false)}
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "grey",
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Debrief Chat</DialogTitle>
              <DialogContent>
                <ChatWithDebrief />
              </DialogContent>
            </Dialog>
          ) : null}
        </Grid>
        {/* Loading back drop */}
        <Grid item xs={12}>
          <BackdropLoader isLoading={openLoading} />
        </Grid>
        <Fab
          size="small" // This will make the button smaller
          color="secondary"
          aria-label="scroll-top"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backdropFilter: "blur(10px)", // Glass effect
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background
            color: "secondary", // Text color set to black
          }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
        <Fab
          size="large" // This will make the button smaller
          color="secondary"
          aria-label="scroll-top"
          style={{
            position: "fixed",
            bottom: "100px",
            right: "20px",
            backdropFilter: "blur(10px)", // Glass effect
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background
            color: "secondary", // Text color set to black
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
                    <img 
  src={logo} 
  alt="Logo" 
  style={{ 
    width: '50px', 
    height: '50px', 
    borderRadius: '50%', // Makes the image circular
    padding: '1px' // Optional: Adds padding inside the border
  }} 
/>
        </Fab>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => handleCloseSnackbar("")}
          severity="success"
          sx={{
            width: "100%",
            border: "1px solid lightgrey", // Add dark grey border to Alert
          }}
        >
          Link copied to clipboard
        </Alert>
      </Snackbar>
    </div>
  );
}
