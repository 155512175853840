import React, { useState, useEffect } from "react";

import axios from "axios";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
} from "@mui/material";
import RingLoader from "react-spinners/RingLoader";
const pythonApi = "http://localhost:8080"; // Replace with your API endpoint as needed
const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function compare(a, b) {
  if (a.searchQuery < b.searchQuery) {
    return -1;
  }
  if (a.searchQuery > b.searchQuery) {
    return 1;
  }
  return 0;
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const TopicSelectionAddition = ({
  parenSelectOptions,
  parentSetNewQuery,
  newQuery,
  selectedOptions,
  actionType,
  setActionType,
}) => {
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  //   const [actionType, setActionType] = useState(0);

  const [allAvailableWebSearchTopics, setAllAvailableWebSearchTopics] =
    useState([]);
  const [openLoading, setOpenLoading] = useState(false);
  const [userTopics, setUserTopics] = useState([]);
  //   const [selectedOptions, setSelectedOptions] = useState([]);
  //   const [newQuery, setNewQuery] = useState("");
  const handleChange = (event, newValue) => {
    setActionType(newValue);
  };
  const handleAutocompleteChange = (event, newSelectedOptions) => {
    parenSelectOptions(newSelectedOptions);
    // setSelectedOptions(newSelectedOptions);
  };
  useEffect(() => {
    async function fetchData() {
      setOpenLoading(true);
      //  Fetch user object

      // Fetch all search topics for options
      let allWebSearchTopics = await axios.get(
        `${prodApi}/sourcesV2/all-active-websearch-topics`
      );

      // Fetch all topics a user follows
      let userTopicsData = await axios.get(
        `${prodApi}/sourcesV2/user-topics/${userobj._id}`
      );

      // Set the view of users current followed topics
      setUserTopics(userTopicsData.data.userTopics);
      // Filter current user topics from available options
      setAllAvailableWebSearchTopics(
        allWebSearchTopics.data.allWebSearchTopics
          .filter(
            (item) =>
              !userTopicsData.data.userTopics.some(
                (topic) => topic._id === item._id
              )
          )
          .sort(compare)
      );
      setOpenLoading(false);
    }
    fetchData();
  }, []);
  return (
    <>
      {openLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 20,
            height: "100%",
          }}
        >
          <RingLoader
            loading={openLoading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              tm: "5",
              tb: "5",
            }}
          >
            {actionType === 1 ? (
              <>
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                    marginRight: "6px",
                    color: "#C6017E",
                  }}
                >
                  info
                </span>
                <Typography
                  component="h6"
                  style={{ color: "#C6017E", fontSize: "15px" }}
                >
                  Please allow up to 24 hours for us to process and review your
                  topic suggestions due to the high volume of requests we
                  receive.{" "}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={actionType}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Search Topics"
                  {...a11yProps(0)}
                  style={{ textTransform: "none" }}
                />

                <Tab
                  label="Create New Topic"
                  {...a11yProps(1)}
                  style={{ textTransform: "none" }}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel value={actionType} index={0}>
            <div style={{ backgroundColor: "", padding: 10 }}>
              {/* <p>Explore existing topics that may fit your interests.</p> */}
              <Autocomplete
                multiple
                id="tags-outlined"
                options={allAvailableWebSearchTopics}
                getOptionLabel={(option) => option.searchQuery}
                value={selectedOptions}
                onChange={handleAutocompleteChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Choose a topic" />
                )}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={actionType} index={1}>
            <p>Cant find what you are looking for? Create a new one.</p>
            <TextField
              name="newTopic"
              required
              fullWidth
              id="topic"
              label="Create New Topic"
              autoFocus
              variant="outlined"
              color="secondary"
              value={newQuery}
              onChange={(event) => {
                parentSetNewQuery(event.target.value);
                // setNewQuery(event.target.value);
              }}
            />
          </CustomTabPanel>
        </>
      )}
    </>
  );
};
