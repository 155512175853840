import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ImportantQuestions({ item, sources, baseUrl , onQuestionClick }) {
  


  
  
  {/* 
  const handleQuestionClick = (questionId) => {
    // Navigate to the question details page based on the questionId
    // You can use your routing library or navigation mechanism here
    console.log(
      `Navigating to question details page for questionId: ${questionId}`
    );
    // Construct the URL for the specific question
    const sharedUrl = `${baseUrl}/shared/${item._id}`;

    // Open the URL in a new tab
    window.open(sharedUrl, "_blank", "noopener,noreferrer");
  };
  */}

  const handleQuestionClick = (section) => {
      onQuestionClick(section);
      
    };



  return (
    <div>
      <Typography variant="h3" component="div" gutterBottom>
      </Typography>
      <Accordion
        elevation={0}
        style={{
          border: "1.5px solid #E0E0E0",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" component="span">
            Go Deeper
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {sources?.map((section, index) => (
              <ListItem
                key={section.id}
                button
                  onClick={() => {
                  console.log(section);
                  handleQuestionClick(section);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderLeft: "4px solid #E0E0E0",
                  borderRadius: "4px",
                  padding: "12px 16px",
                  marginBottom: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      
                      {section.title}
                    </Typography>
                  }
                />
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "0.8rem",
                    color: "text.secondary",
                    ml: 1,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
