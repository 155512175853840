import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CloseIcon,
} from "@mui/material";

const FeedSelector = ({ homeFeedView, handleChange }) => {
  return (
    <Box>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <FormControl component="fieldset">
            <RadioGroup value={homeFeedView} onChange={handleChange}>
              <FormControlLabel
                value="news-articles"
                control={<Radio />}
                label="News Articles"
              />

              <FormControlLabel
                value="news-letters"
                control={<Radio />}
                label="Newsletters"
              />

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Academic Journals"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Patents and Innovation Pipelines"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Financial Reports - 10K, 10Q"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Earnings Calls"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Police Reports"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="coming-soon"
                  control={<Radio disabled />}
                  label="Airtable"
                />
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: "rgba(66, 133, 244, 0.1)",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "1",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#4285F4",
                      fontWeight: "bold",
                    }}
                  >
                    Pro
                  </Typography>
                </Box>
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default FeedSelector;
