import { Grid } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

import TypingText from "../../../common/components/Animated/TypingText";
import axios from "axios";
import RingLoader from "react-spinners/RingLoader";

// COMPONENTS
import ActionButton from "../../../common/components/Buttons/ActionButton";
import SecondaryActionButton from "../../../common/components/Buttons/SecondaryActionButton";
import SectionCard from "../../../common/components/Cards/SectionCard";

// DATE RANGE

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// URLS
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function DebriefBuilder() {
  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState([]);

  const [textOutput, setTextOutput] = useState("Lorem ipsum sum bull shit");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleUpdate = (cardId, value, field) => {
    const updatedCards = sections.map((card) =>
      card._id === cardId ? { ...card, [field]: value } : card
    );
    setSections(updatedCards);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{
          height: "85vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h1>Build Debrief</h1>
          <div>
            <SecondaryActionButton
              label={"Save Report"}
              onClick={async () => {
                setLoading(!loading);
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            justifyContent: "space-between",

            display: "flex",
          }}
        >
          <div
            style={{
              padding: 10,
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={setEndDate}
            />
          </div>
          <div style={{ paddingRight: 30 }}>
            <SecondaryActionButton
              label={"Generate"}
              onClick={async () => {
                setLoading(true);
                const answer = await axios.post(
                  `${pythonApi}/build-debrief`,
                  {
                    sections: sections,
                    startDate: dayjs(startDate).format(
                      "YYYY-MM-DD HH:mm:ss.SSS"
                    ),
                    endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                console.log("Answer:", answer);
                setLoading(false);

                setTextOutput(answer.data);
              }}
            >
              Generate
            </SecondaryActionButton>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={4}
          spacing={1}
          style={{
            overflow: "scroll",
            height: "100%",
          }}
        >
          {sections.length > 0 ? (
            <>
              {sections.map((i, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <SectionCard
                      index={index}
                      data={i}
                      key={index}
                      sections={sections}
                      handleUpdate={handleUpdate}
                      add={() => {
                        let newSections = [
                          ...sections,
                          { _id: index + 1, question: "", keywords: "" },
                        ];
                        setSections(newSections);
                      }}
                      delete={() => {
                        let updated = [...sections].filter((x) => {
                          return i._id !== x._id;
                        });
                        setSections(updated);
                      }}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#FFFFFF",
                  border: "1.5px solid #E0E0E0",
                  borderRadius: 20,
                }}
              >
                <ActionButton
                  label={"Add Section"}
                  fullWidth
                  onClick={() => {
                    setSections([{ _id: 0, question: "", keywords: "" }]);
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            overflow: "scroll",
            height: "85%",
          }}
        >
          <div
            style={{
              borderRadius: 10,
              width: "100%",
              backgroundColor: "white",
              border: "1.5px solid #E0E0E0",
              padding: 10,
              height: "auto",
            }}
          >
            {loading ? (
              <div
                style={{
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RingLoader
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <TypingText text={textOutput} speed={10} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DebriefBuilder;
