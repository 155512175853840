import { useSelector, useDispatch } from "react-redux";
import { commonSliceActions } from "../reducers/CommonReducer";

const useCommonHook = () => {
  const snackbarOpen = useSelector((state) => state.commonSlice.snackbarOpen); // Accessing openDelete directly from the state
  const snackbarMessage = useSelector(
    (state) => state.commonSlice.snackbarMessage
  );
  const dispatch = useDispatch();

  const setSnackBar = (open, message) => {
    dispatch(
      commonSliceActions.setSnackBar({
        snackbarOpen: open,
        snackbarMessage: message,
      })
    );
  };

  return {
    snackbarMessage,
    snackbarOpen,
    setSnackBar,
  };
};

export default useCommonHook;
