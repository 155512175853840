import React from "react";
import useUtilsManageSlices from "../Hooks/useUtilsMangeSlices";

export default (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { setOpenDelete } = useUtilsManageSlices();

  const buttonClicked = () => {
    setOpenDelete();
  };

  return (
    <span>
      <span>{cellValue}</span>&nbsp;
      <button onClick={() => buttonClicked()}>Delete</button>
    </span>
  );
};
