import React, { useState, useEffect } from "react";
import "./SectionPreview.css"; // Make sure this is the correct path to your CSS file
import axios from "axios";
import { getDomainName } from "../../Utilities/SourceUtilities";
import Chip from "@mui/material/Chip";

import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import { Card } from "@mui/material";
import { CustomAccordion } from "../Accordion/CustomAccordion";
const pythonApi = "http://localhost:8080"; // Replace with your API endpoint as needed

const highlightText = (text, wordsToHighlight) => {
  // Check if wordsToHighlight is empty
  if (!wordsToHighlight || wordsToHighlight.length === 0) {
    return text;
  }

  // Split the text into sentences and take the first three
  const firstThreeSentences =
    text
      .match(/[^\.!\?]+[\.!\?]+/g)
      ?.slice(0, 3)
      .join(" ") || "";

  // Create a regex pattern with word boundaries
  const regex = new RegExp(
    `\\b(${wordsToHighlight.map((word) => word.word).join("|")})\\b`,
    "gi"
  );



  return firstThreeSentences.split(regex).map((part, index) => {
    const wordObj = wordsToHighlight.find(
      (word) => word.word.toLowerCase() === part.toLowerCase()
    );
    return wordObj ? (
      <span key={index} style={{ backgroundColor: wordObj.color }}>
        {part}
      </span>
    ) : (
      part
    );
  });
};

function SectionPreview(p) {
  const [previewData, setPreviewData] = useState(null);

    // Truncate function
function truncateTitle(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

  useEffect(() => {
    const fetchPreviewData = async () => {
      try {
        const response = await axios.post(`${pythonApi}/api/link-preview`, {
          url: p.source.link,
        });
        console.log("PREVIEW: ", response.data);
        setPreviewData(response.data);
      } catch (error) {
        console.error("Error fetching link preview:", error);
      }
    };

    // fetchPreviewData();
  }, [p]);

  return (
    <>
      {" "}
      <Card elevation={0} className="custom-card">
        {p?.source ? (
          <>
<p
  className="image-title-link"
  onClick={() => {
    console.log("Clicked card: ", p);
    if (p.source?.link && p.source.link !== "no value") {
      window.open(p.source.link, "_blank");
    } else {
      p.onClickEmail();
    }
  }}
>
  {p?.source?.imageUrl && p?.source.imageUrl !== "no value" && (
    <img
      src={p.source.imageUrl}
      alt="Preview"
      className="preview-image"
    />
  )}
  {p?.source?.image && p?.source.image !== "no value" && (
    <EmailIcon style={{ margin: 10 }} />
  )}
  <h2 className="preview-title">
    {p.source?.title && p.source?.title !== "no value"
      ? truncateTitle(p.source.title, 70) // Apply truncate function here
      : truncateTitle(p.source.subject, 70)} 
  </h2>
</p>

            <CustomAccordion
              section="View Section"
              children={
                <>
                  <p
                    style={{
                      marginTop: 15,
                     color: "text.secondary",
                      paddingLeft: 10,
                      backgroundColor: "tertiary.main",

                      paddingRight: 10,

                      borderLeft: "3.5px solid #E0E0E0",
                    }}
                  >
                    {p.source.text.slice(0, 269)}...
                  </p>
                </>
              }
            />

            <div
              className="flex-row"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 5, margin: 5
              }}
            >
              <div
                className="flex-item"
                style={{ display: "flex", alignItems: "center" }}
              >
                {p.source?.favicon && p.source.favicon !== "no value" && (
                  <div>
                    <Chip
                      avatar={
                        <img
                          src={p.source.favicon}
                          alt="Favicon"
                          className="favicon"
                          style={{ borderRadius: 5, padding: 1 }}
                        />
                      }
                      label={getDomainName(p.source.link)}
                      onClick={() => window.open(p.source.link, "_blank")}
                      // className="source-chip"
                    />
                  </div>
                )}
                {p.source?.senderEmail &&
                  p.source.senderEmail !== "no value" && (
                    <div>
                      <Chip
                        onClick={() => {
                          if (p.source?.link && p.source.link !== "no value") {
                          } else {
                            p.onClickEmail();
                          }
                        }}
                        avatar={
                          <img
                            src={p.source.image}
                            alt="Favicon"
                            // className="favicon"
                            style={{ borderRadius: 5, padding: 1 }}
                          />
                        }
                        label={
                          p.source.senderEmail.length > 20
                            ? p.source.senderEmail.substring(0, 20 - 3) + "..."
                            : p.source.senderEmail
                        }
                        className="source-chip"
                      />
                    </div>
                  )}
              </div>

              {/* 
              <Divider
                className="divider-vertical"
                orientation="vertical"
                flexItem
              />
              <div
                className="flex-item"
                style={{ display: "flex", alignItems: "center" }}
              >
                <p style={{ margin: 0 }}>
                  {p.source?.link && p.source.link !== "no value"
                    ? "Article"
                    : "News Letter"}
                </p>
              </div>
              */}
            </div>
            
          </>
        ) : (
          <p>Loading link preview...</p>
        )}
      </Card>
    </>
  );
}

export default SectionPreview;
