import React, { useState, useEffect, useRef, useCallback } from "react";
import "./CronScheduler.css";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: theme.palette.background.paper,
  padding: "1rem",
  borderRadius: "5px",
  width: "300px",
  border: `1px solid ${theme.palette.divider}`,
}));

const Day = styled("div")(({ theme, selected, disabled }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: disabled ? "not-allowed" : "pointer",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: selected
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : "inherit",
}));

const CronScheduler = (p) => {
  const [frequency, setFrequency] = useState(p.frequency);
  const [time, setTime] = useState(`${p.hour}:${p.minute}`);
  const [days, setDays] = useState(p.days);
  const [months, setMonths] = useState(p.months);

  // Refs to store the previous state values
  const prevFrequency = useRef(p.frequency);
  const prevTime = useRef(`${p.hour}:${p.minute}`);
  const prevDays = useRef(p.days);
  const prevMonths = useRef(p.months);

  console.log("Frequency: ", frequency);
  console.log("Days: ", days);
  console.log("Months: ", months);

  const handleSave = useCallback(() => {
    let [hour, minute] = time.split(":");
    let day_of_week = days?.filter((i) => i !== "").join(",");
    let day_of_month = months.filter((i) => i !== "").join(",");
    let frequencyObject = {
      type: frequency,
      hour: hour,
      minute: minute,
      day_of_week: day_of_week,
      day: day_of_month,
    };
    // Call the onSave prop with the frequency object
    p.onSave(frequencyObject);
  }, [time, days, months, frequency, p]);

  useEffect(() => {
    // Function to compare arrays
    const arraysEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    // Check if any value is different from its previous value
    if (
      frequency !== prevFrequency.current ||
      time !== prevTime.current ||
      !arraysEqual(days, prevDays.current) ||
      !arraysEqual(months, prevMonths.current)
    ) {
      handleSave();
    }

    // Update refs with current state
    prevFrequency.current = frequency;
    prevTime.current = time;
    prevDays.current = days;
    prevMonths.current = months;
  }, [frequency, time, days, months, handleSave]);
  return (
    // <div className="cron-scheduler">
    <StyledDiv>
      <label>
        <b>Select Frequency:</b>
      </label>
      <select
        disabled={p.disabled}
        value={frequency}
        onChange={(e) => {
          setFrequency(e.target.value);
          setDays([]);
          setMonths([]);
        }}
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </select>

      <label>
        <b>Select Time:</b>
      </label>
      <input
        disabled={p.disabled}
        type="time"
        value={time}
        onChange={(e) => {
          console.log("TIME:", e.target.value);
          setTime(e.target.value);
        }}
      />

      {frequency === "weekly" && (
        <div className={`day-selector ${p.disabled ? "disabled" : ""}`}>
          {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map(
            (day, index) => (
              <Day
                key={index}
                className={`day ${days.includes(day) ? "selected" : ""} ${
                  p.disabled ? "disabled" : ""
                }`}
                onClick={() => {
                  if (!p.disabled) {
                    if (days.includes(day))
                      setDays((prevDays) => prevDays.filter((d) => d !== day));
                    else setDays((prevDays) => [...prevDays, day]);
                  }
                }}
              >
                {day}
              </Day>
            )
          )}
        </div>
      )}

      {frequency === "monthly" && (
        <div className={`day-selector ${p.disabled ? "disabled" : ""}`}>
          {Array.from({ length: 31 }).map((_, index) => (
            <Day
              key={index}
              className={`day ${
                months.includes(String(index + 1)) ? "selected" : ""
              } ${p.disabled ? "disabled" : ""}`}
              onClick={() => {
                if (!p.disabled) {
                  if (months.includes(String(index + 1))) {
                    setMonths((prevMonths) =>
                      prevMonths.filter((m) => m !== String(index + 1))
                    );
                  } else {
                    setMonths((prevMonths) => [
                      ...prevMonths,
                      String(index + 1),
                    ]);
                  }
                }
              }}
            >
              {index + 1}
            </Day>
          ))}
        </div>
      )}
    </StyledDiv>
  );
};

export default CronScheduler;
