import * as React from "react";

import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

// Components

export default function DateFormatString(p) {
  const localDate = moment.utc(p.time).local();

  // Formatting the date and time
  const formattedDate = localDate.format(p.dateFormat);
  const formattedTime = localDate.format(p.timeFormat);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: 3,
        }}
      >
        {/* <Typography><b>Created On:</b></Typography> */}
        <Typography
        // fontStyle="italic"
        >{`${formattedDate} @ ${formattedTime}`}</Typography>

        {/* <Chip
          label={`${formattedDate} @ ${formattedTime}`}
          style={{
            fontWeight: "bold",
            marginLeft: 10,
            marginRight: 10,
          }}
        /> */}
      </div>
    </>
  );
}
