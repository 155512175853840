import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { groupByDate } from '../../Utilities/SourceUtilities';
import SectionPreview from '../SectionPreview/SectionPreview';

const SourcesDisplayInContext = ({ sources }) => {
  const theme = useTheme();

  // Function to get unique sources within a date group based on title
  const getUniqueSources = (sources) => {
    const uniqueTitles = new Set();
    return sources.filter(source => {
      if (!uniqueTitles.has(source.title)) {
        uniqueTitles.add(source.title);
        return true;
      }
      return false;
    });
  };

  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Sources in context</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          {groupByDate(sources).length > 0 ? (
            groupByDate(sources).map((dateGroup, dateIndex) => (
              <Grid item xs={12} key={`date_group_${dateIndex}`}>
                <Accordion
                  elevation={0}
                  style={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${dateIndex}-content`}
                    id={`panel${dateIndex}-header`}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        height: 10,
                      }}
                    >
                      <Typography>Expand</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getUniqueSources(dateGroup.sources).map((source, sourceIndex) => (
                        <Grid item xs={12} key={`section_${sourceIndex}`}>
                          <SectionPreview source={source} />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography>No sources available</Typography>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SourcesDisplayInContext;