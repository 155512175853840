// useUtilsManageSlices.js

import { useSelector, useDispatch } from "react-redux";
import { ManageSourcesSliceActions } from "../reducer/ManageSourcesReducer";
import axios from "axios";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

const useUtilsManageSlices = () => {
  const openDelete = useSelector((state) => state.manageSources.openDelete); // Accessing openDelete directly from the state
  const openRemove = useSelector((state) => state.manageSources.openRemove);
  const dispatch = useDispatch();

  const setOpenDelete = () => {
    dispatch(ManageSourcesSliceActions.setOpenDelete(!openDelete));
  };

  const setOpenRemove = () => {
    console.log("Use Utils remove");
    dispatch(ManageSourcesSliceActions.setOpenRemove(!openRemove));
  };

  const handleQueryAddition = async (
    newQuery,
    userobj,
    rowData,
    setRowData,
    setNewQuery,
    handleClose
  ) => {
    try {
      let response;
      if ("id" in newQuery) {
        console.log("Joining new query: ", newQuery);
        // add user to topic
        // if joining topic make sure the user has less than 3 active search queries
        response = await axios.post(`${prodApi}/sourcesV2/join-topic`, {
          queryId: newQuery.id,
          userId: userobj._id,
        });
        response = response.data.query;
      } else {
        // create new topic
        response = await axios.post(`${prodApi}/sourcesV2/create-topic`, {
          query: newQuery.label,
          userId: userobj._id,
        });
        response = response.data;
      }

      console.log("New query save response: ", response);
      let rowDataCopy = [...rowData];
      rowDataCopy.push(response);
      setRowData(rowDataCopy);
      console.log("ROW DATA COPY: ", rowDataCopy);
      setNewQuery("");
      handleClose();
    } catch (e) {
      console.log("Error handling query addition: ", e);
    }
  };
  // Additional business logic goes here...

  return {
    openDelete,
    setOpenDelete,
    setOpenRemove,
    openRemove,
    handleQueryAddition,
  };
};

export default useUtilsManageSlices;
