import React from "react";
import { Helmet } from "react-helmet";

const PageHead = ({ title, description }) => (
  <Helmet>
    <title>{title} • Debriefs AI</title>
    <meta name="description" content={description} />
    <meta
      name="keywords"
      content="workspaces, news scheduling, professional intelligence, tailored news, industry insights, topic curation, intelligence design"
    />
    {/* Open Graph tags for social media */}
    <meta
      property="og:title"
      content="Professional News Intelligence Tailoring - Workspaces"
    />
    <meta
      property="og:description"
      content="Create your custom news intelligence workspace. Schedule and design debriefs that provide targeted insights into your professional field."
    />
    <meta
      property="og:image"
      content="https://uploads-ssl.webflow.com/63b447a20348b2e70f66a236/655fabb44ae62b031896f529_de.png"
    />
    {/* Add any other head elements you need */}
  </Helmet>
);

export default PageHead;
