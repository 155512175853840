import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { getDomainName } from "../../Utilities/SourceUtilities";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import { ThemeModeContext } from "../../../App";
// Components
import SectionPreview from "../SectionPreview/SectionPreview";
import { groupByDate } from "../../Utilities/SourceUtilities";

import { useTheme } from "@mui/material";
export default function KeyInsightsView(p) {
  // const mode = React.useContext(ThemeModeContext);
  // const groupedSources = groupByDate(p.sources[0]?.groupedSources);

  const theme = useTheme();

  return (
    <div
      style={{
        borderRadius: "10px",
        // maxWidth: "70%",
      }}
    >
      <Typography variant="h3" component="div">
        Important Questions
      </Typography>
      {p.item?.clusters?.map((section, index) => (
        <div
          style={{
            marginTop: 10,
          }}
          key={`key_insights_${index}`}
        >
          <Accordion
            elevation={0}
            style={{
              border: "1.5px solid #E0E0E0",
              borderRadius: "10px",

              marginTop: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="body1"
                component="span"
                // style={{ fontWeight: 500 }}
              >
                {section.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography
                variant="body2"
                component="span"
                style={{ maxWidth: "50%" }}
                dangerouslySetInnerHTML={{ __html: section.summary }}
              />
              {p.sources.length > 0 ? (
                <>
                  {" "}
                  <Accordion
                    elevation={0}
                    style={{
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      Sources in context
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {groupByDate(p.sources[index]?.groupedSources).length >
                        0 ? (
                          <>
                            {groupByDate(p.sources[index]?.groupedSources).map(
                              (i) => (
                                <Grid item xs={12}>
                                  <Accordion
                                    elevation={0}
                                    style={{
                                      border: "1px solid #E0E0E0",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          alignContent: "center",
                                          width: "100%",
                                          height: 10,
                                        }}
                                      >
                                        <p>{i.date}</p>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container spacing={2}>
                                        {i.sources.map((i, index) => (
                                          <>
                                            <Grid
                                              item
                                              xs={12}
                                              key={`section_${index}`}
                                            >
                                              <SectionPreview source={i} />
                                            </Grid>
                                          </>
                                        ))}
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
