import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import RingLoader from "react-spinners/RingLoader";
import TypingText from "../../Animated/TypingText";
import IconButton from "@mui/material/IconButton";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import userAvatar from "../../../assets/default_user_icon.svg";
import debriefLogo from "../../../assets/Debriefs.svg";

const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const ChatWithDebrief = (p) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messageListRef = useRef(null);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() !== "") {
      const newMessage = { sender: "me", message: inputText };
      let newList = [...messages, newMessage];
      setMessages(newList);
      setInputText("");
      setIsLoading(true);
      await handleQuestionSubmit(newList);
      setIsLoading(false);
    }
  };

  const handleQuestionSubmit = async (newList) => {
    try {
      // "https://rsnc-python.onrender.com/answer",
      // "http://localhost:8080/answer",
      // "http://127.0.0.1:5000/answerV2",
      const answer = await axios.post(
        `${pythonApi}/controlled-answer`,
        {
          category: p.searchQuery,
          question: inputText,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const newMessage = { sender: "ai", message: answer.data };
      setMessages([...newList, newMessage]);
      console.log("answer: ", answer.data);
    } catch (e) {
      console.log("Error asking question: ", e);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    const inputElement = document.getElementById("chat-input");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <div className="chat-container">
      <div className="chat-body">
        <div className="message-list">
          {messages.map((message, index) => (
            <div key={index} className={`message-bubble ${message.sender}`}>
              {message.sender === "ai" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypingText text={message.message} speed={10} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {message.message}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="chat-footer">
        <input
          className="custom-input"
          type="text"
          id="chat-input"
          placeholder="Ask Debriefs Anything - Go deeper into key insights, summaries and your feed..."
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        {isLoading ? (
          <RingLoader
            loading={isLoading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <IconButton
            className="SendButton"
            onClick={handleSendMessage}
            variant="contained"
          >
            <SendRoundedIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ChatWithDebrief;
