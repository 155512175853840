import React, { useState, useEffect } from "react";
import "./TextWithSource.css";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const BulletSummaryView = ({ text }) => {
  if (!text) {
    return null;
  }

  // Only try to parse JSON if the text looks like it starts with a JSON object/array
  if (isJsonString(text)) {
    try {
      const parsedData = JSON.parse(text);
      const bullets = parsedData.output || [];

      return (
        <div>
          {bullets.map((item, index) => (
            <div
              key={`bullet_${index}_${item.category}`}
              style={{
                marginTop: 25,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 5,
                borderLeft: "1.5px solid #E0E0E0",
              }}
            >
              {item.category && <strong>{item.category}</strong>}
              {item.short_summary && (
                <BulletContent shortSummary={item.short_summary} />
              )}
            </div>
          ))}
        </div>
      );
    } catch (e) {
      console.error("Error parsing JSON or rendering bullets:", e);
      return <div>Error displaying bullets.</div>;
    }
  } else {
    // Handle non-JSON text, possibly show it directly
    return <div>{text}</div>;
  }
};

const BulletContent = ({ shortSummary }) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!shortSummary) {
    return null; // or return a default value or placeholder
  }

  const words = shortSummary.split(" ");

  if (!isMobile || words.length <= 25) {
    return <div>{shortSummary}</div>;
  }

  const truncatedSummary = words.slice(0, 25).join(" ");

  return (
    <div>
      {expanded ? (
        <div>{shortSummary}</div>
      ) : (
        <div>
          {truncatedSummary}
          <span
style={{ 
  cursor: "zoom-in", 
  color: "#545c67", 
  marginLeft: '4px',
  fontStyle: "italic", 
  fontSize: "0.9em" 
}}            onClick={() => setExpanded(true)}
          >
            ...more
          </span>
        </div>
      )}
    </div>
  );
};

export default BulletSummaryView;