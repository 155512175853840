import React, { useState, useEffect, useRef } from "react";
import "./ChatComponent.css";
import axios from "axios";
import RingLoader from "react-spinners/RingLoader";
import Typewriter from "./Typewriter";
import IconButton from "@mui/material/IconButton";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;

const ChatComponent = (p) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Step 1: Loading state
  const messageListRef = useRef(null);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() !== "") {
      const newMessage = { sender: "me", message: inputText };
      let newList = [...messages, newMessage];
      setMessages(newList);
      setInputText("");
      setIsLoading(true); // Step 2: Start loading
      await handleQuestionSubmit(newList);
      setIsLoading(false); // Step 2: End loading
    }
  };

  const handleQuestionSubmit = async (newList) => {
    try {
      // "https://rsnc-python.onrender.com/answer",
      // "http://localhost:8080/answer",
      // "http://127.0.0.1:5000/answerV2",
      const answer = await axios.post(
        `${prodApiPython}/answerV2`,
        {
          id: p.content._id,
          category: p.content.category,
          question: inputText,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const newMessage = { sender: "ai", message: answer.data };
      setMessages([...newList, newMessage]);
      console.log("answer: ", answer.data);
    } catch (e) {
      console.log("Error asking question: ", e);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    const inputElement = document.getElementById("chat-input");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <div className="chat-container" >
      <div className="chat-header">{p.title}</div>
      <div className="chat-body">
        <div className="message-list">
          {messages.map((message, index) => (
            <div className={`message-bubble ${message.sender}`}>
              {message.sender === "ai" ? (
                <Typewriter text={message.message} speed={10} />
              ) : (
                message.message
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="chat-footer">
        <input
          type="text"
          id="chat-input"
          className="custom-input"
          placeholder="Ask about news, key insights, summaries and your feed..."
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        {isLoading ? ( // Step 3: Conditionally render the loader
          <RingLoader
            loading={isLoading}
            color="secondary"
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <IconButton
            className="SendButton"
            onClick={handleSendMessage}
            variant="contained"
            color="secondary"
          >
            <SendRoundedIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ChatComponent;
