import React from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Avatar,
  Box,
  Grid,
  Typography,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Button,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ManageSearchSharpIcon from '@mui/icons-material/ManageSearchSharp';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function SourceFilterSelection({
  filterOptions,
  topicsFeed,
  setTopicsFeedFilter,
}) {
  const [selectedSources, setSelectedSources] = React.useState([]);

  // Sample data - replace with actual data as needed
  let sources = [];

  if (filterOptions) {
    sources = Object.keys(filterOptions).map((key, index) => {
      return {
        id: index,
        name: key,
        imageUrl: filterOptions[key].uri,
        type: filterOptions[key].type,
      };
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        position: "relative",
        p: 2,
      }}
    >




      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 600,
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Select Sources
          </Typography>
          <Autocomplete
            multiple
            fullWidth
            options={sources}
            getOptionLabel={(option) => option.name}
            value={selectedSources}
            onChange={(event, newValue) => {
              setSelectedSources(newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  avatar={<Avatar alt={option.name} src={option.imageUrl} />}
                  label={option.name}
                  onDelete={() => {
                    const newValue = [...selectedSources];
                    newValue.splice(index, 1);
                    setSelectedSources(newValue);
                  }}
                  sx={{ m: 0.5 }}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
<TextField
  {...params}
  placeholder="Search sources"
  InputProps={{
    ...params.InputProps,
    startAdornment: (
      <>
        {selectedSources.map((source, index) => (
          <Chip
            key={index}
            avatar={<Avatar alt={source.name} src={source.imageUrl} />}
            label={source.name}
            onDelete={() => {
              const newSelectedSources = [...selectedSources];
              newSelectedSources.splice(index, 1);
              setSelectedSources(newSelectedSources);
            }}
            sx={{ m: 0.5 }}
          />
        ))}
        <InputAdornment position="start">
          <ManageSearchSharpIcon />
        </InputAdornment>
      </>
    ),
  }}
/>
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.id}>
                <ListItemAvatar>
                  <Avatar alt={option.name} src={option.imageUrl} />
                </ListItemAvatar>
                <ListItemText primary={option.name} />
              </ListItem>
            )}

            
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          {selectedSources.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedSources([]);
                setTopicsFeedFilter(topicsFeed);
              }}
              startIcon={<CloseIcon />}
              sx={{
                mr: 1,
                borderColor: "text.secondary",
                color: "text.secondary",
                "&:hover": {
                  borderColor: "text.primary",
                  color: "text.primary",
                  backgroundColor: "action.hover",
                },
              }}
            >
              Clear
            </Button>
          )}
          <Button
            variant="contained"
            disabled={selectedSources.length === 0}
            onClick={() => {
              let filteredResults = {};
              console.log("Selected sources: ", selectedSources);
              if (selectedSources.length !== 0) {
                // Iterate over each filter in the filters array
                selectedSources.forEach((filter) => {
                  if (filter.type === "webArticle") {
                    // Check each entry in the dictionary
                    Object.keys(topicsFeed).forEach((key) => {
                      // Filter the array of objects to find matches
                      let matches = topicsFeed[key].filter((item) => {
                        return item.link && item.link.includes(filter.name);
                      });
                      // If matches are found, add them to the results under the current key
                      if (matches.length > 0) {
                        if (!filteredResults[key]) {
                          filteredResults[key] = [];
                        }
                        filteredResults[key].push(...matches);
                      }
                    });
                  }
                });
                setTopicsFeedFilter(filteredResults);
              } else {
                setTopicsFeedFilter(topicsFeed);
              }
            }}
            startIcon={<TaskAltIcon />}
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              "&.Mui-disabled": {
                backgroundColor: "action.disabledBackground",
                color: "action.disabled",
              },
            }}
          >
            Apply
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default SourceFilterSelection;