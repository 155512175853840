import React from "react";
import "./TextWithSource.css";

function extractDomain(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (e) {
    return "";
  }
}

const SummaryViewv1 = ({ text }) => {
  try {
    const obj = JSON.parse(text);

    const textItems = [];

    for (let d of obj.summary) {
      // Extract and modify the domain name
      let url = extractDomain(d.source);
      url = url.replace("www.", "").toUpperCase();

      textItems.push(
        <div className="text-with-source" key={d.block}>
          <span className="text-content">{d.block}</span>
          {url === "" ? null : (
            <sup>
              <button
                className="source-button"
                onClick={() => {
                  window.open(d.source);
                }}
              >
                {url} {/* Use the modified and capitalized URL */}
              </button>
            </sup>
          )}
        </div>
      );
    }
    return <div>{textItems}</div>;
  } catch (e) {
    // console.log("Not json, inserting HTML: ", e);
    return <p dangerouslySetInnerHTML={{ __html: text }} />;
    // return null;
  }
};

export default SummaryViewv1;
