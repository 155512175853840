import * as React from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../../common/components/Buttons/GradientButton";

import im from "../../../common/assets/sunset.png";
import logo from "../../../common/assets/Debriefs.svg";

import { useAuth } from "../../../common/hooks/useAuth";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit">Debriefs.ai</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
export function MobileRegisterSection(p) {
  return (
    <>
      {p.allowSignUp ? (
        <>
          {" "}
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Box sx={{ zIndex: 1, position: "absolute", paddingLeft: 2 }}>
                    <h1 style={{ color: "white" }}> Debriefs.ai</h1>
                  </Box>
                  <img
                    src={im}
                    alt="sideView"
                    style={{
                      height: "120px",
                      width: "100vw",
                      objectFit: "cover",
                      borderBottomLeftRadius: "50px",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 5,
                    height: "100%",
                  }}
                >
                  <Typography component="h1" variant="h5">
                    Sign up
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={p.handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="family-name"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          variant="standard"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {p.registerSuccess.state ? (
                          <>
                            {p.registerSuccess.message === "error" ? (
                              <>
                                <Alert severity="error">
                                  Error registering user
                                </Alert>
                              </>
                            ) : (
                              <>
                                <Alert severity="success">
                                  User Created Succesfuly
                                </Alert>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end" spacing={5}>
                      <Grid item>
                        <GradientButton text={"Need an account?"} />
                      </Grid>
                      <Grid item>
                        <Link
                          href="/"
                          variant="body2"
                          sx={{
                            color: "#c6017e",
                            fontWeight: "bold",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#c6017e", // Same color as text
                            },
                          }}
                        >
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Box sx={{ zIndex: 1, position: "absolute", paddingLeft: 2 }}>
                    <h1 style={{ color: "white" }}> Debriefs.ai</h1>
                  </Box>
                  <img
                    src={im}
                    alt="sideView"
                    style={{
                      height: "120px",
                      width: "100vw",
                      objectFit: "cover",
                      borderBottomLeftRadius: "50px",
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <Box
                  component="form"
                  noValidate
                  onSubmit={p.handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 5,
                    height: "100%",
                  }}
                >
                  <div style={{ padding: 5 }}>
                    <Typography component="h1" variant="h5" fontWeight={900}>
                      Hey! Do you have an invitation code?
                    </Typography>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="invitationCode"
                        label="Invitation Code"
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {p.validInvitationCode ? (
                        <></>
                      ) : (
                        <>
                          <Alert severity="error">Invalid code</Alert>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      borderRadius: "15px",
                      background: "linear-gradient(90deg, #ff385c, #c6017e)", // Gradient background
                      "&:hover": {
                        background: "linear-gradient(90deg, #ff5075, #d6018e)", // Brighter gradient for hover effect
                      },
                    }}
                  >
                    Check Access
                  </Button>
                  <Grid container justifyContent="flex-end" spacing={5}>
                    <Grid item>
                      <GradientButton text={"Need an account?"} />
                    </Grid>
                    <Grid item>
                      <Link
                        href="/"
                        variant="body2"
                        sx={{
                          color: "#c6017e",
                          fontWeight: "bold",
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#c6017e", // Same color as text
                          },
                        }}
                      >
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
