import React, { useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../../../common/hooks/useAuth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";
import { doesUserExists } from "../Utils/LoginUtils";
import logo from "../../../common/assets/Debriefs.svg";
import logo_light from "../../../common/assets/DebriefsDarkMode.svg";
import { googleProvider, microsoftProvider } from "../../../firebaseConfig";


import backgroundImage from "../../../common/assets/background.png";

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit">Debriefs.ai</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function LoginContainer(p) {
  const { login, authenticateAndSetUser, registerWithGoogle, user } = useAuth();
  const [disableLogin, setDisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const auth = getAuth();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  const handleLoading = (x) => {
    setDisable(x);
    setLoading(x);
  };

  const handleMicrosoftLogin = async () => {
  try {
    const result = await auth.signInWithPopup(microsoftProvider);
    console.log(result.user);
    handleSignInSuccess(result.user);
  } catch (error) {
    console.error("Error logging in with Microsoft", error);
  }
};

const handleGoogleLogin = async () => {
  try {
    const result = await auth.signInWithPopup(googleProvider);
    console.log(result.user);
    handleSignInSuccess(result.user);
  } catch (error) {
    console.error("Error logging in with Google", error);
  }
};

const handleSignInSuccess = async (user) => {
  logEvent(analytics, "login_success", {
    user_email: user.email,
    label: "login_success",
    timestamp: new Date().toISOString(),
  });

  let loginResponse = {
    email: user.email,
    token: user.accessToken,
    firstName: capitalizeFirstLetter(user.displayName.split(" ")[0]),
    lastName: capitalizeFirstLetter(user.displayName.split(" ")[1]),
  };
  let userExists = await doesUserExists(loginResponse.email);

  if (true) {
    if (userExists) {
      userExists.imageUri = user.photoURL;
      loginResponse.user = userExists;
      authenticateAndSetUser(loginResponse, handleLoading);
    } else {
      await registerWithGoogle(loginResponse, handleLoading);
    }
  } else {
    alert(
      "You do not have access yet. Please contact us if this is a mistake."
    );
  }
};


var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
      console.log("Successful login: ", authResult.user);

      logEvent(analytics, "login_success", {
        user_email: authResult.user.email,
        label: "login_success",
        timestamp: new Date().toISOString(),
      });

      let loginResponseFromGoogle = {
        email: authResult.user.email,
        token: authResult.user.accessToken,
        firstName: capitalizeFirstLetter(
          authResult.user.displayName.split(" ")[0]
        ),
        lastName: capitalizeFirstLetter(
          authResult.user.displayName.split(" ")[1]
        ),
      };
      let userExists = await doesUserExists(loginResponseFromGoogle.email);

      if (true) {
        if (userExists) {
          userExists.imageUri = authResult.user.photoURL;
          console.log("User exists");
          loginResponseFromGoogle.user = userExists;
          authenticateAndSetUser(loginResponseFromGoogle, handleLoading);
        } else {
          console.log("User does not exists");
          let registerUser = await registerWithGoogle(
            loginResponseFromGoogle,
            handleLoading
          );
          console.log("registerUser", registerUser);
        }
      } else {
        alert(
          "You do not have access yet. Please contact us if this is a mistake."
        );
      }

      return true;
    },
  },
  signInFlow: "popup",
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    {
      provider: 'microsoft.com',
      customParameters: {
        prompt: 'select_account'
      }
    }
  ],
};


  useEffect(() => {
    if (user === null) {
      if (!window.firebaseUiInstance) {
        window.firebaseUiInstance = new firebaseui.auth.AuthUI(auth);
      }

      window.firebaseUiInstance.start(
        `#firebaseui-auth-container${isNotMobile ? "" : "-mobile"}`,
        uiConfig
      );

      return () => {
        if (window.firebaseUiInstance) {
          window.firebaseUiInstance.reset();
        }
      };
    }
  }, [user, auth, isNotMobile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    console.log("Handle Submit: ", data);

    login(
      {
        email: data.get("email"),
        password: data.get("password"),
      },
      handleLoading
    );
  };

  const handleClose = () => {
    setLoading(false);
  };

  return (
    <div>
      <PageHead
        title="Sign In or Sign Up"
        description="Sign in or create an account to access personalized debriefs and insights on global topics."
      />
      <>
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust the opacity here
              backdropFilter: "blur(5px)", // Adjust the blur amount here
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
            }}
          >
            <img
              src={logo_light}
              alt="Debriefs AI"
              style={{
                width: "120px",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.85)", // Add this line to make the background color transparent and very light
              borderRadius: "30px",
              p: 4,
              width: "400px",
              maxWidth: "90%",
              backdropFilter: "blur(10px)", // Add this line to apply a backdrop filter
                  boxShadow: "0 0 10px rgba(255, 255, 255, 0.6)", // Add this line to add a whitish shadow around the box


              
            }}
          >
                 <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <img
                src={logo}
                alt="Debriefs AI"
                style={{
                  width: "160px",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Typography variant="h4" align="center" gutterBottom>
              Login or sign up to continue
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Typography variant="body2" align="right">
                Forgot password?
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={disableLogin}
                sx={{
                  mt: 2,
                  mb: 2,
                  borderRadius: "30px",
                  background: "#000",
                  color: "#fff",
                  "&:hover": {
                    background: "#333",
                  },
                }}
              >
                Continue
              </Button>
            </Box>
             <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Link href="https://debriefs.ai/join" target="_blank" rel="noopener">
                Don't have a Google or Microsoft Account?
              </Link>
            </Typography>
            <Divider sx={{ width: "100%", my: 2 }}>OR</Divider>
            <div id={`firebaseui-auth-container${isNotMobile ? "" : "-mobile"}`} />



            
          </Box>
        </Box>
        <Box
          component="footer"
          sx={{
            py: 2,
            backgroundColor: "transparent",
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
        >
          <Copyright />
        </Box>
      </>
    </div>
  );
}