import React from "react";
import useUtilsAccountPreferences from "../../../modules/AccountPreferences/Hooks/useUtilsAccountPreferences";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { setOpenRemove } = useUtilsAccountPreferences();

  const buttonClicked = () => {
    console.log("Remove clicked cell");
    setOpenRemove();
  };

  return (
    <span>
      <span>{cellValue}</span>&nbsp;
      <button onClick={() => buttonClicked()}>Remove</button>
    </span>
  );
};
