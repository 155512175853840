import { Grid } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import axios from "axios";

import Switch from "@mui/material/Switch";

// COMPONENTS
import ActionButton from "../../../common/components/Buttons/ActionButton";
import SecondaryActionButton from "../../../common/components/Buttons/SecondaryActionButton";
import SectionCard from "../../../common/components/Cards/SectionCard";

// DATE RANGE

// URLS
const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function DebriefStructureBuilder(p) {
  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState(p.sections);

  const [edit, setEdit] = useState(false);

  const handleUpdate = (cardId, value, field) => {
    const updatedCards = sections.map((card) =>
      card._id === cardId ? { ...card, [field]: value } : card
    );
    setSections(updatedCards);
  };

  const handleSetEdit = (x) => {
    setEdit(x);
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        // style={{
        //   height: "85vh",

        // }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Build Debrief</h1>
          <div>
            <SecondaryActionButton
              disabled={!edit}
              label={"Save Report"}
              onClick={async () => {
                setLoading(!loading);
                console.log(
                  "Data: ",
                  sections,
                  p.workSpaceId,
                  p.reportStructureId
                );
                let filteredSctions = sections.filter((i) => {
                  if (i.question?.trim() !== "" && i.keywords?.trim() !== "") {
                    return true;
                  } else {
                    return false;
                  }
                });

                // TODO: Add validation, make sure no fields are empty
                console.log("Filtered sections", filteredSctions);
                // // Save the report structure to the workspace
                const answer = await axios.post(
                  `${prodApi}/workSpace/save-workspace-report-structure`,
                  {
                    sections: filteredSctions,
                    workSpaceId: p.workSpaceId,
                    reportStructureId: p.reportStructureId,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log("Answer: ", answer);
                if (answer.status === 200) {
                  p.setAlert(true, "Report Saved Successfully!", "success");
                  setSections(filteredSctions);
                  setEdit(false);
                }
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" gutterBottom>
              {edit ? "Currently Editing" : "Begin Editing?"}
            </Typography>

            <Switch
              checked={edit}
              onChange={() => {
                setEdit(!edit);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        </Grid>

        <Grid container item xs={12} spacing={1}>
          {sections.length > 0 ? (
            <>
              {sections.map((i, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <SectionCard
                      disabled={!edit}
                      index={index}
                      data={i}
                      key={index}
                      sections={sections}
                      handleUpdate={handleUpdate}
                      add={() => {
                        let newSections = [
                          ...sections,
                          { _id: index + 1, question: "", keyWords: "" },
                        ];
                        setSections(newSections);
                      }}
                      delete={() => {
                        let updated = [...sections].filter((x) => {
                          return i._id !== x._id;
                        });
                        setSections(updated);
                      }}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#FFFFFF",
                  border: "1.5px solid #E0E0E0",
                  borderRadius: 20,
                }}
              >
                <ActionButton
                  disabled={!edit}
                  label={"Add Section"}
                  fullWidth
                  onClick={() => {
                    setSections([{ _id: 0, question: "", keyWords: "" }]);
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DebriefStructureBuilder;
