import React from "react";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

// Styled button with custom styles
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.primary.main, // Use primary color from the theme
  boxShadow: "none",
  textTransform: "none",
  fontSize: 12,
  padding: "6px 12px",
  // border: "1px solid",
  // borderColor: theme.palette.primary.main, // Use primary color from the theme for the border as well
  lineHeight: 1.5,
  fontFamily: theme.typography.fontFamily, // Use the font family from the theme
  "&:hover": {
    backgroundColor: theme.palette.action.hover, // Use a color from the theme for the hover state
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.dark, // Use a darker shade of primary for active state if needed
  },
  "&:focus": {
    boxShadow: "none",
  },
}));

const GoBackButton = ({ navigateTo }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(-1); // fallback to go back if no navigateTo prop is provided
    }
  };

  return (
    <StyledButton
      startIcon={<ArrowBackIosNewIcon style={{ fontSize: "10px" }} />} // Adjust the fontSize as needed
      variant="contained"
      onClick={handleNavigation}
    >
      Go back
    </StyledButton>
  );
};

export default GoBackButton;
