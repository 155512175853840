import React, { useState } from "react";
import "./TextWithSource.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';

const BlindspotView = ({ text }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setSelectedTab((prevTab) => Math.min(prevTab + 1, parsedData.output.length - 1)),
    onSwipedRight: () => setSelectedTab((prevTab) => Math.max(prevTab - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!text) {
    return null;
  }

  let parsedData = null;
  try {
    parsedData = JSON.parse(text);
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return <div>Error displaying content.</div>;
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Card sx={{ borderRadius: '12px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Summary tabs"
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : undefined}
        >
          {parsedData.output.map((item, index) => (
            <Tab key={`tab_${index}_${item.category}`} label={item.category} />
          ))}
        </Tabs>
      </Box>
      <CardContent {...swipeHandlers}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          {parsedData.title}
        </Typography>
        {parsedData.output.map((item, index) => (
          <div
            key={`content_${index}_${item.category}`}
            style={{ display: selectedTab === index ? 'block' : 'none' }}
          >
            {item.sections ? (
              item.sections.map((section, sectionIndex) => (
                <Box key={`section_${sectionIndex}`} sx={{ mt: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {section.section_title}
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {section.summary}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box sx={{ mt: 3 }}>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {item.summary}
                </Typography>
              </Box>
            )}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default BlindspotView;