import React from "react";
// import "./index.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import ReactDOM from "react-dom/client";

import App from "./App";
// MUI

// REDUX
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./lib/redux";

const root = ReactDOM.createRoot(document.getElementById("root"));

export const store = configureStore({
  reducer: reducers,
  devTools: true,
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
