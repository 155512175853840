import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid, Typography, Chip, Stack, DialogContent } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import TextField from "@mui/material/TextField";
import ChatComponent from "../Chat/ChatComponent";
import SummaryViewv1 from "../Summary Views/SummaryViewv1";
import "./ScrollableCard.css"; // Import a CSS file for styling
import ChatIcon from "@mui/icons-material/Chat"; // Icon for the chat button
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
};

export default function WebClusterModalv2(p) {
  let content = p.content;

  return (
    <div>
      <Modal
        open={p.open}
        onClose={() => {
          console.log("Handle close ");
          p.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChatComponent
                title={`Ask anything about ${content.category}`}
                content={content}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
