import { Grid } from "@mui/material";
import React, { useState, useRef, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../common/Utilities/DateUtilities";
import CustomLinkRenderer from "../../../common/components/AGGridRenderers/CustomLinkRenderers";

// AG GRID
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import RingLoader from "react-spinners/RingLoader";
import "./TopicSourceManager.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function linkCellRenderer(params) {
  let date = formatDate(params.value);
  // Create a clickable link
  return date;
}

function TopicSourceManager(p) {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const { id } = useParams();
  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const columnDefs = [
    {
      headerName: "",
      field: "type",
      filter: true,
      cellStyle: {
        fontWeight: "bold",
        color: "#007283",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        borderRadius: "8px",
      },
      width: 70,
    },
    {
      headerName: "Document Name",
      field: "title",
      filter: true,
      resizable: true,
      width: 300,
      cellStyle: {
        backgroundColor: "transparent",
        borderRadius: "8px",
      },
    },
    {
      headerName: "Value",
      field: "value",
      filter: true,
      cellRenderer: CustomLinkRenderer,
      width: 200,
      cellStyle: {
        backgroundColor: "transparent",
        borderRadius: "8px",
      },
    },
    {
      headerName: "Uploaded On Date",
      field: "createdAt",
      filter: true,
      cellStyle: {
        fontStyle: "italic",
        color: "#555555",
        backgroundColor: "transparent",
        borderRadius: "8px",
      },
      cellRenderer: linkCellRenderer,
    },
  ];

  // Each Column Definition results in one Column.
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
  }));

  const onPaginationChanged = async (event) => {
    // Get the current page number from the event
    const currentPageNumber = event.api.paginationGetCurrentPage() + 1;

    // Fetch data for the new page
    console.log("Current page: ", currentPageNumber);
    // Fetch all search topics for options
    //   setLoading(true);
    //   let topicData = await axios.post(
    //     `${devApi}/sourcesV2/fetch-topic-sources/${id}`,
    //     {
    //       page: currentPageNumber,
    //       pageSize: 40,
    //     }
    //   );
    //   console.log("Topic Sources: ", topicData);
    //   setData(topicData.data.sources);
    //   setLoading(false);
  };

  React.useEffect(() => {
    async function getTopic() {
      // Fetch all search topics for options
      let topicData = await axios.post(
        `${prodApi}/sourcesV2/fetch-topic-sources/${id}`,
        {
          query: p.query,
          page: 1,
          pageSize: 1000,
        }
      );
      console.log("Topic Sources: ", topicData);
      setData(topicData.data.sources);
      setLoading(false);
    }
    getTopic();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RingLoader
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className="ag-theme-alpine"
              style={{
                height: "80vh",
                width: "100%",
              }}
            >
              {data.length > 0 ? (
                <AgGridReact
                  rowData={data}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  rowSelection="multiple"
                  pagination={true}
                  paginationPageSize={20}
                  onPaginationChanged={onPaginationChanged}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TopicSourceManager;