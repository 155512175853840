import React from "react";
import "./RainbowButton.css"; // Make sure to import the CSS file
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";

function GradientButton({ text }) {
  let location = useLocation();

  return (
    <button
      className="btn"
      data={text}
      onClick={() => {
        // url: https://www.debriefs.ai/join
        const currentScreenName = location.pathname;
        window.open("https://www.debriefs.ai/join", "_blank"); // Opens the URL in a new tab
        logEvent(analytics, "join_clicked", {
          screen_name: currentScreenName,
        });
      }}
    ></button>
  );
}

export default GradientButton;
