import React, { useEffect, useState } from "react";

const Typewriter = ({ text = "", speed = 10 }) => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    if (visibleCount < text.length) {
      const isSpecialCharacter = [
        " ",
        ".",
        ",",
        "!",
        "?",
        ";",
        ":",
        "-",
        "(",
        ")",
        "[",
        "]",
        "{",
        "}",
        "<",
        ">",
        "+",
        "=",
        "*",
        "&",
        "^",
        "%",
        "$",
        "#",
        "@",
        "~",
      ].includes(text[visibleCount]);
      const timer = setTimeout(
        () => {
          setVisibleCount((prev) => (isSpecialCharacter ? prev + 2 : prev + 1));
        },
        isSpecialCharacter ? 30 : speed
      );
      return () => clearTimeout(timer);
    }
  }, [visibleCount, text, speed]);

  const style = {
    whiteSpace: "pre-line", // This style preserves spaces and line breaks
  };

  return <div style={style}>{text.slice(0, visibleCount)}</div>;
};

export default Typewriter;
