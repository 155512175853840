import React, { useEffect } from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AuthContext = createContext();
const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Use Auth 1");
    if (user !== null && location.pathname === "/") {
      navigate("/dashboard/home", { replace: true });
    }
  }, [user, navigate, location.pathname]);

  useEffect(() => {
    console.log("Use Auth 2");
    if (user === null) {
      if (location.pathname !== "/register") {
        navigate("/", { replace: true });
      }
    }
  }, [user, navigate]);

  const authenticateAndSetUser = (loginResponse, handleLoading) => {
    cookies.set("TOKEN", loginResponse.token, {
      path: "/",
    });
    // Get user data from response
    let userData = loginResponse.user;
    userData.token = loginResponse.token;
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
    handleLoading(false);
    navigate("/dashboard/home", { replace: true });
  };

  const login = async (userLogin, handleLoading) => {
    try {
      handleLoading(true);
      const loginResponse = await axios.post(`${prodApi}/user/login`, {
        email: userLogin.email,
        password: userLogin.password,
      });
      if (loginResponse.data.status !== 500) {
        authenticateAndSetUser(loginResponse.data, handleLoading);
      } else {
        handleLoading(false);
        alert(`Error: ${loginResponse.data.message}`);
      }
    } catch (e) {
      console.log("Error signing in: ", e);
      alert("Error: Could not authenticate");
      handleLoading(false);
    }
  };

  const registerWithGoogle = async (data, handleLoading) => {
    let register = await axios.post(`${prodApi}/user/register`, {
      email: data.email.toLowerCase(),
      firstName: data.firstName,
      lastName: data.lastName,
      registerWithGoogle: true,
    });

    if (register.data.status === 201) {
      authenticateAndSetUser(register.data, handleLoading);
    } else {
    }
  };

  const register = async (event, handleLoading) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let register = await axios.post(`${prodApi}/user/register`, {
      email: data.get("email").toLowerCase(),
      password: data.get("password"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
    });

    if (register.data.status === 201) {
      authenticateAndSetUser(register.data, handleLoading);
    } else {
    }
  };

  const logout = () => {
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      authenticateAndSetUser,
      login,
      registerWithGoogle,
      register,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
