import { createSlice, configureStore } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  user: 0,
  loggedIn: {},
  snackbarOpen: false,
  snackbarMessage: "",
};
const CounterSlice = createSlice({
  name: "commonSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setSnackBar: (state, action) => {
      state.snackbarOpen = action.payload.snackbarOpen;
      state.snackbarMessage = action.payload.snackbarMessage;
    },
  },
});

export const commonSliceActions = CounterSlice.actions;
export const commonSliceReducer = CounterSlice.reducer;
