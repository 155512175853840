import React from "react";
import {
  Grid,
  Typography,
  Box,
  Chip,
  useTheme,
  Card,
  CardContent,
  Button,
  Avatar
} from "@mui/material";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ComingSoonComponent from "../../../common/components/Buttons/ComingSoonButton";
import GmailLogo from "../assets/GmailLogo.svg";
import OutlookLogo from "../assets/OutlookLogo.svg";
import PlaidLogo from "../assets/PlaidLogo.svg";
import SpotifyLogo from "../assets/SpotifyLogo.svg"
import ZoomLogo from "../assets/ZoomLogo.svg"
import USPatentLogo from "../assets/USPTOLogo.svg"

function IntegrationsContainer() {
  const theme = useTheme();

  const integrations = [
    {
      title: "Gmail",
      description: "Connect your Gmail account to synthesize, analyze and feed your emails to debriefs.",
      apiConnection: "Google Account",
      activeDate: "7/29/2023",
      status: "Inactive",
      logo: GmailLogo,
    },
    {
      title: "Outlook",
      description: "Connect your Outlook account to synthesize, analyze and feed your emails to debriefs.",
      apiConnection: "Outlook API",
      activeDate: "7/29/2023",
      status: "Inactive",
      logo: OutlookLogo,

    },
    {
      title: "Banking",
      description: "Connect your financial statements and transactions using Plaid for secure data integration into your debriefs.",
      apiConnection: "Plaid API",
      activeDate: "7/29/2023",
      status: "Inactive",
      logo: PlaidLogo,
    },
    {
      title: "Spotify",
      description: "Connect your favorite podcasts to synthesize key findings and feed into your debriefs.",
      apiConnection: "Spotify API",
      activeDate: "7/29/2023",
      status: "Inactive",
      logo: SpotifyLogo,
    },
    {
      title: "Patent Public Search",
      description: "Connect patents in different industries to synthesize key developments and feed into your debriefs.",
      apiConnection: "uspto.gov",
      activeDate: "7/29/2023",
      status: "Inactive",
      logo: USPatentLogo,
    },

  ];

  const handleSettingsClick = (id) => {
    // Handle settings click for the integration with the specified id
  };

  const handleRemoveClick = (data) => {
    // Handle remove click for the integration with the specified data
  };

  return (
    <div>
      <PageHead
        title="Integrations"
        description="Choose where you want to connect your debriefs for data pipelines."
      />

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography component="h1" variant="h3" fontWeight={900} fontSize={40}>
            Integrations <ComingSoonComponent/>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography component="subtitle">
          Connect your data and seamlessly integrate your favorite sources to streamline your data pipelines and enrich your debriefs.
          </Typography>
        </Grid>

        {integrations.map((integration, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card elevation={0} style={{ border: "1px solid grey", borderRadius: "10px" }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar src={integration.logo} alt={integration.title} sx={{ mr: 2 , width: 48, height: 48, '& img': {objectFit: 'contain'}, }} />
                  <Typography variant="h4" component="div">
                    {integration.title} <ComingSoonComponent/>
                </Typography>
              </Box>

                <Typography variant="body1" gutterBottom>
                  {integration.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  API Connection: {integration.apiConnection}
                </Typography>
                {/*<Typography
                  component="p"
                  sx={{
                    marginBottom: "12px",
                    marginTop: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon sx={{ fontSize: "20px", marginRight: "6px", verticalAlign: "middle" }} />
                  <strong>Active since:</strong> {integration.activeDate}
                </Typography>*/}
                <Box
                  sx={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="material-symbols-outlined" style={{ fontSize: "20px", marginRight: "6px", verticalAlign: "middle" }}>
                    schedule_send
                  </span>
                  <strong>Status:</strong>
                  <Typography component="span" sx={{ ml: 1, mr: 3, verticalAlign: "middle" }}>
                    {integration.status === "Active" ? (
                      <Chip
                        label="Active"
                        sx={{
                          bgcolor: "#dcfce6",
                          color: "#166434",
                          border: "0.5px solid green",
                        }}
                      />
                    ) : (
                      <Chip
                        label="Inactive"
                        sx={{
                          bgcolor: "#e0e0e0",
                          color: "#9e9e9e",
                          border: "0.5px solid #9e9e9e",
                        }}
                      />
                    )}
                  </Typography>
                </Box>
              </CardContent>
              {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  size="small"
                  onClick={() => handleRemoveClick(integration)}
                  sx={{ alignSelf: "flex-start", color: theme.palette.error.main }}
                >
                  Remove
                </Button>
                <Button
                  size="small"
                  onClick={() => handleSettingsClick(integration.id)}
                  sx={{
                    alignSelf: "flex-end",
                    color: theme.palette.text.secondary,
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  Connect
                </Button>
                </Box> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default IntegrationsContainer;