import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

// MUI
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import BackdropLoader from "../../../common/components/BackdropLoader/BackdropLoader";
import { Link } from "react-router-dom";

// Icons
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

// DIALOG
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import WorkspaceCard from "../../../common/components/Cards/WorkspaceCard";
import axios from "axios";
import PageHead from "../../../common/components/Helmet/DebriefsHelmet";

const prodApiPython = process.env.REACT_APP_PYTHON_PROD_URL;
const devApiPython = process.env.REACT_APP_PYTHON_DEV_URL;
const localPython = "http://localhost:8080";
const pythonApi = prodApiPython;

const devApi = process.env.REACT_APP_DEV_URL;
const prodApi = process.env.REACT_APP_PROD_URL;

function MyWorkspaces() {
  const topic = {
    name: "Sample Topic",
    description: "This is a sample topic description.",
    followers: 1000,
    posts: 50,
    // Add more data as needed
  };

  const saved = localStorage.getItem("user");
  const userobj = JSON.parse(saved);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setOpenLoading] = useState(false);

  const [userWorkSpaces, setUserWorkSpaces] = useState([]);

  const [openCreate, setOpenCreate] = useState(false);

  const [name, setName] = useState("");
  const [areaOfFocus, setAreaOfFocus] = useState("");

  React.useEffect(() => {
    async function fetchUser() {
      setOpenLoading(true);

      //  Fetch user object
      let userResponse = await axios.post(`${prodApi}/user/fetch`, {
        id: userobj._id,
      });

      if (userResponse.data) {
        // Fetch all search topics for options
        let gridData = await axios.post(
          `${prodApi}/workspace/fetch-all-users-workspaces`,
          {
            userId: userobj._id,
          }
        );
        console.log("Workspaces:", gridData.data);
        setUserWorkSpaces(gridData.data);

        setOpenLoading(false);
      }
    }
    fetchUser();
  }, []);

  const createWorkSpace = async () => {
    try {
      setOpenLoading(true);
      // Define the data for creating a new workspace
      const workspaceData = {
        workspaceName: name, // Replace with the desired workspace name
        focusArea: areaOfFocus, // Replace with the desired focus area
        isClusterReport: true, // Replace with true or false
        createdBy: userobj._id,
      };

      const response = await axios.post(
        `${pythonApi}/scheduler/create_job`,
        workspaceData
      );

      // Handle the response (e.g., display a success message)
      console.log("Workspace created:", response.data);

      // You can also update the userWorkSpaces state if needed
      setUserWorkSpaces((prevWorkspaces) => [
        ...prevWorkspaces,
        response.data.data,
      ]);

      setOpenLoading(false);
      setOpenCreate(false);
    } catch (error) {
      // Handle any errors (e.g., display an error message)
      console.error("Error creating workspace:", error);
    }
  };

  return (
    <div>
      <PageHead
        title="Debrief Workspaces"
        description="Design and schedule personalized debriefs across a variety of topics. Craft a professional news intelligence feed tailored to your industry and interests."
      />

      <>
        {loading ? (
          <Grid item xs={12}>
            <BackdropLoader isLoading={loading} />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h3"
                fontWeight={900}
                fontSize={40}
              >
                Debrief Schedules
              </Typography>
            </Grid>
            {/* <Grid item xs={12} style={{ display: "flex" }}>
              <Typography component="subtitle">
                Schedule debriefs at your convenience
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "15px",
                  verticalAlign: "middle",
                  marginRight: "6px",
                  color: "#545c67",
                }}
              >
                info
              </span>
              <Typography
                component="h6"
                style={{ color: "#545c67", fontSize: "14px" }}
              >
                Today, each schedule is limited to a single topic. Our team is
                actively working on expanding schedules to include multiple
                topics.{" "}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  margin: 5,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                    marginRight: "6px",
                    color: "#505EFF",
                  }}
                >
                  school
                </span>
                <Typography
                  component="h6"
                  style={{
                    color: "#505EFF",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  Learn more about how to create a debrief.
                </Typography>
              </div>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Typography
                    component="h4"
                    variant="h3"
                    fontWeight={900}
                    fontSize={30}
                  >
                    Tutorial
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Typography variant="body" fontWeight={400} fontSize={15}>
                    Learn how to use Debriefs to get the most value.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Link
                    to="../my-schedules"
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      color: "#d31275",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "20px",
                        verticalAlign: "middle",
                        marginRight: "6px",
                      }}
                    >
                      edit_square
                    </span>
                    <Typography component="h6" style={{ fontSize: "15px" }}>
                      Feeling ready? Create your workspace.
                    </Typography>
                  </Link>
                </Grid>

                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/kCym_GhSZBw" // Replace YOUR_VIDEO_ID with the actual ID of the video
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </DialogContent>
            </Dialog>

            {userWorkSpaces.length > 0 ? (
              userWorkSpaces.map((i, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <WorkspaceCard
                    data={i}
                    id={i._id}
                    index={index}
                    status={i.isActive}
                    deleteWorkspace={async () => {
                      try {
                        setOpenLoading(true);
                        // Remove job from python service
                        const deleteJob = await axios.post(
                          `${pythonApi}/scheduler/remove_job`,
                          {
                            workspaceId: i._id,
                          },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        console.log("REMOVE JOB:", deleteJob.data);
                        if (!("error" in deleteJob.data)) {
                          // Delete workspace object
                          const deleteWorkspace = await axios.post(
                            `${prodApi}/workSpace/delete-workspace`,
                            {
                              workspaceId: i._id,
                            },
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );

                          console.log(
                            "DELETE WORKSPACE:",
                            deleteWorkspace.data
                          );
                        }
                      } catch (e) {
                        console.log("ERROR DELETING WORKSPACE: ", e);
                      }
                      // Remove workspace locally
                      let filter = [...userWorkSpaces].filter(
                        (x) => x._id !== i._id
                      );
                      setUserWorkSpaces(filter);
                      setOpenLoading(false);
                    }}
                  />
                </Grid>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <p>
                  You have no schedules created. Please create a schedule with
                  the + button below to get started.
                </p>
              </div>
            )}

            {/* CREATE WORKSPACE MODAL */}
            <Grid item xs={12}>
              <Dialog
                open={openCreate}
                onClose={() => {
                  setOpenCreate(false);
                }}
                maxWidth="md"
                fullWidth
              >
                {userWorkSpaces.length >= 3 ? (
                  <>
                    <DialogTitle
                      style={{
                        paddingBottom: 10,
                        fontSize: "24px",
                        fontWeight: "900",
                      }}
                    >
                      Workspace Limit Reached
                    </DialogTitle>
                    <DialogContent>
                      <p>
                        Looks like you've hit the limit of 3 schedules for now.
                        No worries though! You can modify an existing schedule
                        or reach out to us to get more schedules.
                        <a
                          href="mailto:jarvis@debriefs.ai"
                          style={{ color: "#10A37F" }}
                        >
                          jarvis@debriefs.ai
                        </a>{" "}
                      </p>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setOpenCreate(false);
                        }}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogTitle
                      style={{
                        paddingBottom: 10,
                        fontSize: "24px",
                        color: "#000000",
                        fontWeight: "900",
                      }}
                    >
                      Create Debrief Schedule
                    </DialogTitle>

                    <DialogContent>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "20px",
                            verticalAlign: "middle",
                            marginRight: "6px",
                            color: "#505EFF",
                          }}
                        >
                          lightbulb
                        </span>
                        <Typography
                          component="h6"
                          style={{ color: "#505EFF", fontSize: "15px" }}
                        >
                          Make sure to pick a name that describes what this
                          Debrief will be about.
                        </Typography>
                      </Grid>
                      <p>Choose a name for your Debrief.</p>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Debrief Name"
                        placeholder="Ex: ESG Market Debrief or Climate Tech Debrief"
                        name="schedule"
                        autoFocus
                        variant="outlined"
                        value={name}
                        onChange={(x) => {
                          setName(x.target.value);
                        }}
                      />
                      <p>
                        Provide detailed instructions to guide our AI in
                        creating your debrief.{" "}
                        <em>
                          {" "}
                          Example: Looking for carbon credit market insights as
                          an ESG Analyst.
                        </em>
                      </p>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Customization Instructions"
                        placeholder="Ex: Analyzing AI Chip Market to assess recent developments"
                        name="areaOfFocus"
                        autoFocus
                        variant="outlined"
                        value={areaOfFocus}
                        onChange={(x) => {
                          setAreaOfFocus(x.target.value);
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setOpenCreate(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ color: "#000000" }}
                        onClick={async () => {
                          console.log("Clicked save");
                          createWorkSpace();
                        }}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Fab
                color="primary"
                aria-label="add"
                style={{
                  position: "fixed",
                  bottom: "16px", // Adjust as needed
                  right: "16px", // Adjust as needed
                }}
                onClick={() => {
                  setOpenCreate(true);
                }}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        )}
      </>
    </div>
  );
}

export default MyWorkspaces;
