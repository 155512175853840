import { createTheme } from "@mui/material/styles";

// Dark theme colors and configurations
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // white, inverted from black
    },
    secondary: {
      main: "#0033cc", // a darker shade complementary to yellow
    },
    tertiary: {
      main: "#000000", // black, inverted from white
    },
    grey: {
      main: "#f0f0f0", // grey, inverted from white
    },
    error: {
      main: "#F44336", // error colors typically stay the same
    },
    background: {
      default: "#000", // a dark background, inverted from light
      paper: "#292829", // a dark background for paper elements
    },
    text: {
      primary: "#ffffff", // light text for better contrast on dark backgrounds
      // primary: "#0033cc",
      secondary: "#dddddd", // a lighter gray for secondary text
      // secondary: "#8BC34A",
    },
    accent: {
      main: "#416788", // accent colors can stay the same, but you could also choose a lighter or more vibrant color
    },
    success: {
      main: "#8BC34A", // success colors typically stay the same
    },
    warning: {
      main: "#009688", // warning colors typically stay the same
    },
    sideMenu: {
      main: "#121212", // a darker color for side menu
    },
    action: {
      hover: "#3a3a3a",
      chip: "#333333",
      like: "d31275",
      dislike: "0d3b66",
      commment: "faf0ca",
    },
  },
  typography: {
    fontFamily: [
      "Circular", // Ensure you have the appropriate license to use Circular
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "2.125rem", // 34px
      fontWeight: 700,
      lineHeight: 1.235,
      color: "#ffffff",
    },
    h2: {
      fontSize: "1.75rem", // 28px
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem", // 24px
      fontWeight: 700,
      // lineHeight: 1.167,
    },
    h4: {
      fontSize: "1.25rem", // 20px
      fontWeight: 700,
      // lineHeight: 1.235,
    },
    h5: {
      fontSize: "1rem", // 16px
      fontWeight: 700,
      lineHeight: 1.334,
    },
    h6: {
      fontSize: "0.875rem", // 14px
      fontWeight: 700,
      lineHeight: 1.6,
    },
    subtitle1: {
      fontSize: "1rem", // 16px
      fontWeight: 400,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 500,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: "1rem", // 16px
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 400,
      lineHeight: 1.43,
    },

    caption: {
      fontSize: "0.75rem", // 12px
      fontWeight: 400,
      lineHeight: 1.66,
    },
    overline: {
      fontSize: "0.75rem", // 12px
      fontWeight: 400,
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       backgroundColor: "#0F0F0F", // Dark theme background color
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "8px 16px",
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          margin: "10px", // Inside padding for content spacing

          backgroundColor: "#292829", // Dark theme paper color
          // color: "#f2f2f2", // Light text color for readability on dark background
          "&:hover": {
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.12)",
          },
          transition: "box-shadow 0.2s ease-in-out",
        },
        media: {
          borderRadius: "8px", // Rounded corners for any media elements
          overflow: "hidden", // Ensures the content fits neatly within the rounded corners
        },
        // Styling for the content area inside the card
        content: {
          padding: "16px", // Spacing inside the content area
        },
        // Styling for actions at the bottom of the card
        actions: {
          padding: "16px", // Spacing inside the actions area
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          // Additional styles if needed
          backgroundColor: "transparent",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#000", // Dark theme background color
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // Set the color for the icon
          color: "#ffffff", // Example: orange color
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 8,
});

export default darkTheme;
